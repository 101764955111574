.header {
	position: absolute;
	top: -96px;
	height: 80px;
	max-width: 1440px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
	display: flex;
	justify-content: center;

	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 1800px) {
			object-fit: contain;
			
		}
	}

	@media (max-width: 575px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.header_btn {
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);

	@media (max-width: 1600px) {
		right: 440px;
	}
}

.material {
	padding: 40px 40px 26px 40px;
	font-variant-numeric: lining-nums proportional-nums;
	width: 100%;

	@media (max-width: 1440px) {
		padding: 30px;
	}
	@media (max-width: 575px) {
		padding: 24px 0 0;
	}
}

.title {
	/* margin-bottom: 54px; */

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 32px;
	}
}

.wrapper_title {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	> img {
		margin-right: 10px;
	}

	> h4 {
		font-weight: 700;
		font-size: 30px;
		color: #28a879;
	}

	@media (max-width: 575px) {
		justify-content: center;
		margin-bottom: 8px;
	}
}

.container {
	background-color: rgba(212, 223, 243, 0.17);
	border-radius: 8px;
	padding: 64px;
	display: flex;
	justify-content: space-between;
	position: relative;
	margin-bottom: 28px;
	overflow: hidden;

    @media(max-width: 1800px) {
		padding: 48px;
	}

	@media(max-width: 768px) {
		margin-bottom: 16px;
	}
	
	&_present {
      justify-content: flex-end;
	  background-color: rgba(212, 223, 243, 0.60);
	  overflow: hidden;

	  @media(max-width: 768px) {
		height: 329px;
		background-image: url(../../../../public/static/lk/material/mokup_present_mobile.png);
		background-repeat: no-repeat;
		background-position: right 0 top -20px;
		background-size: cover;
		flex-direction: column;
		justify-content: space-between;
	  }
	}

	&_question {
		justify-content: space-between;
		align-items: center;
		background-color: rgba(212, 223, 243, 0.17);
		overflow: hidden;

		@media(max-width: 768px) {
			min-height: 508px;
			flex-direction: column-reverse;
			gap: 20px;
			
		}
	}

	&_aplication {
		justify-content: space-between;
		align-items: center;
		overflow: hidden;
		@media(max-width: 768px) {
			flex-direction: column;
		}
		
	}

	&_gov {
		justify-content: flex-start;
		background-color: rgba(212, 223, 243, 0.60);
	}

	&_video {
		justify-content: flex-end;
		/* margin-top: 86px; */
		margin-top: 32px;

		@media(max-width: 768px) {
			margin-top: 0;
			flex-direction: column;
		}
	}

	&_videotop {
		justify-content: space-between;
		/* margin-top: 86px; */
		margin-top: 32px;

		@media(max-width: 768px) {
			margin-top: 0;
			flex-direction: column;
		}
	}

	&_business {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		
	}

	&_case {
		background-image: url(../../../../public/static/lk/material/background_case.png);
		justify-content: flex-end;
		padding: 0;

		@media(max-width: 768px) {
			background-image: none;
			background-color: rgba(31, 51, 69, 0.90);
            backdrop-filter: blur(2px);
			flex-direction: column;
		}
	}

	&_events {

	}

	&_license {
		justify-content: flex-start;
		background-color: rgba(212, 223, 243, 0.60)
	}

	@media(max-width: 768px) {
		padding: 20px 16px;
	}
}


.block_text {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 506px;
	color: rgba(31, 51, 69, 0.90);
	margin-right: 40px;
	font-variant-numeric: lining-nums proportional-nums;
	
	@media(max-width: 1800px) {
		margin-right: 30px;
		min-width: 358px;
	}

	@media(max-width: 768px) {
		display: flex;
		align-items: flex-start;
		width: 100%;
		min-width: 100px;
	}
}

.block_present {
	width: 444px;
	
}

.block_video {
   &_2 {
	@media(max-width: 768px) {
		display: none;
	}
   }

   &_mob {
	display: none;

	@media(max-width: 768px) {
		display: block;
		margin-bottom: 12px;
	}
}
}

.blockvid {
	@media(max-width: 1440px) {
		margin-right: -44px;
	}
}

.block_bussines {
	@media(max-width: 768px) {
		width: 100%;
		height: auto;
	}
}

.block_question {
	max-width: 684px;
}

.title_block {
    font-feature-settings: 'liga' off;
    font-size: 38px;
    font-weight: 700;
    line-height: 120%;
	max-width: 720px;
	margin-bottom: 24px;
	color: rgba(31, 51, 69, 0.90);

	@media(max-width: 1800px) {
		font-size: 28px;
		margin-bottom: 20px;
		max-width: 380px;
	}

	@media(max-width: 1440px) {
	
	}

	@media(max-width: 768px) {
		font-size: 20px;
		text-align: start;
		margin-bottom: 16px;
	}

	span {
		color: #002CFB;
	}

	&_aplication {
		@media(max-width: 1800px) {
			max-width: 450px;
		}
		
	}

	&_gov {
		@media(max-width: 1800px) {
			max-width: 450px;
		}
	}
}

.title_video {
	width: 441px;

	@media(max-width: 768px) {
		max-width: 272px;;
	}
}

.title_business {
	width: 520px;

	@media(max-width: 768px) {
		width: 272px;
	}
}

.title_case {
	color: #FFF;
	width: 390px;
	margin-bottom: 32px;

	@media(max-width: 768px) {
		margin-bottom: 16px;
	}
}

.title_advantages {
	width: 461px;

	@media(max-width: 768px) {
		max-width: 272px;
	}
}

.title_present {
    @media(max-width: 768px) {
        max-width: 272px;
	}
}

.title_events {
	@media(max-width: 768px) {
		
	}
}

.text {
	font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
	color: rgba(31, 51, 69, 0.90);

	@media(max-width: 1800px) {
		font-size: 16px;
	}

	@media(max-width: 768px) {
		font-size: 14px;
	}

	&_present {
		margin-bottom: 24px;

		@media(max-width: 1800px) {
			margin-bottom: 20px;
		}

		@media(max-width: 768px) {
			display: none;
		}
	}

	&_case {
		color: #FFF;
	}
}

.list {
	list-style-type: none;
	padding: 0;
	margin: 0 0 24px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@media(max-width: 1800px) {
		margin-bottom: 20px;
	}

	@media(max-width: 768px) {
		margin-bottom: 16px;
	}

	&_case {
		width: 474px;
		margin-bottom: 32px;

		@media(max-width: 768px) {
			margin-bottom: 16px;
		}
	}

	&_video {
		@media(max-width: 768px) {
			display: none;
		}
	}

	&_events {
		@media(max-width: 768px) {
			
		}
	}
	
	
}

.item {
	font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
	display: flex;
	padding: 0;
	margin-bottom: 16px;

	@media(max-width: 1800px) {
		margin-bottom: 12px;
	}

	@media(max-width: 768px) {
		margin-bottom: 8px;
	}

	svg {
		margin-right: 8px;
	}

	@media(max-width:768px) {
		svg {
			width: 16px;
			height: 16px;
		}
	}

	@media(max-width: 1800px) {
		font-size: 18px;
	}

	@media(max-width: 768px) {
		font-size: 14px;
	}
}

.number {
	color: #71869D;
	margin-right: 8px;

	@media(max-width: 1800px) {
		margin-right: 6px;
	}
}

.item:last-child {
	margin-bottom: 0;
}

.button {
	color: var(--White, #FFF);
    text-align: center;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    line-height: 48px;
	padding: 0 24px;
	display: flex;
	align-items: center;
	background-color: #002CFB;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s;
	margin-bottom: 76px;
	width: max-content;

	@media(max-width: 1800px) {
		font-size: 16px;
		line-height: 40px;
		padding: 0 20px;
	}

	@media(max-width: 768px) {
		display: flex;
		margin-bottom: 0;
		width: 100%;
		justify-content: center;
		font-size: 14px;
		line-height: 40px;
	}

	&_video {
		@media(max-width: 768px) {
			display: none;
		}
	}

	&_present {
		margin-bottom: 33px;

		@media(max-width: 768px) {
			margin-bottom: 0;
		}
	}

	&_question {
		margin-bottom: 0;
	}

	&_aplication {
		margin-bottom: 24px;

		@media(max-width: 1800px) {
			margin-bottom: 20px;
		}

        @media(max-width: 768px) {
			margin-bottom: 16px;
		}
	}

	&_gov {
		border: 1px solid  #002CFB;
		color: #002CFB;
        background: none;
		margin-right: 12px;
		margin-bottom: 28px;

		@media(max-width: 768px) {
			margin-bottom: 12px;
		}
	}

	&_gov:last-child {
		@media(max-width: 768px) {
			margin-bottom: 0;
		}
	}

	&_business {
		margin-bottom: 144px;

		@media(max-width: 1440px) {
			/* margin-bottom: 88px; */
		}

		@media(max-width: 768px) {
			margin-bottom: 0;
		}
	}

	&_license {
		margin-bottom: 0;

		@media(max-width: 768px) {
			margin-top: 16px;
		}
	} 

	

	svg {
		margin-left: 8px;
	}
}

.button_another {
	color: var(--White, #FFF);
    text-align: center;
    font-family: Raleway;
    font-weight: 500;
    line-height: 48px;
	padding: 0 24px;
	display: flex;
	align-items: center;
	background-color: #002CFB;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s;
	margin-bottom: 52px;
	width: calc(100% + 32px);
	font-size: 14px;
	justify-content: center;
	align-self: center;

	svg {
		margin-left: 12px;
	}

	@media(min-width: 768px) {
		display: none;
	}

}

.button:hover {
    background-color: #0024CB;
}

.button:active {
	background-color: #0024CB;
}

.button_gov:hover {
	background: none;
	border: 1px solid  #0024CB;
	color: #0024CB;
}

.button_gov:active {
	background: none;
	border: 1px solid  #0024CB;
	color: #0024CB;
}

.buttons_events {
	margin-bottom: 68px;
}

.video {
   width: 35.5vw;
   height: 20vw;
   margin-bottom: 76px;

   @media(max-width: 1800px) {
	width: 515px;
	height: 288px;
   }

   

   @media(max-width: 768px) {
	 width: 90vw;
	 height: 50.625vw;
	 margin-bottom: 16px;
   }
}

.logos {
	position: absolute;
	width: calc(100% - 128px);
	bottom: 64px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&_aplication {
		width: 100%;
		display: flex;
	    justify-content: space-between;
	    align-items: center;

	
	}

	&_gov {
		width: 480px;
		display: flex;
		justify-content: space-between;

		@media(max-width: 768px) {
			display: none;
		}
	}

	@media(max-width: 768px) {
		width: calc(100% - 32px);
		bottom: 28px;
		left: 16px;
	}

}

.logo {
	height: 30px;
	background-repeat: no-repeat;
	background-size: contain;
	display: flex;
	align-items: center;

	&_gov {
      opacity: 0.35;
	}

	&_aplication {
		@media(max-width: 1800px) {
           height: 20px;
		}

		@media(max-width: 768px) {
			height: 15px;
		}
	}

	@media(max-width: 1800px) {
       height: 24px;
	   justify-content: space-between;
	}

	@media(max-width: 768px) {
		height: 15px;
	}
	
	&_dl {
		background-image: url(../../../../public/static/lk/Logo_Dl.svg);
		width: 161px;
		margin-bottom: 4px;

		@media(max-width: 768px) {
			display: none;
		}
	}

	&_mv {
		background-image: url(../../../../public/static/lk/Logo_mv.svg);
		width: 72px;

		@media(max-width: 768px) {
			display: none;
		}
	}

	&_sb {
		background-image: url(../../../../public/static/lk/Logo_sb.svg);
		width: 84px;

		@media(max-width: 768px) {
			width: 55px;
		}
	}

	&_pr {
		background-image: url(../../../../public/static/lk/Logo_pr.svg);
		width: 47px;

		@media(max-width: 768px) {
			display: none;
		}
	}

	&_av {
		background-image: url(../../../../public/static/lk/Logo_av.svg);
		width: 80px;

		@media(max-width: 768px) {
			width: 53px;
		}
	}

	&_fi {
		background-image: url(../../../../public/static/lk/Logo_fi.svg);
		width: 97px;

		@media(max-width: 768px) {
			width: 65px;
		}
	}

	&_dns {
		background-image: url(../../../../public/static/lk/Logo_dns.svg);
		width: 58px;
		margin-top: 6px;

		@media(max-width: 768px) {
			display: none;
		}
	}

	&_mag {
		background-image: url(../../../../public/static/lk/Logo_mag.svg);
		width: 104px;

		@media(max-width: 768px) {
			width: 64px;
		}
	}
}

.mokup {
	position: absolute;
	top: -20px;
	left: -30px;

	@media(max-width: 1800px) {
		width: 601.5px;
		height: 320px;
		top: 0;
		left: -60px;
	}

	@media(max-width: 768px) {
		display: none;
	}

	&_2 {
		position: absolute;
		bottom: 0;
		right: 10px;
		height: 320px;
		width: auto;

		@media(max-width: 768px) {
			display: none;
		}
	}

	&_3 {
		width: 470px;
		height: 470px;
		margin-right: 60px;

		@media(max-width: 1800px) {
			width: 374px;
			height: 374px;
			margin-right: 45px;
		}

		@media(max-width: 768px) {
			display: none;
		}
	}


	&_3mob {
		display: none;

		@media(max-width: 768px) {
			display: flex;
			width: calc(100% + 40px);
			margin-left: -20px;
			margin-top: -20px;
			margin-bottom: 16px;
		}
	}

	&_video {
		position: absolute;
		top: 0;
		left: 0;
		
		@media(max-width: 1800px) {
			width: auto;
			height: 100%;
		}

		@media(max-width: 768px) {
			display: none;
		}
	}
 
	&_videomob {
		display: none;
		@media(max-width: 768px) {
			height: auto;
            width: calc(100% + 40px);
			display: flex;
			margin-left: -20px;
			margin-top: -20px;
			margin-bottom: 16px;
		}
	}

	&_gov {
		position: absolute;
		bottom: 0;
		right: 0;

		@media(max-width: 1800px) {
			height: 337px;
			width: auto;
		}

		@media(max-width: 768px) {
			display: none;
		}
	}

	&_govmob {
		display: none;

		@media(max-width: 768px) {
			display: flex;
			margin-bottom: 14px;
		}
	}

	&_business {
		position: absolute;
		top: 0;
		right: 0;

		@media(max-width: 1800px) {
			height: 350px;
			width: auto;
		}

		@media(max-width: 768px) {
			display: none;
		}
	}

	&_businessmob {
        display: none;

		@media(max-width: 768px) {
			display: flex;
			position: absolute;
			width: 350px;
			height: auto;
			
	   }
	}


	&_casemob {
		display: none;
		@media(max-width: 768px) {
			display: flex;
			width: calc(100% + 40px);
			margin-left: -20px;
			margin-top: -20px;
			margin-bottom: 16px;
		}
	}

	&_advantages {
		position: absolute;
		top: 0;
		right: -80px;
		height: 100%;
		width: auto;

		@media(max-width: 768px) {
            display: none;
		}
	}

	&_advantagesmob {
		display: none;

		@media(max-width: 768px) {
			display: flex;
			margin-bottom: -25px;
			margin-left: 20px;
		}	
	}

	&_license {
		position: absolute;
		top: 0;
		right: 0;

		@media(max-width: 768px) {
            display: none;
		}
	}

	&_licensemob {
		display: none;

		@media(max-width: 768px) {
			display: flex;
			width: 100%;
			height: auto;
			
			margin-left: 20px;
		}
	}
	
	&_events {
		display: none;
		
		@media(max-width: 768px) {
			display: flex;
			background-image: url(../../../../public/static/lk/material/mokup_events.png);
			height: 200px;
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			margin-bottom: 16px;
			border-radius: 4px;
		}
	}
}

.badge {
	padding: 16px;
	background-color: #E5ECF8;
	border-radius: 4px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	margin-bottom: 24px;

	@media(max-width: 1800px) {
		max-width: 505px;
		margin-bottom: 20px;
	}

	@media(max-width:768px) {
		padding: 12px;
		align-items: flex-start;
		margin-bottom: 16px;
	}

	&__container {
        display: flex;
		flex-direction: column;
		margin-left: 12px;

	   @media(max-width: 1800px) {
           margin-left: 9px;
	   }	

		h4 {
			font-family: Raleway;
            font-size: 20px;
            font-weight: 700;
            line-height: 120%;
			color: rgba(31, 51, 69, 0.90);

			@media(max-width:1800px) {
				font-size: 16px;
			}
		}

		p {
			font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;

			@media(max-width:1800px) {
				font-size: 12px;
			}
		}
	}
}

.buttons {
	display: flex;

	@media(max-width: 768px) {
		flex-direction: column;
	}
}

.gallery {
	display: flex;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;

	@media(max-width: 768px) {
		display: none;
	}
}

.photo {
	width: 260px;
	height: 160px;
	border-radius: 4px;
    object-fit: contain;
	overflow: hidden;
	background-color: black;
	position: relative;

	@media(max-width: 1800px) {
		width: 194px;
		height: 120px;
	}

	&_event {
		width: 142px;
		height: 89px;
		position: relative;
		border-radius: 4px;
        object-fit: contain;
	    overflow: hidden;
	}

	&_big {
		position: relative;

		@media(max-width: 1800px) {
			height: 250px;
			width: auto;
		}

		@media(max-width: 768px) {
			display: none;
		}
	}
	
	img {
		transition: opacity 0.2s;
	}

	&__link {
		width: 100%;
		height: 100%;
		object-fit: contain;
		display: flex;
	}


	&__overlay {
        position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0;
		transition: opacity 0.2s;
		background-color: #00000038;
	}

	&__icon {
		background-image: url(../../../../public/static/lk/IconZoomPlus.png);
		width: 28px;
		height: 28px;
	}
}


.photo:hover .photo__overlay {
	opacity: 1;
}

.photo_event:hover .photo__overlay {
	opacity: 1;
}

.photo_big:hover .photo__overlay {
	opacity: 1;
}

.block_case {
	padding: 64px;
	background-color: rgba(31, 51, 69, 0.90);
    backdrop-filter: blur(2px);

	@media(max-width: 768px) {
		padding: 0;
		background: none;
		backdrop-filter: none;
		width: 100%;
	}
}

.photos {
	margin-right: 60px;
	position: relative;

	@media(max-width: 768px) {
		margin-right: 0;
	}
}

.backgroundmob {
    display: none;

	&_question {
        @media(max-width: 768px) {
			width: 309px;
			height: 221px;
			background-image: url(../../../../public/static/lk/material/backgroundmob_question.png);
			background-size: cover;
		}
	}

	&_aplication {
		@media(max-width: 768px) {
			width: 100%;
			height: auto;
			background-image: url(../../../../public/static/lk/material/backgroundmob_aplication.png);
			background-size: cover;
		}
	}
}

.box {
	z-index: 10;
	
	@media(max-width: 768px) {
	
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&_office {
        display: none;
		@media(max-width: 768px) {
			display: flex;
			width: 100%;
			height: 200px;
			border-radius: 4px;
			overflow: hidden;
			object-fit: cover;
			position: relative;
			margin-bottom: 16px;
		}
	}
}

.videoover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(../../../../public/static/lk/material/coverLKVideo.jpg);
	opacity: 0;
	background-size: cover;
	cursor: pointer;
}

.video {
	position: relative;
}

.progressbar {
	width: 98%;

	display: flex;
	align-items: center;
	overflow: hidden;
}

.line {
	width: 100%;
	height: 4px;
	background-color: #E5ECF8;
	position: relative;

	&_green {
		position: absolute;
		border-radius: 100px;
        background: linear-gradient(270deg, #E5ECF8 5.34%, #28A879 8%);

		height: 4px;
	}

	&_green2 {
		position: absolute;
		border-radius: 100px;
        background-color: #28A879;
		height: 4px;
		
	}
}

.container_progress {
	display: none;
	z-index: 20;
	top: 0;
	right: 0;
	margin-top: -20px;
	position: fixed;
	width: calc(100% - 240px);
	height: 116px;
	background-color: #FFF;
	padding: 0 160px 0 160px;
	@media (max-width: 1800px) {
		padding: 0 85px 0 85px;
	}

	@media (max-width: 1280px) {
		
	}

	align-items: center;

	&_view {
		z-index: 50;
		display: flex;
		box-shadow: 5px 0 15px rgb(184, 183, 183);

		@media(max-width: 768px) {
			display: none;
		}
	}

	@media(max-width: 768px) {
		display: none;
	}
}

.check {
	position: absolute;
	width: 24px;
	height: 24px;
	z-index: 40;

	p {
		width: 150px;
		color: var(--Text, rgba(31, 51, 69, 0.90));
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;

		@media(max-width: 1800px) {
			font-size: 12px;
		}
	}
	&_bp {
		left: 0%;
	}

	&_pres {
		left: 19%;

		p {
			position: absolute;
			left: -30px;
		}
	}

	&_qustion {
		left: 31%;

		p {
			position: absolute;
			left: -30px;
		}
	}

	&_aplic {
		left: 43%;

		p {
			position: absolute;
			text-align: center;
			left: -60px;
		}
	}

	&_business {
		left: 55%;

		p {
			position: absolute;
			text-align: center;
			left: -60px;
		}
	}

	&_review {
		left: 67%;

		p {
			position: absolute;
			left: -30px;
		}
	}

	/* &_office {
		left: 80%;
	} */

	&_case {
		left: 79%;
		p {
			position: absolute;
			text-align: center;
			left: -60px;
		}
	}

	/* &_advant {
		left: 82%;
	}

	&_event {
		left: 92%;
	} */

	&_contract {
		left: 98%;

		p {
			position: absolute;
			left: -110px;
		}
	}
}

.progress_box {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;

	@media(max-width: 768px) {
		display: none;
	}
}

.progress_mob {
	display: none;
	@media(max-width: 768px) {
		width: 100%;
		height: 20px;
		background: #FFF;
		position: fixed;
		top: 68px;
		left: 0;
		z-index: 20;
		padding: 8;
		display: flex;
		align-items: center;
		box-shadow: 3px 0 10px rgb(184, 183, 183);
	}
	
}

.linemob {
	width: 100%;
	height: 4px;
	border-radius: 100px;
    background-color: #F1F4F9;
	margin: 0 8px;
	overflow: hidden;

	&_fill {
		position: absolute;
		left: 0;
		z-index: 20;
		height: 4px;
		background-color: #28A879;
		margin: 0 8px;
	}
}