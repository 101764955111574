.intro {
	padding: 92px 0 50px 260px;
	position: relative;
	background: url('../../../public/static/vip/bg.jpg') #000 no-repeat;

	@media (max-width: 575px) {
		position: relative;
		padding: 56px 16px 40px;

		background: url('../../../public/static/vip/bg-mobile.jpg') #000 no-repeat;
		background-size: cover;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.5);
		}
	}
}

.logo {
	display: inline-block;
	margin-bottom: 76px;

	@media (max-width: 575px) {
		position: relative;
		z-index: 1;
		margin-bottom: 56px;
	}
}

.info {
	margin-bottom: 45px;

	>h4 {
		color: #00ff88;
		font-weight: 600;
		font-size: 24px;
		margin-bottom: 24px;

		@media (max-width: 575px) {
			margin-bottom: 16px;
			font-size: 16px;
		}
	}

	>h1 {
		max-width: 841px;
		width: 100%;
		color: #fff;
		font-weight: 800;
		font-size: 70px;
		line-height: 72px;

		@media (max-width: 575px) {
			font-weight: 800;
			font-size: 26px;
			line-height: 120%;
		}
	}

	@media (max-width: 575px) {
		position: relative;
		z-index: 1;
		margin-bottom: 320px !important;
	}
}

.info_margin {
	margin-bottom: 200px;
}

.price {
	color: #e3e4e8;
	font-weight: 600;

	>h3 {
		font-size: 24px;
		margin-bottom: 12px;
		text-decoration-line: line-through;
		text-transform: uppercase;
	}

	>h2 {
		font-size: 32px;
		margin-bottom: 12px;
		text-transform: uppercase;
	}

	>p {
		position: relative;
		z-index: 10;
		line-height: 130%;
		font-weight: 400;
		font-size: 14px;
		color: #ffffff;
		opacity: 0.6;

		@media (max-width: 575px) {
			text-align: center;
		}
	}
}

.founder {
	position: absolute;
	bottom: -50px;
	right: 0;

	@media (max-width: 1600px) {
		display: none;
	}

	@media (max-width: 575px) {
		display: block;
	}
}

.form {
	position: relative;
	//z-index: 20;
	background-color: rgb(48, 53, 80);
	width: max-content;
	padding: 24px;
	margin-bottom: 50px;

	@media (max-width: 575px) {
		margin: 30px auto 15px !important;
		width: 100%;
		padding: 16px;
		border-radius: 5px;

		display: flex;

		flex-direction: column;
	}
}

.wrapperField {
	display: flex;
	flex-direction: column;

	@media (max-width: 575px) {
		flex-direction: column;
		align-items: center;
	}
}

.field {
	color: rgb(255, 255, 255);
	display: block;
	height: 50px;
	background-color: rgba(0, 7, 41, 0.3);
	padding: 12.5px 13px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 4px 0 0 4px;
	width: 275px;
	color: #fff;
	font-size: 18px;

	@media (max-width: 575px) {
		width: 100% !important;
	}
}

.error {
	border: 1px solid red;
}

.form_button {
	position: relative;
	display: flex;
	align-items: center;
	min-height: 48px;
	padding: 12.5px 24px;
	font-size: 18px;
	font-weight: 600;
	background-color: #00ff88;
	color: #000;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	z-index: 1;

	&:hover {
		background-color: #00cc6e;
	}

	@media (max-width: 575px) {
		width: 100%;
		padding: 0;
		justify-content: center;
	}
}

.firstSection {
	position: relative;
	background: url('../../../public/static/vip/bg2.jpg') #000 no-repeat;
	min-height: 739px;

	@media (max-width: 575px) {
		background: url('../../../public/static/vip/bg2-mobile.jpg') #000 no-repeat;
		min-height: 730px;
		background-size: cover;
	}
}

.secondSection {
	position: relative;
	background: url('../../../public/static/vip/bg3.jpg') #000 no-repeat;
	min-height: 885px;
	background-size: cover;

	@media (max-width: 575px) {
		background: url('../../../public/static/vip/bg3-mobile.jpg') #000 no-repeat;
		background-size: cover;
	}
}

.thirdSection {
	position: relative;
	background: url('../../../public/static/vip/bg4.jpg') #000 no-repeat;
	min-height: 987px;

	@media (max-width: 575px) {
		background: url('../../../public/static/vip/bg4-mobile.jpg') #000 no-repeat;
		background-size: cover;
	}
}

.fourthSection {
	position: relative;
	background: url('../../../public/static/vip/bg5.jpg') #000 no-repeat;
	min-height: 883px;

	@media (max-width: 575px) {
		background: url('../../../public/static/vip/bg5-mobile.jpg') #000 no-repeat;
		background-size: cover;
	}
}

.fifthSection {
	position: relative;
	background: url('../../../public/static/vip/bg6.jpg') #000 no-repeat;
	min-height: 783px;

	@media (max-width: 575px) {
		background: url('../../../public/static/vip/bg6-mobile.jpg') #000 no-repeat;
		background-size: cover;
	}
}


.formblock {
	display: flex;
	flex-wrap: wrap;
	width: 558px;
	gap: 8px;
	margin-bottom: 16px;

	input {
		width: 275px !important;
		margin: 0;
	}

	div {
		width: 275px !important;
		margin: 0;
	}

	@media(max-width: 768px) {
		flex-direction: column;
		width: 100%;
		gap: 16px;
		

		input {
			margin: 0;
			width: 100% !important;
		}

		div {
			margin: 0;
			width: 100% !important;
		}
	}

	


}