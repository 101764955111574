.button {
	position: relative;
	z-index: 2;
	min-height: 48px;
	padding: 0 24px;
	font-size: 18px;
	font-weight: 500;
	background: #002cfb;
	color: #fff;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:hover {
		background: #0024cb;
	}
}

.button_back {
	position: relative;
	z-index: 2;
	background: transparent;
	color: #002cfb;
	border: 1px solid #647fff;
	border-radius: 4px;
	&:hover {
		background: transparent;
		border: 1px solid #0024cb;
	}

	@media (max-width: 575px) {
		width: 48px;
		height: 48px;
		padding: 0;
		font-size: initial;
	}
}

.buttondis {
	color: #afafaf;
	background-color: rgb(241, 241, 241);
	pointer-events: none;
}
