.enter {
	background: radial-gradient(55.7% 97% at 0% 3%,
			#000e50 21.6%,
			rgba(0, 23, 134, 0) 80.42%),
		radial-gradient(20.81% 43.51% at 87.01% 11.68%,
			#001fb2 0%,
			rgba(0, 23, 134, 0) 100%),
		radial-gradient(66.95% 140.01% at 66.95% 90.11%, #000e50 0%, #001786 42.05%);

	mix-blend-mode: normal;
	min-height: 100vh;
}

.logo {
	margin-bottom: 40px;

	@media (max-width: 575px) {
		margin-bottom: 24px;
	}
}

.rectangle {
	margin: 0 auto 70px;
	width: 120px;
	height: 120px;
	object-fit: contain;

	img {
		width: 120px;
		height: 120px;
	}
}

.title {
	max-width: 510px;
	margin: 0 auto 0;
	font-weight: 800;
	font-size: 50px;
	line-height: 110%;
	color: #fff;

	@media (max-width: 575px) {
		max-width: 288px;
		font-weight: 800;
		font-size: 30px;

	}
}

.text {
	margin: 0 0 12px 80px;
	max-width: 510px;
	font-weight: 400;
	font-size: 22px;
	line-height: 110%;
	color: #fff;
	text-align: start;

	@media (max-width: 575px) {
		max-width: 420px;
		font-weight: 400;
		font-size: 18px;
		margin: 0 0 8px;
	}

	&_2 {
		font-weight: 500;
		font-size: 34px;
		margin: 0 auto 40px;
		text-align: center;

		@media (max-width: 575px) {
			max-width: 320px;
			font-weight: 500;
			font-size: 22px;
		}
	}


}

.inner {
	max-width: 650px;
	width: 100%;
	margin: 0 auto;
	padding: 72px 0 48px;
	text-align: center;

	@media (max-width: 575px) {
		padding: 36px 16px 32px 16px;
	}
}

.form {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 24px;
	width: 100%;
	max-width: 600px;
	margin: 0 auto 28px;
	background: #000933;
	border-radius: 12px;

	@media (max-width: 575px) {
		display: flex;
		flex-direction: column;
		padding: 16px;
	}
}

.input {
	background: rgba(0, 0, 0, 0.2);
	font-size: 18px;
	font-weight: 500;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 4px 0px 0px 4px;
	padding-left: 13px;
	color: #fff;

	&::placeholder {
		color: rgba(255, 255, 255, 0.56);
		font-weight: 500;
		font-size: 18px;
	}

	@media (max-width: 575px) {
		padding: 12.5px 13px;
	}
}

.wrapper_btn {
	background: #3357ff;
	border-radius: 4px;

	cursor: pointer;
	transition: all 0.2s ease-in-out;

	>button {
		min-width: 296px;
		width: 100%;
		min-height: 48px;
		font-family: inherit;
		color: #fff;
		font-weight: 600;
		font-size: 18px;
		cursor: pointer;

		>svg {
			margin-left: 12px;
			vertical-align: bottom;
		}

		@media (max-width: 1600px) {
			font-size: 17px;
		}
	}

	&:hover {
		background: #0024cb;
	}

	@media (max-width: 575px) {
		font-weight: 600;
		font-size: 18px;
		margin-top: 16px;
	}
}

.descr {
	color: rgba(255, 255, 255, 0.56);
	font-weight: 400;
	font-size: 16px;
	margin-bottom: 127px;

	@media (max-width: 575px) {
		margin-bottom: 37px;
	}
}

.descrlink {
	color: rgba(255, 255, 255, 0.56);
	font-weight: 400;
	font-size: 16px;
	margin-bottom: 12px;
	text-wrap: wrap;

	a {
		transition: all 0.2s;
		text-decoration: underline;

		&:active {
			color: rgba(255, 255, 255, 0.56);
		}

		&:visited {
			color: rgba(255, 255, 255, 0.56);
		}

		&:link {
			color: rgba(255, 255, 255, 0.56);
		}

		&:hover {
			color: rgba(255, 255, 255, 0.733);
		}
	}



	@media (max-width: 575px) {
		margin-bottom: 32px;
		font-size: 14px;
	}
}

.link {
	margin-right: 12px;
	color: rgba(255, 255, 255, 0.56);
	font-weight: 400;
	font-size: 16px;
	text-decoration: underline;
	text-underline-offset: 2px;
}

.years {
	color: rgba(255, 255, 255, 0.56);
	font-weight: 400;
	font-size: 16px;
}

.input_error {
	border: 1px solid red;
	transition: all 0.2s ease-in-out;
}

.errorText {
	color: red;
}

.loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.list {
	margin-bottom: 40px;
	margin-left: 80px;

	@media (max-width: 575px) {
		margin-left: 0;
	}

	ul {

		padding: 0;
		margin: 0;
		overflow: hidden;
		transition: all 0.25s ease-in-out;
	}


	li {
		text-align: start;
		color: #fff;
		font-weight: 600;
		font-size: 18px;
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 0 0 8px 0;

		@media (max-width: 575px) {
			font-weight: 500;
			font-size: 14px;
		}
	}

	a {
		color: #fff;
		text-decoration: underline;
		text-underline-offset: 2px;
		cursor: pointer;
	}
}