.done {
	padding: 40px 40px -20px 40px;

	@media (max-width: 575px) {
		padding: 24px 0px;
		text-align: center;
	}
}

.wrapper_title {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	color: #8e9aa8;
	font-weight: 700;
	font-size: 30px;

	@media (max-width: 575px) {
		justify-content: center;
		margin-bottom: 8px;
	}
}

.subtitle {
	margin-left: 10px;

	@media (max-width: 575px) {
		margin-left: 6px;
	}
}

.title {
	margin-bottom: 32px;

	@media (max-width: 575px) {
		margin-bottom: 20px;
	}
}

.descr {
	max-width: 432px;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	font-size: 20px;
	color: #8e9aa8;
	margin-bottom: 596px;

	@media (max-width: 1200px) {
       margin-bottom: 450px;
	}

	@media (max-width: 1000px) {
		margin-bottom: 350px;
	 }

	 @media (max-width: 771px) {
		margin-bottom: 100px;
	 }

	@media (max-width: 575px) {
		margin-bottom: 30px !important;
	}
}

.descr_approval {
	max-width: 432px;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	font-size: 20px;
	color: #8e9aa8;
	margin-bottom: 64px;
	@media (max-width: 575px) {
		margin-bottom: 0;
	}
}

.illustation,
.illustation__approval,
.illustration__rejected {
	position: absolute;
	bottom: 0;
	right: 30px;
	@media (max-width: 1600px) {
		height: 40vw;
	}

	@media (max-width: 1000px) {
		height: 30vw;
	}

	@media (max-width: 771px) {
		display: block;
		position: static;
		width: 110%;
		height: auto;
		display: block;
		margin-left: -16px;
		margin-bottom: -55px;
	}
}

.illustation__approval {
	width: 100%;
	right: 0;

	@media (max-width: 575px) {
		width: 110%;
	}
}

.subtitle__approval {
	color: #28a879;
}

.subtitle__notApproval {
	color: #e10d0d;
}

.btn {
	display: inline-block;
	position: relative;
	z-index: 2;
	padding: 15.5px 62px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: #002cfb;
	border-radius: 4px;
	margin-bottom: 473px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:hover {
		background: #0024cb;
	}

	@media (max-width: 575px) {
		line-height: 48px;
		max-width: 350px;
		width: 100%;
		position: fixed;
		z-index: 30;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		margin-bottom: 0;
		padding: 0;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.window {
	width: 685px;
	height: 531px;
	background-color: #28a879;
}
