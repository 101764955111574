@import '../../../../../styles/vendor/animation.scss';

.eventButtons {
	max-width: 320px;
	width: 100%;
	margin-right: 24px;

	@media (max-width: 1700px) {
		max-width: 280px;
	}

	@media (max-width: 820px) {
		margin-right: 0;
		margin-bottom: 20px;
	}

	@media (max-width: 575px) {
		margin-right: 0;
		margin-bottom: 40px;
	}
}

.tabs {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&::after {
		content: '';
		position: absolute;
		bottom: -10px;
		z-index: 5;
		display: inline-block;
		background: rgba(173, 191, 223, 0.4);
		width: 100%;
		height: 2px;
	}
}

.tab {
	position: relative;
	color: #71869d;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	font-size: 16px;
	cursor: pointer;
	animation: opacity 1s;
	transition: all 0.2s ease-in-out;

	&:hover {
		color: #2e3c4e;
	}

	@media (max-width: 1700px) {
		font-size: 14px;
	}

}

.line {
	position: relative;
	display: inline-block;
	background: rgba(173, 191, 223, 0.4);
	width: 100%;
	height: 2px;
}

.line_tab {
	position: absolute;
	bottom: -10px;
	left: 0;
	display: inline-block;
	z-index: 2;
	width: 100%;
	height: 2px;
}

.active_tab {
	color: #002cfb;
}

.active_line_tab {
	background: #002cfb;
	transition: all 0.2s ease-in-out;
}
