.form {
	display: flex;
	flex-direction: column;
	background: #303550;
	border-radius: 12px;
	padding: 24px;

	@media (max-width: 575px) {
		flex-direction: column;
		background: rgba(0, 7, 41, 0.5);
		padding: 16px 0;
	}
}

.form_transform {
	flex-direction: column;
	max-width: 350px;
	width: 100%;
	flex-direction: column-reverse;
	background: transparent;
}

.input {
	font-family: inherit;
	max-width: 350px;
	min-height: 48px;
	width: 100%;
	background: rgba(0, 0, 0, 0.2);
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 4px 0px 0px 4px;
	padding-left: 13px;
	font-weight: 500;
	color: #ffffff;
	font-size: 18px;

	&::placeholder {
		color: #ffffff;
		font-weight: 500;
		font-size: 18px;
	}

	@media (max-width: 575px) {
		margin-bottom: 24px;
	}
}

.input_hidden {
	opacity: 0;
	visibility: hidden;

	@media(max-width: 575px) {
		margin-bottom: -50px;
	}
}

.btn {
	min-width: 272px;
	height: 48px;
	margin-left: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 18px;
	line-height: 120%;
	font-weight: 600;

	background: #00ff88;
	border-radius: 0 4px 4px 0;

	transition: background-color 0.3s ease;
	color: #000729;
	cursor: pointer;
	font-family: inherit;

	& span {
		margin-left: 12px;
		width: 21px;
		height: 18px;
	}

	@media (max-width: 575px) {
		font-size: 18px;
		border-radius: 4px;
	}
}

.btn_transform {
	min-width: 350px;

	@media(max-width: 320px) {
		min-width: 272px;
	}
}

.btn_customer {
	background: #002cfb;
	color: #ffffff;

	&:hover {
		background: #0024cb;
	}
}

.btn_business {
	background: #00ff88;
	color: #000729;

	&:hover {
		background-color: #00cc6e;
	}
}

.btn_worker {
	background: #8800ff;
	color: #ffffff;

	&:hover {
		background: #621f9d;
	}
}

.input_error {
	border: 1px solid red;
	transition: all 0.2s ease-in-out;
}

.politic {
	margin-top: 20px;
	font-weight: 400;
	font-size: 14px;
	color: #71869d;

	> a {
		color: #71869d;
		text-decoration: underline;
	}

	@media (max-width: 575px) {
		width: initial;
		line-height: 130%;
		margin-top: 10px;
	}
}

.wrapper {
	display: flex;

	@media(max-width: 575px) {
		flex-direction: column;
	}
}
