.form_title {
	max-width: 575px;
	margin-bottom: 16px;
	color: #121922;
	font-weight: 600;
	font-size: 20px;

	@media (max-width: 575px) {
		text-align: left;
	}
}

.form_item {
	margin-bottom: 32px;

	&:last-of-type {
		margin-bottom: 64px;
	}

	@media (max-width: 575px) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}

.radio {
	position: relative;
	margin-bottom: 8px;

	> label {
		display: inline-block;
		max-width: 600px;
		font-family: 'Inter', sans-serif;
		vertical-align: sub;
		font-weight: 400;
		font-size: 18px;
		color: #121922;
		padding-left: 34px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			z-index: 1;
			transform: translateY(-50%);
			transition: all 0.2s linear;
			width: 20px;
			height: 20px;
			border: 2px solid #adbfdf;
			background-color: transparent;
			border-radius: 50%;
		}

		&:after {
			content: '';
			opacity: 0;
			position: absolute;
			display: block;
			background: #002cfb;
			width: 10px;
			height: 10px;
			top: 50%;
			left: 5px;
			z-index: 2;
			border-radius: 50%;
			transform: translateY(-50%);
		}

		@media (max-width: 575px) {
			padding-left: 34px;
			text-align: left;
		}
	}

	> input {
		appearance: none;
		position: absolute;

		&:checked + label::before {
			border: 2px solid #002cfb;
		}

		&:checked + label::after {
			opacity: 1;
		}

		&:hover + label::before {
			border: 2px solid #002cfb;
		}

		&:hover + label {
			color: #2e3c4e;
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 575px) {
		margin-bottom: 12px;
	}
}

.checkbox {
	position: relative;
	margin-bottom: 8px;

	> label {
		font-family: 'Inter', sans-serif;
		vertical-align: sub;
		font-weight: 400;
		font-size: 18px;
		color: #121922;
		padding-left: 34px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 0;

			transform: translateY(-50%);
			transition: all 0.2s linear;
			width: 24px;
			height: 24px;
			border: 2px solid rgba(173, 191, 223, 0.4);
			border-radius: 6px;
			background-color: rgba(255, 255, 255, 0.56);
		}

		&:after {
			content: url('../../../../../public/static/lk/checkboxDone.svg');
			opacity: 0;
			position: absolute;
			display: block;
			z-index: 2;
			top: 50%;
			left: 2px;
			color: #fff;
			transform: translateY(-50%);
		}
	}

	> input {
		appearance: none;
		position: absolute;

		&:checked + label::before {
			border: 2px solid #002cfb;
			background: #002cfb;
		}

		&:checked + label::after {
			opacity: 1;
		}

		&:not(input:checked):hover + label::before {
			background: rgba(0, 44, 251, 0.05);
			border: 2px solid #526ed3;
		}

		&:hover + label {
			color: #2e3c4e;
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 12px;
	}
}

.education,
.experience {
	display: flex;
	flex-direction: column;
	position: relative;

	@media (max-width: 575px) {
		align-items: start;
		width: 100%;
	}
}

.textarea {
	font-family: 'Inter', sans-serif;
	resize: none;
	height: 80px;
	max-width: 600px;
	width: 100%;
	padding: 11px 13px;
	font-weight: 400;
	font-size: 18px;
	line-height: 150%;
	background: #f1f4f9;
	border: 1px solid #ecf2fc;
	color: #2e3c4e;
	border-radius: 4px;
	margin-top: 16px;
	transition: all 0.2s ease-in;
	outline: none;

	&::placeholder {
		color: #71869d;
		font-weight: 500;
		font-size: 18px;
	}

	&:hover {
		background: #f1f4f9;
		border: 1px solid #adbfdf;
		color: #2e3c4e;
	}

	&:focus {
		color: #121922;
		border: 1px solid #71869d;
	}
}

.btns {
	max-width: 600px;
	width: 100%;
	display: flex;
	justify-content: space-between;

	@media (max-width: 525px) {
		margin-top: 0;	
		bottom: 8px;
		width: 100%;
		margin-bottom: 40px;
        display: flex;
		justify-content: space-between;
	
	}
}

.illustration {
	position: absolute;
	bottom: 0;
	right: 0;

	@media (max-width: 1600px) {
		display: none;
	}

	@media (max-width: 575px) {
		display: none;
	}
}

.title_step5 {
	font-weight: 700;
	font-size: 30px;
	color: #71869d;
	margin-bottom: 40px;

	@media (max-width: 575px) {
		margin-bottom: 32px;
	}
}

.input_text {
	font-family: 'Inter', sans-serif;
	min-height: 48px;
	width: 600px;
	padding: 0 13px;
	background: #f1f4f9;
	font-weight: 400;
	font-size: 18px;
	border: 1px solid #ecf2fc;
	border-radius: 4px;
	color: #71869d;
	margin-right: 8px;
	margin-bottom: 8px;
	transition: all 0.2s ease-in-out;
	&:last-child {
		margin-right: 0;
	}

	&:hover {
		background: #f1f4f9;
		color: #2e3c4e;
		border: 1px solid #adbfdf;
	}

	&:focus {
		background: #f1f4f9;
		color: #121922;
		border: 1px solid #71869d;
	}

	@media (max-width: 575px) {
		width: 100%;
	}
}

.label_moreText {
	&:before {
		top: 6px !important;
		transform: initial !important;
	}

	&::after {
		top: 11px !important;
		transform: initial !important;
	}
}

.radio_mb12 {
	margin-bottom: 12px;
}

.error {
	&:focus {
		border: 1px solid #e10d0d;
	}
}

.errorText {
	
	color: #e10d0d;
	margin-top: 8px;

	@media (max-width: 575px) {
		font-size: 15px;
	}
}

.errorText2 {
	
	color: #e10d0d;

	@media (max-width: 575px) {
		font-size: 15px;
	}
}


.linkcheck {
	text-decoration: underline;
    transition: all 0.2s;
	&:hover {
		color: #121922a9;
	}
}