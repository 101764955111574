.header {
	position: absolute;
	top: -96px;
	left: 0;
	height: 80px;
	max-width: 1440px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
	
	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 1800px) {
			object-fit: contain;
		}
	}

	@media (max-width: 575px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.header_btn {
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);

	@media (max-width: 1600px) {
		right: 440px;
	}
}


.title {
	margin-bottom: 64px;

	@media (max-width: 575px) {
		margin-bottom: 40px;
		text-align: center;
		font-weight: 800;
		font-size: 24px;
		line-height: 28px;
	}
}

.block {
	max-width: 428px;
	width: 100%;
	margin-bottom: 99px;

	@media (max-width: 575px) {
		margin-bottom: 0 !important;
	}
}

.data {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;

	@media (max-width: 575px) {
		justify-content: center;
		align-items: baseline;
		margin-bottom: 24px;
	}
}

.label {
	font-weight: 600;
	font-size: 24px;
	color: rgba(31, 51, 69, 0.9);

	@media (max-width: 575px) {
		margin-right: 9px;
	}
}

.done {
	display: flex;
	align-items: center;

	> svg {
		margin-right: 8px;
	}

	> span {
		font-weight: 400;
		font-size: 18px;
		color: #71869d;
	}
}

.btn_material {
	width: 100%;
	display: flex;
	justify-content: center;
	position: fixed;
	bottom: 16px;
	left: 0;
	z-index: 7;

	> button {
		font-family: inherit;
		background: #002cfb;
		font-weight: 500;
		font-size: 18px;
		color: #fff;
		padding: 0 43px;
		min-height: 48px;
		border-radius: 4px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;

		&:hover {
			background: #0024cb;
		}

		@media (max-width: 575px) {
			width: 100%;
			max-width: 350px;
			padding: 0;
			z-index: 30;
		}

		@media (max-width: 320px) {
			max-width: 304px;
		}
	}
}
