.exam {
	padding: 40px 40px 24px;
	min-height: 750px;

	@media (max-width: 1200px) {
		min-height: 700px;
	}

	@media (max-width: 1070px) {
		min-height: 600px;
	}

	@media (max-width: 770px) {
        min-height: 400px;
	}

	@media (max-width: 575px) {
		padding: 24px 8px 0;
		margin-bottom: -8px;
	}
}

.header {
	position: absolute;
	top: -96px;
	height: 80px;
	max-width: 1440px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;

	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 1800px) {
			object-fit: contain;
		}
	}

	@media (max-width: 770px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.header_btn {
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);

	@media (max-width: 1600px) {
		right: 440px;
	}
}

.wrapper_heading {
	display: flex;
	align-items: flex-start;

	> h5 {
		font-weight: 700;
		font-size: 30px;
		line-height: 36px;
		margin-bottom: 10px;
		color: #8e9aa8;

		@media (max-width: 575px) {
			margin-bottom: 0;
			font-size: 24px;
		}
	}

	> img {
		margin-right: 10px;
	}

	&.heading_done {
		> h5 {
			color: #28a879;
		}
	}

	@media (max-width: 770px) {
		justify-content: center;
		margin-bottom: 8px;
	}
}

.heading {
	position: relative;
	z-index: 1;
	max-width: 800px;
	width: 100%;

	> h2 {
		font-weight: 800;
		font-size: 38px;
		margin-bottom: 32px;
		color: rgba(31, 51, 69, 0.9);

		@media (max-width: 575px) {
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 20px;
		}
	}

	> p {
		font-family: 'Inter', sans-serif;
		font-weight: 400;
		font-size: 20px;
		line-height: 26px;
		color: #8e9aa8;

		@media (max-width: 770px) {
			font-size: 18px;
		}
	}

	&.heading_waiting {
		@media (max-width: 770px) {
			margin-bottom: 0;
		}
	}

	@media (max-width: 770px) {
		text-align: center;
	}
}

.illustration {
	position: absolute;
	bottom: 0;
	right: 0;
	height: 800px;

	@media (max-width: 1760px) {
		right: -10px;
	}

	@media (max-width: 770px) {
		height: auto;
		width: 100%;
		position: static;
		bottom: -8px;
		left: 16px;
		margin-left: 0;
		margin-bottom: -30px;
		margin-right: 40px;
	}

	@media (max-width: 1600px) {
		display: none;
	}

	@media (max-width: 770px) {
		width: 100%;
		position: static;
		display: block;
		width: 100%;
		margin-left: 24px;
		margin-top: 23px;
	}
}

.list {
	max-width: 560px;
	width: 100%;
	padding: 16px 16px 18px;
	margin: 40px 0 89px;

	background: rgba(212, 223, 243, 0.17);
	border-radius: 8px;

	> h4 {
		font-family: 'Inter', sans-serif;
		font-weight: 600;
		font-size: 20px;
		line-height: 26px;
		color: #121922;
		margin-bottom: 24px;

		@media (max-width: 575px) {
			font-size: 18px;
			color: #000729;
		}
	}

	@media (max-width: 770px) {
		margin: 32px 0 32px;
	}
}

.btn {
	min-height: 48px;
	min-width: 228px;
	padding: 0 97px;
	color: #fff;
	font-weight: 500;
	background:  #002CFB;
	font-size: 18px;
	border-radius: 4px;
	margin-top: 25px;
	cursor: pointer;
	transition: background-color 0.2s;

	.btn:hover {
		background-color: #0024CB;
	 }

	&_disabled {
		color:  #ADBFDF;
		cursor: none;
		background: #F1F4F9;
	
	}
	&_disabled:hover {
		background: #F1F4F9;
	}

	@media (max-width: 770px) {
		padding: 0;
		width: 100%;
		max-width: 350px;
		margin-top: 0;
		position: fixed;
		bottom: 8px;
		left: 50%;
		z-index: 30;
		transform: translateX(-50%);
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}



.text_bottom {
	color: #71869d;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	font-size: 18px;

	@media (max-width: 770px) {
		text-align: center;
	}
}
