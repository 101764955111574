.assistant {
	display: flex;
	align-items: center;
}

.img {
	width: 48px;
	height: 48px;
	border-radius: 50%;
}

.info {
	margin-left: 10px;
}

.name {
	color: #121922;
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 4px;
}

.prof {
	color: #8e9aa8;
	font-weight: 500;
	font-size: 16px;
}
