.auth {
	font-family: 'Raleway', sans-serif;
	padding: 120px 0 0 120px;
	min-height: 800px;
	height: 90vh;

	@media (max-width: 575px) {
		padding: 24px 0 0 0;
		min-height: auto;
	}
}

.illustration {
	position: absolute;
	bottom: 0;
	right: 0;

	@media (max-width: 1340px) {
		height: 40vw;
	}

	@media (max-width: 770px) {
		height: 380px;
	}


	@media (max-width: 575px) {
		display: block;
		left: 20px;
		height: 320px;
	}
}
