.premiumDone {
	padding: 40px;
	min-height: 800px;

	@media (max-width: 575px) {
		padding: 24px 0 0;
		margin-bottom: 40px;
		min-height: auto;
	}
}

.btn {
	margin-bottom: 368px;
	font-weight: 500;
	font-size: 18px;
	font-family: inherit;
	background: #002cfb;
	border-radius: 4px;
	color: #fff;
	min-height: 48px;
	padding: 0 24px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:hover {
		background: #0024cb;
	}

	@media (max-width: 575px) {
		max-width: 350px;
		position: fixed;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		margin-bottom: 100px;
		width: 100%;
		margin: 0;
		padding: 0;
		z-index: 30;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.wrapper_title {
	max-width: 655px;
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	> img {
		margin-right: 10px;
	}

	@media (max-width: 575px) {
		justify-content: center;
	}
}

.title {
	margin-bottom: 32px;
	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 20px;
	}
}

.subtitle {
	color: #28a879;
	font-weight: 700;
	font-size: 30px;
}

.descr,
.descr2 {
	max-width: 680px;
	width: 100%;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	color: #8e9aa8;
	margin-bottom: 60px;
	@media (max-width: 575px) {
		text-align: center;
	}
}

.descr {
	@media (max-width: 575px) {
		margin-bottom: 32px;
	}
}

.descr2 {
	max-width: 440px;

	@media (max-width: 575px) {
		font-size: 17px;
	}
}

.bottom {
	> div {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		color: #121922;
		font-weight: 600;
		font-size: 20px;

		> span {
			margin-left: 8px;
		}

		@media (max-width: 575px) {
			margin-bottom: 12px;
		}
	}

	> p {
		max-width: 632px;
		width: 100%;
		font-family: 'Inter', sans-serif;
		color: #8e9aa8;
		font-weight: 400;
		font-size: 20px;
		line-height: 26px;
	}

	@media (max-width: 575px) {
		margin-bottom: 34px;
	}
}

.illustation {
	position: absolute;
	bottom: 0;
	right: 0;

	@media (max-width: 1600px) {
		display: none;
	}

	@media (max-width: 575px) {
		display: block;
		position: static;
		width: 100%;
		margin-left: 16px;
	}
}

.contract_text {
	font-family: 'Inter', sans-serif;
	color: #8e9aa8;
	font-weight: 400;
	font-size: 20px;
	text-decoration: underline;
	text-underline-offset: 4px;
	font-weight: 600;
	cursor: pointer;

	@media (max-width: 575px) {
		font-size: 18px;
	}
}

.back {
	cursor: pointer;
	margin-bottom: 30px;

	> img {
		width: 40px;
		height: 40px;
	}
}

.bottom_margin {
	margin-top: 500px;

	@media (max-width: 575px) {
		margin-top: 0;
	}
}
