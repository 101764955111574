.header {
	position: absolute;
	top: -96px;
	left: 0;
	height: 80px;
	max-width: 1440px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 1800px) {
			object-fit: contain;
		}
	}

	@media (max-width: 575px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.header_btn {
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);

	@media (max-width: 1600px) {
		right: 440px;
	}
}

.zoom {
	padding: 40px;
	height: calc(100vh - 100px);
	position: relative;
	display: flex;
	flex-direction: column;

	@media (max-width: 1300px) {
		height: 700px;
	}

	@media (max-width: 575px) {
		height: 600px;
	}
}

.wrapper_title {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	> img {
		margin-right: 10px;
	}

	@media (max-width: 575px) {
		justify-content: center;
		margin-bottom: 8px;
	}
}

.subtitle_step1 {
	font-weight: 700;
	font-size: 30px;
	color: #8e9aa8;
}

.subtitle_done {
	font-weight: 700;
	font-size: 30px;
	color: #28a879;
}

.title {
	max-width: 519px;
	width: 100%;
	margin-bottom: 32px;

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 40px;
	}
}

.pict {
	@media (max-width: 575px) {
		object-fit: cover;
		position: relative;
		z-index: 2;
	}
}

.title_done {
	max-width: 680px;
	width: 100%;
	margin-bottom: 56px;

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 40px;
	}
}

.list {
	padding: 0;
	margin-bottom: 48px;

	> li {
		align-items: center;
	}

	@media (max-width: 575px) {
		margin-bottom: 32px;
	}
}

.btn {
	position: relative;
	z-index: 12;
	min-height: 48px;
	padding: 0 30px;
	font-family: inherit;
	background: #002cfb;
	border-radius: 4px;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	margin-top: auto;
	max-width: 260px;

	&:hover {
		background: #0024cb;
	}

	@media (max-width: 575px) {
		max-width: 350px;
		width: 100%;
		position: fixed;
		bottom: 8px;
		left: 50%;
		z-index: 30;
		margin: 0;
		padding: 0;
		transform: translateX(-50%);
	}
	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.illustation {
	position: absolute;
	bottom: 0;
	right: 0;
	height: 700px;

	@media (max-width: 1600px) {
		height: 500px;
	}

	@media (max-width: 1300px) {
		height: 350px;
	}

	@media (max-width: 970px) {
		height: 300px;
	}

	@media (max-width: 770px) {
		display: none;
		height: 457px;
		margin-bottom: -30px;
	}
	@media (max-width: 642px) {
		height: 408px;
	}

	@media (max-width: 575px) {
		height: 300px;
		margin-top: 50px;
		margin-left: -40px;
		
	}

	
}

.expert {
	display: flex;
	justify-content: space-between;
	background: #f1f4f9;
	max-width: 377px;
	width: 100%;
	border-radius: 12px;
	padding: 12px 16px;
	margin-top: 56px;

	> img {
		width: 85px;
		height: 85px;
		border-radius: 50%;
		margin-right: 24px;

		@media (max-width: 575px) {
			margin-top: 11.5px;
		}
	}

	@media (max-width: 575px) {
		padding: 16px 34px 11.5px;
		flex-direction: column-reverse;
		text-align: center;
		align-items: center;
		margin-top: 0;
	}
}

.prof {
	margin-bottom: 6px;
	color: #71869d;
	font-weight: 500;
	font-size: 16px;

	@media (max-width: 575px) {
		font-size: 14px;
		margin-bottom: 4px;
	}
}

.name {
	color: rgba(31, 51, 69, 0.9);
	font-weight: 700;
	font-size: 30px;

	@media (max-width: 575px) {
		line-height: 34px;
	}
}

.sended {
	display: flex;
	align-items: center;
	margin-top: auto;

	@media (max-width: 575px) {
		margin: 0;
		min-width: 304px;
		position: fixed;
		bottom: 23px;
		left: 50%;
		margin: 0;
		padding: 0;
		transform: translateX(-50%);
	}
}

.text_sended {
	margin-left: 12px;
	color: #71869d;
	font-weight: 500;
	font-size: 18px;

	@media (max-width: 575px) {
		line-height: 22px;
	}
}
