.card {
	background-color: #ccc;
}

.input {
	position: relative;
	display: flex;
	justify-content: space-around;
	align-items: center;
	max-width: 200px;
	width: 100%;
	height: 40px;
	background: #ffffff;
	border-radius: 8px;
	cursor: pointer;

	@media (max-width: 1460px) {
		height: 30px;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		display: block;
		top: 0;
		left: 45px;
		height: 100%;
		width: 3px;
		background: #ECF2FC;
	}

	&::after {
		left: inherit;
		right: 45px;
	}
}

.info {
	display: flex;
	align-items: center;
}

.icon {
	display: inline-block;
	margin-right: 7px;
}

.month {
	display: inline-block;
	color: rgba(31, 51, 69, 0.9);
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
}

.btn {
	cursor: pointer;
	height: 100%;
	width: 40px;
}
