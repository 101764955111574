.button,
.btn_record,
.btn_lookData {
	font-weight: 500;
	font-size: 18px;
	font-family: inherit;
	background: #002cfb;
	border-radius: 4px;
	color: #fff;
	min-height: 48px;
	padding: 0 24px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:hover {
		background: #0024cb;
	}
}

.btn_record {
	margin-bottom: 228px;

	@media (max-width: 575px) {
		padding: 0;
		position: fixed;
		z-index: 30;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 350px;
		width: 100%;
		margin-bottom: 0;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.btn_lookData {
	margin-bottom: 326px;

	@media (max-width: 575px) {
		padding: 0;
		position: fixed;
		z-index: 30;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 350px;
		width: 100%;
		margin-bottom: 0;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}
