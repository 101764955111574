.icon {
	display: block;
	width: 16px;
	height: 16px;
}

.arrow-right {
	cursor: text;
	background: url('../../../public/static/icons/arrow-right.png') no-repeat
		center;
}

.arrow-right-big {
	background: url('../../../public/static/icons/arrow-right-big.png') no-repeat
		center;
}

.arrow-right-top {
	cursor: text;
	background: url('../../../public/static/icons/arrow-right-top.png') no-repeat
		center;
}

.arrow-right-top.purple {
	cursor: pointer;
	background: url('../../../public/static/icons/arrow-right-top-purple.png')
		no-repeat center;
}

.arrow-right-top.green {
	cursor: pointer;
	background: url('../../../public/static/icons/arrow-right-top-green.png')
		no-repeat center;
}

.arrow-right-top-big {
	cursor: text;
	background: url('../../../public/static/icons/arrow-right-top-big.png')
		no-repeat center;
	width: 23px;
}

.arrow-chart {
	cursor: pointer;
	background: url('../../../public/static/icons/arrow-chart.png') no-repeat
		center;
}

.arrow-chart {
	cursor: pointer;
	background: url('../../../public/static/icons/arrow-chart.png') no-repeat
		center;
}

.arrow-chart-business {
	cursor: pointer;
	background: url('../../../public/static/icons/arrow-chart-business.svg')
		no-repeat center;
}

.arrow-right-footer {
	cursor: text;
	background: url('../../../public/static/icons/arrow-right-footer.png')
		no-repeat center;
}

.arrow-right-top-smail {
	cursor: text;
	background: url('../../../public/static/icons/arrow-right-top-smail.png')
		no-repeat center;
}

.view {
	cursor: text;
	background: url('../../../public/static/icons/view.png') no-repeat center;
}

.like {
	cursor: pointer;
	background: url('../../../public/static/icons/like.png') no-repeat center;
}

.dislike {
	cursor: pointer;
	background: url('../../../public/static/icons/dislike.png') no-repeat center;
}

.download {
	background: url('../../../public/static/icons/download.png') no-repeat center;
	width: 24px;
	height: 24px;
}

.down {
	background: url('../../../public/static/icons/down.svg') no-repeat center;
	width: 24px;
	height: 24px;
}

.check {
	cursor: text;
	background: url('../../../public/static/icons/check.png') no-repeat center;
	width: 32px;
	height: 32px;
}

.check.business {
	cursor: text;
	background: url('../../../public/static/icons/check-business.png') no-repeat
		center;
	width: 32px;
	height: 32px;
}

.cabinet-link {
	cursor: text;
	background: url('../../../public/static/icons/cabinet-link.png') no-repeat
		center;
}

.arrow-right-business {
	cursor: pointer;
	background: url('../../../public/static/icons/arrow-right-business.png')
		no-repeat center;
}

.union {
	cursor: pointer;
	background: url('../../../public/static/icons/union.svg') no-repeat center;
}

.union_white {
	cursor: pointer;
	background: url('../../../public/static/icons/union-white.svg') no-repeat center;
}

.iconForward {
	cursor: pointer;
	display: inline-block;
	background: url('../../../public/static/icons/IconForward.svg') no-repeat
		center;
}

.iconForward-white {
	cursor: pointer;
	display: inline-block;
	background: url('../../../public/static/icons/IconForward-white.svg') no-repeat
		center;
}

.download-big {
	cursor: pointer;
	background: url('../../../public/static/icons/download-big.png') no-repeat
		center;
}

.close-menu {
	width: 24px;
	height: 24px;
	cursor: pointer;
	background: url('../../../public/static/icons/close-menu.png') no-repeat
		center;
}

.slider-arrow {
	cursor: pointer;
	background: url('../../../public/static/icons/slider-arrow.png') no-repeat
		center;
}

.arrow-business-link {
	cursor: pointer;
	background: url('../../../public/static/icons/arrow-business-link.png')
		no-repeat center;
}

.cross {
	cursor: pointer;
	width: 24px;
	height: 24px;
	background: url('../../../public/static/icons/cross.png');
}

.back {
	cursor: pointer;
	width: 24px;
	height: 24px;
	background: url('../../../public/static/icons/back.png');
}
