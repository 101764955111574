.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
  }
}
