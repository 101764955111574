.entity {
    width: 100%;
    min-height: 883px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px;
    box-sizing: border-box;
}

.header {
    display: flex;
    flex-direction: column;
}

.title {
    color: #8E9AA8;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 4px;
}

.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    &_big {
        font-size: 38px;
        font-weight: 800;
        line-height: 120%;
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings: 'liga' off;
        margin-bottom: 8px;
    }
}

.descript {
    font-size: 20px;
    color: #8E9AA8;
    font-weight: 400;
    line-height: 26px;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 6px;
    width: 600px;
    height: 46px;
    padding: 5px;
    box-sizing: border-box;
    background-color: #F8FAFD;
    border-radius: 8px;
}

.button {
    color: #71869D;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;

    &_active {
        background-color: #F1F4F9;
        color: #121922;
        font-size: 16px;
        line-height: 20px;
    }

    &_dis {
        pointer-events: none;
    }
}

.radio {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    >label {
        display: inline-block;
        max-width: 600px;
        font-family: 'Inter', sans-serif;
        vertical-align: sub;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #121922;
        padding-left: 34px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        width: 100%;
        text-align: left;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;
            transform: translateY(-50%);
            transition: all 0.2s linear;
            width: 20px;
            height: 20px;
            border: 2px solid #adbfdf;
            background-color: transparent;
            border-radius: 50%;
        }

        &:after {
            content: '';
            opacity: 0;
            position: absolute;
            display: block;
            background: #002cfb;
            width: 10px;
            height: 10px;
            top: 50%;
            left: 5px;
            z-index: 2;
            border-radius: 50%;
            transform: translateY(-50%);
        }

        @media (max-width: 575px) {
            padding-left: 34px;
            text-align: left;
        }
    }

    >input {
        appearance: none;
        position: absolute;

        &:checked+label::before {
            border: 2px solid #002cfb;
        }

        &:checked+label::after {
            opacity: 1;
        }

        &:hover+label::before {
            border: 2px solid #002cfb;
        }

        &:hover+label {
            color: #2e3c4e;
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (max-width: 575px) {
        margin-bottom: 12px;
    }
}

.subtitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 12px;
}

.block_inputs {
    width: 600px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    div {
        width: 194px;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 12px;
        box-sizing: border-box;
        background-color: #F1F4F9;
        border-radius: 4px;
    }

    input {
        border: none;
        background: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        width: 100%;


        &::placeholder {
            color: #71869D;
        }
    }

    &_org {
        div {
            width: calc(50% - 4px);
        }

    }

    &_ooo {
        gap: 12px;
        margin-bottom: 24px;


        div {
            width: 100%;
            padding: 9px 12px;

            textarea {
                background: none;
                border: none;
                width: 100%;
                height: 100%;
                resize: none;
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                overflow: auto;

                &:focus {
                    outline: none;
                }

                &::placeholder {

                    color: #71869D;
                }
            }
        }



        span {
            margin-top: -8px;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: #71869D;
        }
    }
}

.err {
    border: 1px solid #E75A5A;
    box-sizing: border-box;
}

.step {
    position: absolute;
    bottom: 40px;
    width: 600px;
    display: flex;
    justify-content: space-between;



    &_dis {
        button {
            background-color: #F1F4F9;
            color: #ADBFDF;
            pointer-events: none;
        }
    }
}

.button1 {
    min-width: 180px;
    width: fit-content;
    text-wrap: nowrap;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #002CFB;
    padding: 0 24px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 48px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        background-color: #0028e5;
    }

    &_dis {
        background-color: #F1F4F9;
        color: #ADBFDF;
        pointer-events: none;
    }
}

.button2 {
    height: 48px;
    width: 101px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid #647FFF;
    border-radius: 4px;
    color: #002CFB;
    font-size: 18px;
    font-weight: 500;
    line-height: 48px;
    transition: all 0.2s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    &:hover {
        color: #0028e5;
        border-color: #0028e5;
    }

    &_visible {
        opacity: 1;
        visibility: visible;
    }
}

.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #71869D;
    margin-bottom: 12px;
}

.orglist {
    width: 600px;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    position: absolute;
    right: 0;
    top: 40px;
    height: 220px;
    overflow-y: scroll;
    padding: 0;
    box-shadow: 0px 0px 50px 0px rgba(129, 135, 163, 0.20);
    border-radius: 8px;
    background-color: #F8FAFD;
    box-sizing: border-box;

    li {
        padding: 0;
        width: 100%;
        padding: 4px 12px;
        min-height: 30px;
        height: fit-content;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        transition: all 0.15s;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        &:hover {
            background-color: #F1F4F9;
        }
    }
}