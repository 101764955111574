.title {
	margin-bottom: 16px;

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 8px;
	}
}

.descr {
	font-weight: 400;
	font-size: 18px;
	color: #71869d;
	margin-bottom: 40px;

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 32px;
	}
}

.form {
	max-width: 416px;
	width: 100%;
}

.item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;

	&:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 575px) {
		flex-direction: column;
		margin-bottom: 24px;
		align-items: flex-start;
	}
}

.label {
	color: #8e9aa8;
	font-weight: 400;
	font-size: 18px;
	margin-right: 12px;

	@media (max-width: 575px) {
		margin-bottom: 14px;
		font-size: 14px;
		margin-bottom: 4px;
	}
}

.wrapperInput {
	position: relative;

	>svg {
		position: absolute;
		top: 50%;
		right: 12px;
		cursor: pointer;

		transform: translateY(-50%);
	}

	@media (max-width: 575px) {
		width: 100%;
	}
}

.input {
	font-weight: 500;
	font-size: 18px;
	background: #f1f4f9;
	color: #121922;
	border: 1px solid #ecf2fc;
	border-radius: 4px;
	font-family: inherit;
	min-height: 48px;
	min-width: 300px;
	padding: 0 12px;
	transition: all 0.2s ease-in-out;

	&::placeholder {
		color: #71869d;
	}

	&:hover {
		border: 1px solid #adbfdf;
		color: #2e3c4e;
	}

	@media (max-width: 575px) {
		min-width: 100%;
	}
}

.politic {
	position: absolute;
	bottom: 40px;
	left: 120px;
	max-width: 554px;
	width: 100%;
	font-weight: 400;
	font-size: 14px;
	color: #71869d;

	>a {
		color: #71869d;
		text-decoration: underline;
	}

	@media (max-width: 575px) {
		width: initial;
		position: static;
		line-height: 130%;
	}
}

.menu {
	position: absolute;
	z-index: 10;
	width: 100%;
	max-width: 360px;
	max-height: 200px;
	margin: 0 auto;
	padding: 9px 0;

	font-size: 18px;
	font-weight: 400;
	text-align: left;
	transition: background-color 0.3s ease;
	list-style-type: none;
	background: #fff;
	border: 1px solid #ecf2fc;
	color: #121922;
	box-shadow: 0px 0px 10px rgba(0, 32, 51, 0.1);
	border-radius: 4px;
	overflow-y: auto;
	cursor: pointer;
}

.city {
	transition: all 0.2s ease-in-out;
	padding: 8px 12px;
	line-height: 30px;

	&:hover {
		background: rgba(212, 223, 243, 0.17);
		color: #2e3c4e;
	}
}

.city_active {
	color: #002cfb;
}

.input_error {
	border: 1px solid red;
	transition: all 0.2s ease-in-out;
}

.wrapperBtn {
	width: 100%;
	display: flex;
	justify-content: center;
	position: fixed;
	z-index: 3;
	bottom: 16px;
	left: 0;

	>button {
		font-family: inherit;
		background: #002cfb;
		font-weight: 500;
		font-size: 18px;
		color: #fff;
		padding: 0 43px;
		min-height: 48px;
		border-radius: 4px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;
		&:disabled {
			pointer-events: none;
			background-color: #F1F4F9;
			color: #8E9AA8;
		}

		&:hover {
			background: #0024cb;
		}

		@media (max-width: 575px) {
			max-width: 350px;
			width: 100%;
			padding: 0;
			z-index: 30;
		}

		@media (max-width: 320px) {
			max-width: 304px;
		}
	}

	
} 

.container_check {
	width: 700%;

	@media(max-width: 1600px) {
		width: 150%;
	}

	@media(max-width: 1100px) {
		width: 100%;
	}
}


.checkbox {
	position: relative;
	margin-bottom: 8px;
	display: flex;
	align-items: flex-start;

	>label {
		font-family: 'Inter', sans-serif;
		vertical-align: sub;
		font-weight: 400;
		font-size: 16px;
		color: #71869d;
		padding-left: 34px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;
		text-align: start;

		@media(max-width: 720px) {
			font-size: 14px;
			color: #2e3c4e;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			margin-bottom: 20px;
			transform: translateY(-50%);
			transition: all 0.2s linear;
			width: 24px;
			height: 24px;
			border: 2px solid rgba(173, 191, 223, 0.4);
			border-radius: 6px;
			background-color: rgba(255, 255, 255, 0.56);

			@media(max-width: 720px) {
				top: 14px;
				transform: translateY(-50%) scale(0.8);
			}
		}

		&:after {
			content: url('../../../../../public/static/lk/checkboxDone.svg');
			opacity: 0;
			position: absolute;
			display: block;
			z-index: 2;
			top: 50%;
			left: 2px;
			color: #fff;
			transform: translateY(-50%);

			@media(max-width: 720px) {
				top: 14px;
				transform: translateY(-50%) scale(0.7);
			}
		}

	}

	
	&_checked {
		>label {
			color: #2e3c4e;
		}
	}

	>input {
		appearance: none;
		position: absolute;

		&:checked+label::before {
			border: 2px solid #002cfb;
			background: #002cfb;
			color: #2e3c4e;
		}

		&:checked+label::after {
			opacity: 1;
		}

		&:not(input:checked):hover+label::before {
			background: rgba(0, 44, 251, 0.05);
			border: 2px solid #526ed3;
		}

		&:hover+label {
			color: #2e3c4e;
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 12px;
	}
}