.form {
	position: relative;
	max-width: 582px;
	width: 100%;
	padding: 60px;
	background: #303550;
	border-radius: 12px;

	@media (max-width: 575px) {
		background: transparent;
		padding: 0 0 13px;
	}
}

.title {
	color: #ffffff;
	font-weight: 800;
	font-size: 36px;
	line-height: 120%;
	margin-bottom: 36px;

	@media (max-width: 575px) {
		text-align: start;
		font-size: 26px;
		margin-bottom: 32px;
	}
}

.tabs {
	max-width: 462px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 36px;

	@media (max-width: 320px) {
		flex-direction: column;
	}
}

.tab {
	min-height: 48px;
	padding: 0 24px;
	font-family: inherit;
	color: #e3e4e8;
	font-weight: 500;
	font-size: 18px;
	cursor: pointer;

	&.active {
		font-weight: 600;
		border-radius: 100px;
	}

	@media (max-width: 575px) {
		font-size: 14px;
		padding: 0 16px;
		min-height: 40px;
		line-height: 40px;
	}
}

.tab_active_customer {
	color: #ffffff;
	background: #3357ff;
}

.tab_active_business {
	color: #0f1324;
	background: #00ff88;
}

.tab_active_worker {
	color: #fff;
	background: #a033ff;
}

.subtitle {
	color: #ffffff;
	font-weight: 700;
	font-size: 22px;
	line-height: 120%;
	margin-bottom: 24px;

	@media (max-width: 575px) {
		text-align: start;
	}
}

.inputs {
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
}

.input {
	min-height: 48px;
	padding: 0 12px;
	font-family: inherit;
	background: rgba(0, 0, 0, 0.2);
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 4px;
	color: #fff;
	font-size: 18px;
	margin-bottom: 12px;
	transition: border 0.2s ease-in-out;

	&:last-child {
		margin-bottom: 0;
	}

	&.textarea {
		padding: 10.5px 12px;
		min-height: 108px;
		resize: none;
		outline: none;
	}

	&::placeholder {
		color: #abadba;
	}

	@media (max-width: 575px) {
		font-size: 16px;
		margin-bottom: 8px;
	}
}

.descr {
	color: rgba(255, 255, 255, 0.6);
	opacity: 0.6;
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
	margin-bottom: 36px;

	@media (max-width: 575px) {
		text-align: start;
		margin-bottom: 32px;
	}
}

.price {
	position: relative;
	> h5 {
		font-weight: 700;
		font-size: 22px;
		line-height: 120%;
		color: #ffffff;
		margin-bottom: 8px;

		@media (max-width: 575px) {
			font-size: 18px;
		}
	}

	> p {
		color: #00ff88;
		font-weight: 700;
		font-size: 40px;
		line-height: 120%;
		margin-bottom: 36px;

		@media (max-width: 575px) {
			font-size: 24px;
			margin-bottom: 32px;
		}
	}

	@media (max-width: 575px) {
		text-align: start;
	}
}

.price_business {
	> p {
		color: #00ff88;
	}
}

.price_customer {
	> p {
		color: #647fff;
	}
}

.price_worker {
	> p {
		color: #b866ff;
	}
}

.error {
	border: 1px solid #ff0041;
}
