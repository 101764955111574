.article-slide {
  cursor: pointer;
  &__title {
    width: 200px;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #8f92a3;
    margin-bottom: 16px;
    transition: all 0.2s linear;
  }

  &__descr {
    max-width: 200px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
    color: #4d5268;
  }

  img {
    max-width: 120px !important;
  }

  &:hover {
    .article-slide__title {
      color: #00cc6e;
    }

    .article-slide__descr {
      color: #0f1324;
    }
  }
}

.article-slide-client {
  display: inline-block;
  max-width: 424px;
  background: #f9f9f9;
  box-shadow: 0px 2px 6px rgba(166, 166, 166, 0.25);
  border-radius: 12px;
  text-decoration: none;
  &__inner {
    width: 424px;
    padding: 24px;

    @media (max-width: 768px) {
      padding: 24px 10px 24px 0px;
    }
  }
  &__logo {
    max-width: 118px;
    min-height: 18px;
    margin-bottom: 16px;
  }

  &__img {
    display: flex;
    justify-content: space-between;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &:hover {
      svg {
        path {
          fill: #000729;
        }
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      max-width: 300px;
      margin-left: 10px;
      align-items: center;
    }
  }

  &__slogan {
    max-width: 350px;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #303550;
    margin-bottom: 16px;
  }
  &__name {
    text-align: center;
    margin-top: 5px;
    color: #4d5268;
  }
}

.article-slide-partner {
  width: 213px;
  display: inline-block;
  position: relative;
  text-decoration: none;
  cursor: pointer;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 768px) {
      display: block;
    }
  }

  &__descr {
    font-size: 18px;
    max-width: 182px !important;
    width: 100%;
    color: #000729;
    margin-top: 24px;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 14px;
      text-align: center;
    }
  }
  &__name {
    color: #4d5268;
    font-weight: 400;
    font-size: 14px;
    margin-top: 8px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &__img {
    border-radius: 300px;
    width: 160px;
    height: 170px;
    filter: drop-shadow(0 2px 6px rgba(#a6a6a6, 25%));

    @media (max-width: 768px) {
      width: unset;
      height: 170px;
    }
  }

  &__wrapper {
    position: relative;

    p {
      max-width: 170px;
      width: 100%;
      position: absolute;
      text-transform: uppercase;
      color: #f9f9f9;
      font-weight: 600;
      font-size: 12px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: drop-shadow(0 4px 4px rgba(#000, 25%));
    }

    @media (max-width: 768px) {
      margin: 0 10px;
    }
  }

  @media (max-width: 768px) {
    width: unset;
    line-height: 130%;
  }
}

.article-slide-services {
  //display: inline-block;
  text-decoration: none;

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
    color: #abadba;
    transition: all 0.2s linear;
    text-transform: uppercase;
  }
  &__name {
    font-weight: 600;
    font-size: 18px;
    margin-top: 16px;
    text-align: center;
    line-height: 130%;
    color: #303550;

    @media (max-width: 768px) {
      //max-width: 343px;
      font-size: 14px;
    }
  }

  img {
    width: 100%;
    max-width: 213px;
    @media (max-width: 768px) {
      max-width: unset !important;
    }
  }

  &:hover {
    .article-slide-services__title {
      color: #00cc6e;

      &--customer {
        color: #002cfb;
      }

      &--worker {
        color: #8800ff;
      }

      &--business {
        color: #00ff88;
      }
    }
  }
}

.slick-slide {
  margin-right: 24px;

  @media (max-width: 768px) {
    margin: unset;
  }
}

.slick-track {
  display: flex;
  align-items: flex-start;
}
