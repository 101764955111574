.cover {
	max-width: 598px;
	width: 100%;

	@media (max-width: 1600px) {
		width: 486px;
	}

	@media (max-width: 575px) {
		margin-bottom: 44px;
	}
}

.img {
	margin-bottom: 20px;

	@media (max-width: 1600px) {
		width: 100%;
	}

	@media (max-width: 575px) {
		margin-bottom: 0;
	}
}

.pages,
.logos {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pages {
	margin-bottom: 31px;
    img {
		@media(max-width: 1600px) {
			width: 150px;
			height: auto;
		}
	}

}

.logos {
	max-width: 465px;
	width: 100%;
}
