.window {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    z-index: 15;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: all 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;

    &_anim {
        background-color: rgba(0, 0, 0, 0.3);
    }

    

}

.picture {
    height: 80vh;
    width: auto;
    transform: translateY(-2000px);
    transition: all 0.4s;
    position: relative;
    

    @media (max-width: 768px) {
        width: 80%;
        height: auto;
        margin-bottom: 140px;
    }

    &_anim {
        transform: translateY(0);
    }

    img {
        height: 100%;
        width: 100%;
    }
}

.close {
    position: absolute;
    right: -40px;
    top: -40px;
    cursor: pointer;
    transition: all 0.2s;
    z-index: 12;
    opacity: 0;

    @media (max-width: 768px) {
        right: -4px;
        top: -30px;
    }

    &_anim {
        opacity: 1;
    }

    &:hover {
        opacity: 0.7;
    }
}