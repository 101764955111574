.panel {
	position: relative;
	z-index: 11;
	width: 100%;
	max-width: 437px;
	padding: 16px;

	background: rgba(212, 223, 243, 0.17);
	border: 1px solid #ecf2fc;
	border-radius: 8px;
	margin-bottom: 209px;

	> h5 {
		color: #121922;
		font-family: 'Inter', sans-serif;
		font-weight: 600;
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 32px;

		@media (max-width: 575px) {
			font-size: 18px;
			text-align: center;
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 32px;
		padding: 16px 8px;
	}
}

.item {
	> p {
		color: #71869d;
		font-family: 'Inter', sans-serif;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 8px;
	}

	margin-bottom: 12px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.field {
	position: relative;
	display: inline-block;
	width: 100%;
	font-family: 'Inter';
	font-size: 18px;
	min-height: 48px;
	line-height: 48px;
	background: #f1f4f9;
	border-radius: 4px;
	padding-left: 13px;
	color: #8e9aa8;
	cursor: pointer;
}

.copyText {
	position: absolute;
	top: 0;
	right: 15px;
	color: #ccc;
	font-size: 12px;
}
