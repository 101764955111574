.cover {
	max-width: 598px;
	width: 100%;

	@media (max-width: 575px) {
		margin-bottom: 44px;
	}
}

.img {
	margin-bottom: 20px;

	@media (max-width: 575px) {
		margin-bottom: 0;
	}
}

.logos {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pages {
	max-width: 507px;
	width: 100%;
	margin-bottom: 31px;

	> img {
		max-width: 161px;
		width: 100%;
		object-fit: none;
		margin-right: 12px;

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.logos {
	max-width: 465px;
	width: 100%;
}
