.button {
	
	position: relative;
	z-index: 10;
	font-family: inherit;
	font-weight: 500;
	font-size: 18px;
	line-height: 48px;
	padding: 0 40px;
	color: #ffffff;
	border-radius: 4px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&.blue {
		background: #002cfb;

		&:hover {
			background: #0024cb;
		}
	}

	&.green {
		background: #00ff88;
		color: #000729;
		font-weight: 600;
		font-size: 18px;

		&:hover {
			background: #00cc6e;
		}
	}

	&.purple {
		background: #8800ff;
		color: #ffffff;
		font-weight: 600;
		font-size: 18px;

		&:hover {
			background: #621f9d;
		}
	}

	&.white {
		color: #002cfb;
		background: #fff;
		border: 1px solid #002cfb;

		&:hover {
			color: #0024cb;
			border: 1px solid #0024cb;
		}
	}

	@media (max-width: 575px) {
		padding: 0;
		position: fixed;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 350px;
		width: 100%;
		z-index: 30;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.position {
	position: static;
	transform: translateX(0);
}

.skilla_book {
	position: static;

	@media (max-width: 575px) {
		transform: translateX(0);
		width: 343px !important;
		margin: 0 auto;
	}

	@media (max-width: 320px) {
		width: 304px !important;
	}
}

.flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon {
	width: 24px;
	height: 24px;
	margin-left: 14px;
}
