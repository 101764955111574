.ulItem {
	padding: 0;
	display: flex;
	margin-bottom: 16px;

	&:last-child {
		margin-bottom: 0;
	}

	> span {
		font-family: 'Inter', sans-serif;
		margin-left: 8px;
		color: #71869d;
		font-weight: 500;
		font-size: 18px;

		@media (max-width: 575px) {
			margin-left: 12px;
		}
	}

	@media (max-width: 575px) {
		line-height: 20px;
		margin-bottom: 20px;
	}
}

.ulItem_contract {
	align-items: center;
}
