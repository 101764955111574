.advantage {
	position: absolute;
	background: #303550;
	border-radius: 12px;
	padding: 40px;

	@media (max-width: 575px) {
		padding: 24px 16px 20px;
		position: relative;
		z-index: 1;
	}
}

.subtitle {
	color: #abadba;
	font-weight: 400;
	font-size: 24px;
	margin-bottom: 16px;

	@media (max-width: 575px) {
		margin-bottom: 24px;
		font-size: 20px;
	}
}

.title {
	max-width: 697px;
	width: 100%;
	color: #ffffff;
	margin-bottom: 48px;
	font-weight: 700;
	font-size: 46px;

	@media (max-width: 575px) {
		max-width: 311px;
		margin-bottom: 32px;
		font-size: 26px;
	}
}

.list {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.item {
	display: flex;
	align-items: flex-start;
	color: #e3e4e8;
	font-weight: 500;
	font-size: 22px;
	line-height: 32px;
	padding: 0;
	margin-bottom: 20px;

	> img {
		padding-right: 16px;
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 575px) {
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;
		margin-bottom: 12px;
	}
}

.firstSection {
	max-width: 688px;
	width: 100%;
	top: 120px;
	right: 260px;

	@media (max-width: 575px) {
		top: 320px;
	}
}

.secondSection {
	max-width: 688px;
	width: 100%;
	top: 120px;
	left: 260px;

	@media (max-width: 575px) {
		top: 177px;
	}
}

.thirdSection {
	max-width: 925px;
	width: 100%;
	top: 120px;
	right: 260px;

	@media (max-width: 575px) {
		top: 40px;
	}
}

.fourthSection {
	max-width: 688px;
	width: 100%;
	top: 120px;
	left: 260px;

	@media (max-width: 575px) {
		top: 346px;
	}
}

.fifthSection {
	max-width: 688px;
	width: 100%;
	top: 120px;
	right: 260px;

	@media (max-width: 575px) {
		top: 356px;
	}
}

.firstSection,
.secondSection,
.thirdSection,
.fourthSection,
.fifthSection {
	@media (max-width: 575px) {
		max-width: 343px;
		width: 100%;
		left: 50%;
		right: 0;
		transform: translateX(-50%);
	}
}
