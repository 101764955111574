.buy {
	margin-left: 58px;

	@media (max-width: 1600px) {
		margin-left: 28px;
	}

	@media (max-width: 575px) {
		margin-left: 0;
	}
}

.info {
	margin-bottom: 30px;
	> h2 {
		margin-bottom: 15px;
		font-weight: 800;
		font-size: 38px;
		color: rgba(31, 51, 69, 0.9);
	}

	> h5 {
		color: rgba(31, 51, 69, 0.9);
		font-weight: 500;
		font-size: 25px;
		line-height: 140%;
		margin-bottom: 15px;
	}

	> p {
		color: #8e9aa8;
		font-weight: 500;
		font-size: 22px;
	}

	@media (max-width: 575px) {
		> h2 {
			font-weight: 800;
			font-size: 24px;
			line-height: 120%;
			margin-bottom: 10px;
		}

		> h5 {
			font-weight: 500;
			font-size: 18px;
			line-height: 26px;
			margin-bottom: 10px;
		}

		> p {
			font-weight: 400;
			font-size: 16px;
		}
	}

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 32px;
	}
}

.btns {
	max-width: 520px;
	width: 100%;
	margin-bottom: 30px;

	@media (max-width: 575px) {
		padding: 0;
		position: fixed;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 350px;
		width: 100%;
		margin-bottom: 0;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;

	@media (max-width: 575px) {
		flex-direction: column;
		justify-content: center;
		margin-bottom: 0;

		> button {
			margin-bottom: 16px;
		}
	}
}

.descr {
	> p {
		color: #71869d;
		font-weight: 500;
		font-size: 18px;
		line-height: 140%;
		margin-bottom: 15px;

		&:last-of-type {
			margin-bottom: 0;
		}

		@media (max-width: 575px) {
			font-weight: 400;
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 38px;
	}
}

.logos {
	@media (max-width: 575px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.readAll,
.readSnippet {
	display: inline-block;
	position: relative;
	z-index: 10;
	font-family: inherit;
	font-weight: 500;
	font-size: 18px;
	line-height: 48px;
	padding: 0 40px;
	color: #ffffff;
	border-radius: 4px;
	background: #002cfb;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		background: #0024cb;
	}

	@media (max-width: 575px) {
		width: 100%;
		text-align: center;
	}
}

.readSnippet {
	min-width: 234px;

	@media (max-width: 575px) {
		margin-bottom: 16px;
	}
}
