.header {
	position: absolute;
	top: -96px;
	height: 80px;
	max-width: 1440px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;


	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 1800px) {
			object-fit: contain;
		}
	}

	@media (max-width: 575px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.header_btn {
	position: absolute;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);

	@media (max-width: 1600px) {
		right: 440px;
	}
}

.contract {
	padding: 40px 22px 40px 40px;

	@media (max-width: 575px) {
		padding: 24px 0 0;
		margin: 0 -8px;
	}
}

.contract_vipStatus {
	padding: 0;
	@media (max-width: 575px) {
		width: 100vw;
		margin-left: -16px;
	}
}

.subtitle {
	margin-bottom: 10px;
	color: #8e9aa8;
	font-weight: 700;
	font-size: 30px;

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 8px;
	}
}

.title {
	margin-bottom: 40px;

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 32px;
	}
}

.list {
	padding: 0;
}

.list_title {
	margin-bottom: 24px;
	font-weight: 600;
	font-size: 20px;
	color: #121922;

	@media (max-width: 575px) {
		font-size: 18px;
	}
}

.left,
.right,
.top_notPremium,
.bottom_notPremium {
	max-width: 480px;
	width: 100%;
	margin-right: 20px;
	padding: 16px;
	background: rgba(212, 223, 243, 0.17);
	border-radius: 8px;
}

.left {
	@media (max-width: 575px) {
		margin-bottom: 16px;
	}
}

.top_notPremium,
.bottom_notPremium {
	max-width: 842px;
}
.bottom_notPremium {
	margin-bottom: 24px;
	max-width: 720px;
}

.right {
	max-width: 430px;
	margin-right: 0;
}

.top_notPremium {
	margin-bottom: 20px;
}

.info {
	display: flex;
	align-items: flex-start;
	margin-bottom: 40px;

	@media (max-width: 575px) {
		flex-direction: column;
		margin-bottom: 32px;
	}
}

.info_notPremium {
	display: flex;
	flex-direction: column;
}

.date {
	position: relative;
	z-index: 2;
	font-family: 'Inter', sans-serif;
	margin-bottom: 16px;
	font-weight: 400;
	font-size: 18px;
	color: #71869d;

	@media (max-width: 575px) {
		max-width: 304px;
		width: 100%;
		line-height: 26px;
	}
}

.btns,
.btn {
	display: flex;
	align-items: center;

	> button {
		position: relative;
		z-index: 1;
		font-family: inherit;
		font-size: 18px;
		font-weight: 500;
		margin-right: 16px;
		min-height: 48px;
		padding: 0 24px;
		background: #002cfb;
		border-radius: 4px;
		color: #fff;
		cursor: pointer;
		transition: all 0.2s ease-in-out;

		&:hover {
			background: #0024cb;
		}

		@media (max-width: 575px) {
			max-width: 350px;
			width: 100%;
			margin-right: 0;
			font-size: 16px;
			z-index: 30;
		}

		@media (max-width: 320px) {
			max-width: 304px;
		}
	}

	> a {
		position: relative;
		z-index: 1;
		font-family: inherit;
		min-height: 48px;
		line-height: 48px;
		font-size: 18px;
		padding: 0 24px;
		font-weight: 500;
		color: #002cfb;
		border: 1px solid #002cfb;
		border-radius: 4px;
		cursor: pointer;
		transition: all 0.2s ease-in-out;

		&:hover {
			border: 1px solid #0024cb;
			color: #0024cb;
		}

		@media (max-width: 575px) {
			max-width: 350px;
			width: 100%;
			background-color: #fff;
			text-align: center;
			margin-top: 10px;
			z-index: 30;
		}

		@media (max-width: 320px) {
			max-width: 304px;
		}
	}
}

.btn {
	> button {
		&:last-of-type {
			display: block;
			position: relative;
			z-index: 1;
			font-family: inherit;
			font-size: 18px;
			font-weight: 500;
			min-height: 48px;
			padding: 0 126.5px;
			background: #002cfb;
			border-radius: 4px;
			color: #fff;
			cursor: pointer;
			transition: all 0.2s ease-in-out;

			&:hover {
				background: #0024cb;
				color: #fff;
			}

			@media (max-width: 575px) {
				width: 100%;
				padding: 0;
			}
		}
	}

	@media (max-width: 575px) {
		position: fixed;
		min-width: 350px;
		bottom: 8px;
		left: 50%;
		z-index: 2;
		transform: translateX(-50%);
		justify-content: center;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.btns {
	@media (max-width: 575px) {
		justify-content: center;
		flex-direction: column;
	}
}

.founder {
	position: absolute;
	bottom: 0;
	right: 0;

	@media (max-width: 1600px) {
		display: none;
	}

	@media (max-width: 575px) {
		display: block;
		position: static;
		width: 110%;
		margin-left: -26px;
	}
}

.inner {
	padding: 40px 32px 40px 40px;
	height: 688px;
	overflow-y: auto;
	scrollbar-width: thin;

	> h3 {
		margin-bottom: 16px;
		color: #000729;
		font-weight: 600;
		font-size: 22px;
	}

	> p {
		margin-bottom: 16px;
		color: #000729;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;

		&:last-of-type {
			margin-bottom: 0;
		}

		@media (max-width: 575px) {
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
		}
	}

	@media (max-width: 575px) {
		height: 100%;
		padding: 24px 16px 0 8px;
		margin-right: -16px;
	}
}

.consent {
	max-width: 1440px;
	width: 100%;
	padding: 28px 32px;
	margin-top: 16px;
	background: #ffffff;
	border-radius: 8px;

	> form {
		display: flex;
		justify-content: space-between;
		@media (max-width: 575px) {
			flex-direction: column;
		}
	}

	@media (max-width: 575px) {
		padding: 24px 0 0;
	}
}

.popup_sign {
	padding: 24px 16px 8px;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 100;
	background: #ffffff;
	border-radius: 8px 8px 0px 0px;
}

.go_sign {
	min-height: 48px;
	min-width: 350px;
	position: fixed;
	font-family: inherit;
	bottom: 8px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
	background: #fff;
	color: #002cfb;
	font-weight: 500;
	font-size: 18px;
	border: 1px solid #002cfb;
	border-radius: 4px;

	@media (max-width: 320px) {
		min-width: 304px;
	}
}

.checkbox {
	position: relative;
	margin-bottom: 24px;

	&:last-of-type {
		margin-bottom: 0;
	}

	> label {
		vertical-align: sub;
		font-family: 'Inter', sans-serif;
		max-width: 607px;
		display: inline-block;
		font-weight: 400;
		font-size: 18px;
		color: #121922;
		padding-left: 34px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;

		> a {
			color: #121922;
			text-decoration-line: underline;
		}

		> span {
			max-width: 607px;
			display: inline-block;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 0;

			transform: translateY(-50%);
			transition: all 0.2s linear;
			width: 24px;
			height: 24px;
			border: 2px solid rgba(173, 191, 223, 0.4);
			border-radius: 6px;
			background-color: rgba(255, 255, 255, 0.56);

			@media (max-width: 575px) {
				top: 0;
				transform: initial;
			}
		}

		&:after {
			content: url('../../../../public/static/lk/checkboxDone.svg');
			opacity: 0;
			position: absolute;
			display: block;
			z-index: 2;
			top: 50%;
			left: 2px;
			color: #fff;
			transform: translateY(-50%);

			@media (max-width: 575px) {
				top: 4px;
				transform: initial;
			}
		}

		@media (max-width: 575px) {
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
		}
	}

	> input {
		appearance: none;
		position: absolute;

		&:checked + label::before {
			border: 2px solid #002cfb;
			background: #002cfb;
		}

		&:checked + label::after {
			opacity: 1;
		}

		&:not(input:checked):hover + label::before {
			background: rgba(0, 44, 251, 0.05);
			border: 2px solid #526ed3;
		}

		&:hover + label {
			color: #2e3c4e;
		}
	}

	@media (max-width: 575px) {
		text-align: initial;
	}
}

.inner::-webkit-scrollbar {
	width: 6px;
	background: #c4ceff;
}

.inner::-webkit-scrollbar-thumb {
	background: #002cfb;
	border-radius: 10px;
}

.error {
	&:before {
		border: 2px solid #e10d0d !important;
	}
}

.inputNumber {
	font-weight: 500;
	font-size: 18px;
	background: #f1f4f9;
	color: #121922;
	border: 1px solid #ecf2fc;
	border-radius: 4px;
	font-family: inherit;
	min-height: 48px;
	min-width: 365px;
	padding: 0 12px;
	transition: all 0.2s ease-in-out;

	&::placeholder {
		color: #71869d;
	}

	@media (max-width: 575px) {
		min-width: 100%;
		
	}
}

.item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;

	&:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 575px) {
		flex-direction: column;
		margin-bottom: 24px;
		margin-top: 32px;
		align-items: flex-start;
	}
}

.wrapperInput {
	position: relative;

	> svg {
		position: absolute;
		top: 50%;
		right: 12px;
		cursor: pointer;

		transform: translateY(-50%);
	}

	@media (max-width: 575px) {
		width: 100%;
	}
}

.menu {
	position: absolute;
	top: -113px;
	z-index: 10;
	width: 100%;
	max-width: 360px;
	max-height: 200px;
	margin: 0 auto;
	padding: 9px 0;
	font-size: 18px;
	font-weight: 400;
	text-align: left;
	transition: background-color 0.3s ease;
	list-style-type: none;
	background: #fff;
	border: 1px solid #ecf2fc;
	color: #121922;
	box-shadow: 0px 0px 10px rgba(0, 32, 51, 0.1);
	border-radius: 4px;
	overflow-y: auto;
	cursor: pointer;
}

.number {
	transition: all 0.2s ease-in-out;
	padding: 8px 12px;
	line-height: 30px;

	&:hover {
		background: rgba(212, 223, 243, 0.17);
		color: #2e3c4e;
	}
}

.number_active {
	color: #002cfb;
}

.btn_contract {
	margin-top: 24px;
	background: #002cfb;
	border-radius: 4px;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	min-height: 48px;
	padding: 0 40px;
	transition: all 0.2s ease-in;
	cursor: pointer;

	&:hover {
		background: #0024cb;
	}

	@media (max-width: 575px) {
		width: 100%;
		font-size: 15px;
		padding: 0;
		margin-top: 16px;
	}
}
