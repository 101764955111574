.button_mob {
    margin-top: 18px;
    width: 100%;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    font-weight: 600;
    color: #fff;
    border: 0;
    font-size: 16px;
    border-radius: 4px;
    background-color: rgba(149, 168, 255, 0.2);
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    span {
        margin-left: 12px;
        display: flex;
        align-items: center;
    }
}