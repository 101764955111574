@import '../../../styles/vendor/variables.scss';

.lkSidebar {
	position: fixed;
	height: 100%;
	z-index: 100;
	background: #091336;
	max-width: $width-lkSidebar;
	width: 100%;
	overflow-y: auto;
	transition: transform 0.3s ease-in-out;

	scrollbar-width: 0;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: gray;
		border-radius: 30px;
	 }

	@media (max-width: 770px) {
		max-width: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	}
}

.hide {
	transform: translateX(115%);
}

.logo {
	margin-left: 20px;
	margin-top: 20px;
	width: 180px;
	height: 32px;
}

.container {
	max-width: 224px;
	width: 100%;
	margin: 0 auto;

	@media (max-width: 770px) {
		max-width: 100%;
	}

	@media (max-width: 525px) {
		max-width: 100%;
	}
}
