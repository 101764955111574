.doc {
    .header-bar {
        min-height: 0;
    }
    
    div {
        overflow: hidden;
        min-height: 0;
    }
}

