.list {
	list-style-type: none;
	padding: 0;
	margin-top: 17px;

	@media (max-width: 575px) {
		padding: 0 16px;
	}
}

.wrapper2 {
	display: flex;
	align-items: center;
}

.footer {
	max-width: 224px;
	width: 100%;
	margin-top: 40px;
	padding-bottom: 24px;

	@media (max-width: 770px) {
		padding: 0 16px 24px;
		max-width: 100%;
	}
}

.footer_btns {
	text-align: center;
}

.btn {
	font-family: inherit;
	display: inline-block;
	width: 100%;
	font-weight: 500;
	font-size: 16px;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	background: transparent;
	color: #fff;
	text-align: center;
	line-height: 42px;
	margin-bottom: 8px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:last-of-type {
		margin-bottom: 0;
	}

	&:hover {
		background: rgba(255, 255, 255, 0.1);
	}
}

.notice {
	position: fixed;
	top: 30px;
	right: 60px;
	padding: 8px;
	max-width: 350px;
	align-items: center;
	background-color: #fff;
	box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.10);
	border-radius: 4px;
	display: flex;
	font-size: 14px;
    font-weight: 500;
    line-height: 14px;
	color: rgba(31, 51, 69, 0.9);


	&__line {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 3px;
		margin-right: 8px;
		border-radius: 8px;
		background-color: #002efbc7;
		transition: all 2.5s;
	}

}

.btn_book {
	line-height: 120%;
	padding: 12px;
	margin-bottom: 8px !important;
}

.btn_call {
	background-color: #002cfb;
	border-color: #002efb00;
}

.btn_call:hover {
	background-color: #0126D4;
}

.btn_close {
	align-self: flex-start;
	margin-top: -2px;
	cursor: pointer;
}

.btn_close path {
	transition: all 0.2s;
}

.btn_close:hover path{
	fill: #939ba9;
}

.btn_active {
	background: rgba(255, 255, 255, 0.1);
}

.log_out {
	margin-top: 34px;

	@media (max-width: 770px) {
		line-height: 130%;
	}
}

.wrapper {
	padding-top: 16px;
	display: flex;
	align-items: self-start;
	justify-content: space-between;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		height: 2px;
		z-index: 1;
		top: -16px;
		left: 0;
		background: rgba(255, 255, 255, 0.1);
	}
}

.wrapper3 {
	display: flex;

	> img {
		width: 40px;
		height: 60px;
		object-fit: cover;
	}
}

.info_book {
	text-align: left;
	margin-left: 10px;

	> p {
		margin-bottom: 8px;
		font-weight: 500;
		font-size: 16px;
		line-height: 120%;

		&:last-of-type {
			margin-bottom: 0;
			color: #8e9aa8;
			font-size: 14px;
		}
	}

	@media (max-width: 770px) {
		margin-left: 40px;
	}
}

.name {
	max-width: 150px;
	margin-bottom: 4px;
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	font-size: 16px;
	color: #fff;

	@media (max-width: 770px) {
		font-size: 22px;
	}
}

.prof {
	color: #8e9aa8;
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	font-size: 14px;

	@media (max-width: 770px) {
		font-size: 16px;
	}
}

.img {
	width: 40px;
	height: 40px;
	margin-right: 20px;
}

.btn_log_out {
	width: 24px;
	height: 24px;
	cursor: pointer;

	> img {
		width: 100%;
		height: 100%;
	}
}
