.footerBook {
	background: #000729;
	width: 100%;
	min-height: 271px;
}

.inner {
	padding-top: 32px;
	display: flex;
	align-items: flex-start;

	@media (max-width: 575px) {
		flex-direction: column;
		padding-top: 48px;
		padding-bottom: 48px;
	}
}

.container {
	margin: 0 auto;
	width: 1400px;

	@media (max-width: 575px) {
		max-width: 343px;
	}
}

.content {
	max-width: 625px;
	width: 100%;
	margin-left: 195px;

	> h5 {
		color: rgba(255, 255, 255, 0.6);
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 8px;
	}

	> h2 {
		color: #00ff88;
		font-weight: 800;
		font-size: 27px;
		margin-bottom: 12px;

		@media (max-width: 575px) {
			margin-bottom: 16px;
		}
	}

	> p {
		color: #e3e4e8;
		font-weight: 400;
		font-size: 16px;
		line-height: 120%;
		margin-bottom: 22px;

		@media (max-width: 575px) {
			margin-bottom: 36px;
		}
	}

	@media (max-width: 575px) {
		margin-left: 0;
	}
}

.customer {
	> h2 {
		color: #3357ff;
	}

	> form {
		> div {
			> button {
				background: #002cfb;
				color: #fff;
				transition: all 0.2s ease-in-out;
	
				&:hover {
					background: #0024cb;
				}
			}
		}
	}
}

.business {
	> h2 {
		color: #00ff88;
	}

	> form {
		> div {
			> button {
				background: #00ff88;
				color: #000729;
				transition: all 0.2s ease-in-out;
	
				&:hover {
					background: #00cc6e;
				}
			}
		}
	}
}

.worker {
	> h2 {
		color: #b866ff;
	}

	> form {
		> div {
			> button {
				background: #6f1cb7;
				color: #fff;
				transition: all 0.2s ease-in-out;
	
				&:hover {
					background: #621f9d;
				}
			}
		}
	}
}

.form {
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;

	@media (max-width: 575px) {
		flex-direction: column;
	}
}
.form_transform {
	flex-direction: column;
	max-width: 350px;
	width: 100%;
	flex-direction: column-reverse;
}

.input {
	font-family: inherit;
	max-width: 350px;
	min-height: 48px;
	width: 100%;
	background: rgba(0, 0, 0, 0.2);
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 4px 0px 0px 4px;
	padding-left: 13px;
	font-weight: 500;
	color: #ffffff;
	font-size: 18px;

	&::placeholder {
		color: #ffffff;
		font-weight: 500;
		font-size: 18px;
	}

	@media (max-width: 575px) {
		margin-bottom: 8px;
	}
}

.input_hidden {
	opacity: 0;
	visibility: hidden;
}

.btn {
	margin-left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;

	font-size: 16px;
	line-height: 120%;
	font-weight: 600;
	min-width: 297px;

	background: #00ff88;
	border-radius: 0 4px 4px 0;

	font-size: 16px;
	transition: background-color 0.3s ease;
	color: #000729;
	cursor: pointer;
	font-family: inherit;

	& span {
		margin-left: 12px;
	}

	&:hover {
		background-color: #00cc6e;
	}

	@media (max-width: 575px) {
		font-size: 18px;
		margin-bottom: 20px;
	}
}

.input_error {
	border: 1px solid red;
	transition: all 0.2s ease-in-out;
}

.wrapper {
	display: flex;

	@media (max-width: 575px) {
		flex-direction: column;
	}
}
