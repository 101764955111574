
.title {
	color: rgba(31, 51, 69, 0.9);
	font-weight: 500;
	font-size: 25px;
	margin-bottom: 32px;
}

.items {
	display: flex;
	justify-content: space-between;
	

	@media (max-width: 575px) {
		flex-direction: column;
	}
}

.item {
	max-width: 588px;
	width: 100%;
	display: flex;
	padding: 25px 20px;
	justify-content: space-between;
	align-items: flex-start;
	background: rgba(212, 223, 243, 0.17);
	border-radius: 8px;
	margin-right: 20px;

	&:last-child {
	    margin-right: 0;
	}

	@media (max-width: 575px) {
		margin-bottom: 16px;
		flex-direction: column;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.wrapper {
	@media (max-width: 575px) {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
	}
}

.img {
	width: 108px;
	height: 108px;
	border-radius: 50%;
	margin-right: 20px;

	@media (max-width: 575px) {
		width: 63px;
		height: 63px;
	}
}

.info {
	@media (max-width: 575px) {
		font-weight: 500;
		font-size: 16px;
		line-height: 130%;
		color: rgba(9, 19, 54, 0.5);
	}
}

.content {
	margin-left: 20px;
	> p {
		color: #121922;
		font-weight: 600;
		font-size: 18px;
		line-height: 130%;
		margin-bottom: 40px;

		@media (max-width: 575px) {
			margin-bottom: 0;
		}
	}

	> div {
		color: rgba(9, 19, 54, 0.5);
		font-weight: 500;
		font-size: 18px;
		line-height: 130%;
	}

	@media (max-width: 575px) {
		display: flex;
		flex-direction: column-reverse;
		margin-left: 0;
	}
}
