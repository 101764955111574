.articleBtns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1100px;
  margin-top: 32px;

  &__items {
    &-footer {
      display: grid;
      gap: 10px 8px;
      grid-template-columns: repeat(3, auto);
      a {
        text-align: center;
      }

      @media (max-width: 768px) {
        margin-left: 10px;
      }
    }

    @media (max-width: 768px) {
      max-width: 375px;
      width: 100%;
      gap: 10px;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      flex-wrap: wrap;
      overflow-x: auto;
      padding-bottom: 20px;
    }
  }

  &__item-header {
    margin-bottom: 10px;
  }

  &__item {
    &-header,
    &-footer {
      font-size: 12px;
      font-weight: 600;
      font-family: inherit;
      color: #e3e4e8;
      background: transparent;
      border: 1px solid #8f92a3;
      border-radius: 0.33em;
      text-transform: uppercase;
      padding: 0.3em 0.65em;
      margin-left: 8px;
      transition: all 0.2s linear;
      cursor: pointer;

      &:first-child {
        @media (max-width: 1600px) {
          margin-left: 8px;
          margin-bottom: 8px;
        }

        @media (max-width: 768px) {
          margin-left: 0;
          margin-bottom: 0;
        }
      }

      @media (max-width: 768px) {
        margin-left: 0;
        min-width: 250px;
        text-align: center;
      }
    }

    &-header {
      display: inline-block;
      &:hover {
        color: #c6c8d3;
      }
    }

    &-footer {
      margin-left: 0;
      color: #8f92a3 !important;

      &:hover {
        color: #000 !important;
      }
    }
  }

  .line {
    margin: 0 16px 0 16px;

    @media (max-width: 1600px) {
      display: none;
    }
  }

  .date {
    color: #e3e4e8;
    &.footer {
      color: #8f92a3;
      @media (max-width: 768px) {
        margin-left: 10px;
      }
    }

    @media (max-width: 768px) {
      margin: 10px 0;
    }
  }
  a {
    text-decoration: none;
  }

  @media (max-width: 1600px) {
    display: block;
    width: 100%;
  }

  @media (max-width: 768px) {
    max-width: initial;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    z-index: 1;
    align-items: unset;
    line-height: 130%;
  }
}
