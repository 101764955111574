.header {
	position: absolute;
	top: -96px;
	height: 80px;
	max-width: 1440px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 1800px) {
			object-fit: contain;
		}
	}

	@media (max-width: 575px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.header_btn {
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);

	@media (max-width: 1600px) {
		right: 440px;
	}
}


.application {
	padding: 40px;
	overflow: hidden;
	position: relative;

	@media (max-width: 575px) {
		padding: 24px 0 0;
		margin-bottom: -12px;
	}
}

form {
	@media (max-width: 575px) {
		text-align: center;
	}
}

.wrapper_step1 {
	max-width: 690px;
	width: 100%;
	margin-bottom: 40px;

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 12px;
	}
}

.wrapper {
	max-width: 517px;
	width: 100%;
	margin-bottom: 40px;

	@media (max-width: 575px) {
		margin-bottom: 32px;
	}
}

.title {
	@media (max-width: 575px) {
		text-align: center;
	}
}

.wrapper_title {
	max-width: 740px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	margin-bottom: 16px;

	> img {
		margin-right: 10px;

		@media (max-width: 575px) {
			width: 28px;
			height: 28px;
			margin-right: 6px;
		}
	}

	@media (max-width: 575px) {
		justify-content: center;
		align-items: center;
		margin-bottom: 12px;
	}
}

.subtitle_step1 {
	color: #8e9aa8;
	font-weight: 700;
	font-size: 30px;

	@media (max-width: 575px) {
		font-size: 24px;
	}
}

.subtitle_step2 {
	color: #28a879;
	font-weight: 700;
	font-size: 30px;
}

.descr {
	font-family: 'Inter', sans-serif;
	margin-top: 16px;
	color: #8e9aa8;
	font-weight: 400;
	font-size: 20px;

	@media (max-width: 575px) {
		text-align: center;
		margin-top: 12px;
	}
}

.descr_mobile {
	@media (max-width: 575px) {
		font-family: 'Inter', sans-serif;
		margin: 12px 0 32px;
		color: #8e9aa8;
		font-weight: 400;
		font-size: 16px;
	}
}

.done {
	width: 24px;
	height: 24px;
}

.list {
	max-width: 640px;
	width: 100%;
	padding: 0;
	list-style-type: none;
}

.brands {
	display: flex;
	margin-top: 72px;

	@media (max-width: 1050px) {
		flex-direction: column;
        margin-top: 38px;
		align-items: center;
	}

	@media (max-width: 575px) {
		position: relative;
		flex-direction: column;
		margin-top: 32px;
		z-index: 2;
	}
}

.brand {
	position: relative;
	padding: 12px 16px;
	background: rgba(212, 223, 243, 0.17);
	border-radius: 8px;
	margin-right: 24px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	z-index: 2;

	@media (max-width: 1050px) {
		width: 184px;
		margin-bottom: 20px;
	}

	> a {
		width: 100%;
		height: 100%;
		display: inline-block;
	}

	&:last-child {
		margin-right: 0;

		@media (max-width: 1050px) {
			margin-right: 24px;
		}

		@media (max-width: 575px) {
			margin-right: 0;
		}
	}
	

	&:hover {
		background: #f1f4f9;

		@media (max-width: 575px) {
			background: rgba(212, 223, 243, 0.17);
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 4px;
		margin-right: 0;
		text-align: center;
	}
}

.btn,
.btnTransparent {
	font-family: inherit;
	min-height: 48px;
	padding: 0 51px;

	font-weight: 500;
	font-size: 18px;
	background: #002cfb;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	transition: all 0.2s ease-in;

	&:hover {
		background: #0024cb;
	}
}

.vc {
	max-width: 722px;
	width: 100%;
	background: rgba(212, 223, 243, 0.17);
	border-radius: 8px;
	margin-top: 72px;
	padding: 28px 32px;

	@media (max-width: 1620px) {
		max-width: 536px;
	}

	@media (max-width: 1440px) {
		max-width: 436px;
		margin-top: 48px;
	}

	@media (max-width: 1200px) {
		max-width: 510px;
		margin-top: 48px;
	}

	@media (max-width: 1100px) {
		max-width: 410px;
		margin-top: 48px;
	}

	@media (max-width: 1050px) {
		margin-top: 38px;
		max-width: 100%;
	}

	> p {
		max-width: 607px;
		width: 100%;
		margin-top: 11px;
		margin-bottom: 27px;
		color: #2e3c4e;
		font-weight: 600;
		font-size: 20px;
		line-height: 23px;

		@media (max-width: 1200px) {
			font-size: 16px;
		}
	}

	@media(max-width: 575px) {
		margin-top: 32px;
	}
}

.btn {
	display: inline-block;
	line-height: 48px;
	max-width: 329px;
	width: 100%;
	padding: 0;
	text-align: center;
	
	@media (max-width: 575px) {
		width: 100%;
		max-width: 350px;
		position: fixed;
		text-align: center;
		bottom: 8px;
		left: 50%;
		margin: 0;
		padding: 0;
		transform: translateX(-50%);
		z-index: 30;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.btnTransparent {
	position: relative;
	z-index: 2;
	min-height: initial;
	display: inline-block;
	background-color: transparent;
	color: #002cfb;
	border: 1px solid #4b6bff;
	padding: 12.5px 86px;
	margin-top: 252px;

	&:hover {
		border: 1px solid #0024cb;
		background-color: transparent;
		color: #0024cb;
	}

	@media (max-width: 575px) {
		width: 100%;
		max-width: 350px;
		line-height: 48px;

		position: fixed;
		bottom: 8px;
		left: 50%;

		padding: 0;
		margin: 0;
		font-size: 18px;
		width: 100%;
		text-align: center;
		transform: translateX(-50%);

		border: 1px solid #3357ff;
		background: #fff;

		&:hover {
			border: 1px solid #3357ff;
			background: #fff;
		}
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.illustation {
	position: absolute;
	bottom: 0;
	right: 0;

	@media (max-width: 1765px) {
      height: 42vw;
	}

	@media (max-width: 1440px) {
		height: 52vw;
		margin-right: -60px;
	}

	@media (max-width: 575px) {
		width: 110%;
		height: auto;
		display: block;
		position: sticky;
		margin-top: -40px;
		margin-left: -20px;

		&.illustation_success {
			margin-top: 32px;
		}
	}
}

.qr {
	position: absolute;
	right: 40px;
	bottom: 40px;
	width: 144px;
	height: 144px;
	background: #f1f4f9;
	border-radius: 8px;
	padding: 14px;

	@media (max-width: 1050px) {
		position: static;
		margin-left: 35%;
		margin-top: 20px;
	}
}

.wrapper_mobile {
	@media (max-width: 575px) {
		> span {
			font-family: 'Inter', sans-serif;
			display: inline-block;
			margin-bottom: 6px;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			margin-left: 12px;
			color: #71869d;
		}

		> p {
			font-family: 'Inter', sans-serif;
			color: #71869d;
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
			margin-left: -24px;
		}
	}
}
