.header {
	position: absolute;
	top: -96px;
	height: 80px;
	max-width: 1440px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
	border-radius: 8px;
	overflow: hidden;
	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 1800px) {
			object-fit: contain;
		}
	}

	@media (max-width: 575px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.header_btn {
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);

	@media (max-width: 1600px) {
		right: 440px;
	}
}

.access {
	height: 100%;
	position: relative;

	@media (max-width: 575px) {
		height: inherit;
		margin-bottom: 40px;
	}
}

.success,
.page_done,
.waiting {
	padding: 40px;

	@media (max-width: 575px) {
		padding: 24px 8px 0;
		margin-bottom: -8px;
	}
}

.wrapper_heading {
	display: flex;
	align-items: flex-start;

	> h5 {
		font-weight: 700;
		font-size: 30px;
		line-height: 36px;
		margin-bottom: 10px;
		color: #8e9aa8;

		@media (max-width: 575px) {
			margin-bottom: 0;
			font-size: 24px;
		}
	}

	> img {
		margin-right: 10px;
	}

	&.heading_done {
		> h5 {
			color: #28a879;
		}
	}

	@media (max-width: 575px) {
		justify-content: center;
		margin-bottom: 8px;
	}
}

.heading {
	position: relative;
	z-index: 1;
	max-width: 800px;
	width: 100%;

	> h2 {
		font-weight: 800;
		font-size: 38px;
		margin-bottom: 32px;
		color: rgba(31, 51, 69, 0.9);

		@media (max-width: 575px) {
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 20px;
		}
	}

	> p {
		font-family: 'Inter', sans-serif;
		font-weight: 400;
		font-size: 20px;
		line-height: 26px;
		color: #8e9aa8;

		@media (max-width: 770px) {
			font-size: 18px;
		}
	}

	&.heading_waiting {
		margin-bottom: 624px;

		@media (max-width: 770px) {
			margin-bottom: 0;
		}
	}

	@media (max-width: 575px) {
		text-align: center;
	}
}

.illustration {
	position: absolute;
	bottom: 0;
	right: 0;
	height: 800px;

	&.illustration_waiting {
		@media (max-width: 770px) {
			position: static;
		}
	}

	&.illustration_done {
		width: 100%;
		@media (max-width: 770px) {
			width: 120%;
			margin-left: -26px;
			margin-top: 0;
		}
	}

	@media (max-width: 1230px) {
		height: 650px;
	}

	@media (max-width: 770px) {
		width: 100%;
		height: auto;
		position: static;
		display: block;
		width: 100%;
		margin-top: 23px;
		margin-bottom: -40px;
	}
}

.list {
	max-width: 480px;
	width: 100%;
	padding: 16px 16px 18px;
	margin: 40px 0 36px;

	background: rgba(212, 223, 243, 0.17);
	border-radius: 8px;

	> h4 {
		font-family: 'Inter', sans-serif;
		font-weight: 600;
		font-size: 20px;
		line-height: 26px;
		color: #121922;
		margin-bottom: 24px;

		@media (max-width: 575px) {
			font-size: 18px;
			color: #000729;
		}
	}

	@media (max-width: 575px) {
		margin: 32px 0 32px;
	}
}

.footer_block {
	position: relative;
	z-index: 1;
	max-width: 611px;
	width: 100%;

	> P {
		margin-bottom: 20px;
		color: #121922;
		font-family: 'Inter';
		font-weight: 400;
		font-size: 18px;
		line-height: 26px;

		&:last-of-type {
			margin-bottom: 0;
		}

		@media (max-width: 575px) {
			font-size: 16px;
			line-height: 18px;
		}
	}
}

.footer_text {
	font-family: 'Inter', sans-serif;
	font-size: 18px;
	line-height: 26px;
	color: #71869d;
	margin-top: 260px;

	@media (max-width: 575px) {
		margin-top: 0;
	}
}
