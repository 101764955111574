.chart {
	padding: 40px 20px;
	background-color: #fff;
	border-radius: 8px;
	margin-bottom: 16px;
}

.title {
	max-width: 600px;
	width: 100%;
	color: rgba(31, 51, 69, 0.9);
	font-weight: 800;
	font-size: 38px;
	margin-bottom: 16px;

	@media (max-width: 575px) {
		max-width: 358px;
		width: 100%;
		margin-bottom: 12px;
		font-weight: 800;
		font-size: 24px;
		margin-left: 16px;
	}
}

.descr {
	max-width: 631px;
	width: 100%;
	font-family: 'Inter', sans-serif;
	color: #8e9aa8;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;

	@media (max-width: 575px) {
		font-size: 16px;
		line-height: 20px;
		margin-left: 16px;
		max-width: 358px;
		width: 100%;
	}
}

.container {
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	margin-right: -200px;
	
	@media (max-width: 1400px) {
		margin-right: -400px;
	}

	@media (max-width: 1100px) {
		margin-right: -600px;
	}

	@media (max-width: 1100px) {
		margin-right: -700px;
	}

	@media (max-width: 770px) {
		margin-bottom: 40px;
	}

	@media (max-width: 590px) {
		justify-content: center;
	}
}
/////
.chart__chart {
	margin-top: 42px;
	> svg {
		
	}

	& tspan {
		fill: #8e9aa8;
		font-weight: 500;
		font-size: 14px;
		line-height: 110%;
	}

	& .recharts-cartesian-grid {
		opacity: 0.3;
	}

	& .recharts-cartesian-axis-line {
		stroke: #fff;
	}
}

.chart__loader {
	width: min-content;
	margin: auto;

	@media (max-width: 768px) {
		width: 100%;
	}
}

.chart__info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;

	& .chart__link {
		margin-top: 0;
		padding: 6px 12px;
	}
}

.chart-legend {
	display: flex;
	padding: 9.5px 16px;
	border-radius: 4px;
	background-color: #303550;
	color: #fff;
	gap: 20px;
	height: 100%;

	& span {
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: 120%;

		&::before {
			content: '';
			display: block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			margin-right: 8px;
		}
	}
}

.chart_tooltip {
	border-radius: 4px;
	background-color: #303550;
	padding: 4px 8px 8px 8px;
	color: #fff;
}

.chart_tooltip__title {
	font-size: 18px;
	line-height: 120%;
	font-weight: 600;
	margin-bottom: 5px;
}

.chart_tooltip__revenue,
.chart_tooltip__orders {
	font-size: 16px;
	line-height: 120%;
}

.chart-legend__revenue::before {
	background-color: #00cc6e;
}

.chart-legend__orders::before {
	background-color: #ffd500;
}

.chart__link {
	margin-top: 30px;
	display: flex;
	align-items: center;
	padding: 10px 12px;
	background-color: #303550;
	border-radius: 4px;
	width: max-content;
	margin-left: auto;
	gap: 10px;
	font-size: 18px;
	line-height: 120%;
	color: #abadba;
	text-decoration: none;
	transition: color 0.3s ease;

	&:hover {
		color: #fff;

		& span {
			background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.18939 2.60785L1.52643 2.60785L1.52643 0.607852L9.60346 0.607852L9.60346 8.68488H7.60346V4.02221L1.5265 10.0992L0.112288 8.68495L6.18939 2.60785Z' fill='white'/%3e%3c/svg%3e ");
		}
	}

	& span {
		margin-left: 5px;
	}
}

@media (max-width: 768px) {
	.chart {
		&:first-child {
			padding: 96px 0 16px 0;
		}
		padding: 16px 0 16px 0;
		margin-bottom: 32px;
	}

	.chart__chart {
		margin-top: 42px;

		& .recharts-cartesian-grid {
			opacity: 0.3;
		}
	}

	.chart__info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 30px;

		& .chart__link {
			margin-top: 0;
			padding: 6px 12px;
		}
	}

	.chart-legend {
		display: flex;
		padding: 9.5px 16px;
		border-radius: 4px;
		background-color: #303550;
		color: #fff;
		gap: 20px;
		height: 100%;

		& span {
			display: flex;
			align-items: center;
			font-size: 16px;
			line-height: 120%;

			&::before {
				content: '';
				display: block;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				margin-right: 8px;
			}
		}
	}

	.chart-tooltip {
		border-radius: 4px;
		background-color: #303550;
		padding: 4px 8px 8px 8px;
	}

	.chart-tooltip__title {
		font-size: 18px;
		line-height: 120%;
		font-weight: 600;
		margin-bottom: 5px;
	}

	.chart-tooltip__revenue,
	.chart-tooltip__orders {
		font-size: 16px;
		line-height: 120%;
	}

	.chart-legend__revenue::before {
		background-color: #00cc6e;
	}

	.chart-legend__orders::before {
		background-color: #ffd500;
	}

	.chart__link {
		margin-top: 30px;
		display: flex;
		align-items: center;
		padding: 10px 12px;
		background-color: #303550;
		border-radius: 4px;
		width: max-content;
		margin-left: auto;
		gap: 10px;
		font-size: 18px;
		line-height: 120%;
		color: #abadba;
		text-decoration: none;
		transition: color 0.3s ease;

		&:hover {
			color: #fff;

			& span {
				background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.18939 2.60785L1.52643 2.60785L1.52643 0.607852L9.60346 0.607852L9.60346 8.68488H7.60346V4.02221L1.5265 10.0992L0.112288 8.68495L6.18939 2.60785Z' fill='white'/%3e%3c/svg%3e ");
			}
		}

		& span {
			margin-left: 5px;
		}
	}
}


