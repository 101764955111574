.scheduleItem {
	position: relative;
	max-width: 196px;
	min-height: 126px;
	width: 100%;
	padding: 16px;
	background: #fff;
	color: #adbfdf;
	border: 1px solid #ecf2fc;
	border-radius: 4px;
	font-family: 'Inter';
	font-weight: 400;
	font-size: 18px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 4px;
		height: 100%;
	}

	@media (max-width: 1800px) {
		font-size: 16px;
		padding: 9px;
	}

	@media (max-width: 1700px) {
		font-size: 14px;
		padding: 10px;
	}

	@media (max-width: 1460px) {
		font-size: 12px;
	}

	@media (max-width: 1200px) {
		font-size: 10px;
	}

	@media (max-width: 770px) {
		font-size: 14px;
		padding: 16px;
	}
}

.cat_1 {
	background: #dbf8ef;
	color: #009953;

	&::before {
		background: #009953;
	}
}

.cat_2 {
	background: rgba(0, 0, 0, 0.1);
	color: rgba(31, 51, 69, 0.9);

	&::before {
		background: #0f1324;
	}
}

.cat_3 {
	background: rgba(255, 213, 0, 0.15);
	color: #f89500;

	&::before {
		background: #f89500;
	}
}

.cat_4 {
	background: #fee9ef;
	color: #cc0034;
	&::before {
		background: #ff0041;
	}
}

.cat_5 {
	background: #f6ebff;
	color: #8800ff;

	&::before {
		background: #a033ff;
	}
}

.cat_6 {
	background: rgba(212, 223, 243, 0.17);
	color: rgba(173, 191, 223, 0.4);

	&::before {
		background: rgba(173, 191, 223, 0.4);
	}
}

.top {
	font-family: 'Inter', sans-serif;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	font-weight: 500;

	@media (max-width: 1650px) {
		font-size: 14px;
		flex-direction: column;
		align-items: flex-start;
	}

	@media (max-width: 1200px) {
		font-size: 10px;
	}

	@media (max-width: 1350px) {
		flex-direction: column;
		align-items: flex-start;
	}

	@media (max-width: 770px) {
		font-size: 14px;
		flex-direction:row;
		justify-content: space-between;
	}
}

.date {
	font-weight: 400;
	font-size: 18px;

	@media (max-width: 1800px) {
		font-size: 16px;
	}

	@media (max-width: 1700px) {
		font-size: 14px;
	}

	@media (max-width: 1460px) {
		font-size: 14px;
	}

	@media (max-width: 1200px) {
		font-size: 10px;
	}

	@media (max-width: 770px) {
		margin-right: 10px;
		font-size: 22px;
	}
}

.date_event {
	font-size: 14px;
	margin-right: 20px;

	@media (max-width: 770px) {
		margin-right: 0;
	}
}

.descr {
	margin-top: 20px;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	justify-self: flex-end;

	@media (max-width: 1700px) {
		font-size: 14px;
	}

	@media (max-width: 1280px) {
		font-size: 12px;
	}

	@media (max-width: 1200px) {
		font-size: 10px;
	}

	@media (max-width: 770px) {
		font-size: 16px;
	}
}
