.container {
	max-width: 1400px;
	width: 100%;
	margin: 0 auto;
	border-radius: 8px;
}

.waiting {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}

.wrapper_title {
	display: flex;
	align-items: center;
	justify-content: center;
}

.subtitle_done {
	font-size: 28px;
	color: #28a879;
	margin-left: 10px;

	@media (max-width: 575px) {
		margin-left: 0;
		text-align: center;
	}
}

.return {
	position: fixed;
	top: 100px;
	left: 100px;
	color: #66717f;
	font-weight: 600;
	font-size: 20px;
	cursor: pointer;

	@media (max-width: 575px) {
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		text-align: center;
		line-height: 120%;
	}
}
