.nextEvents {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 32px 24px;

	@media (max-width: 575px) {
		grid-template-columns: repeat(1, 1fr);
		justify-items: center;
		margin-bottom: 40px;
	}
}
