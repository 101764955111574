.container {
	max-width: 1400px;
	padding-bottom: 70px;
	width: 100%;
	margin: 0 auto;

	@media (max-width: 575px) {
		max-width: 350px;
		padding-bottom: 0;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.inner {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	@media (max-width: 575px) {
		flex-direction: column;
	}
}

.top {
	margin-bottom: 73px;
	padding-top: 180px;

	@media (max-width: 575px) {
		padding-top: 115px;
		margin-bottom: 60px;
	}
}
