#bookPaymentButton2,
.second_btn {
	background: #a2a9b4;
	position: relative;
	z-index: 10;
	min-width: 270px;
	font-family: inherit;
	font-weight: 500;
	font-size: 18px;
	line-height: 48px;
	padding-right: 14px;
	border-radius: 4px;
	transition: all 0.2s ease-in-out;

	font-weight: 600;
	font-size: 18px;
	cursor: pointer;

	&::after {
		content: url('../../.././../../public/static/icons/IconForward-white.svg');
		position: absolute;
		right: 63.5px;
		top: 6px;
		width: 24px;
		height: 24px;
	}

	@media(max-width: 575px) {
		width: 350px;
	}

	@media(max-width: 320px) {
		width: 304px
	}
}

.btn_blue {
	background: #002cfb;
	color: #fff;

	&:hover {
		background: #0024cb;
	}
}

.btn_green {
	background: #00ff88;
	color: #000729;

	&::after {
		content: url('../../.././../../public/static/icons/IconForward.svg') !important;
	}

	&:hover {
		background: #00cc6e;
	}
}

.btn_purple {
	background: #8800ff;
	color: #ffffff;

	&:hover {
		background: #621f9d;
	}
}

.second_btn {
	&::after {
		right: 25.5px !important;
	}
}
