.failed {
	padding: 120px 120px 0;

	@media (max-width: 575px) {
		padding: 24px 8px 0;
	}
}

.btn {
	opacity: 0;
	visibility: hidden;
	margin-bottom: 368px;
}

.wrapper_title {
	max-width: 655px;
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	> img {
		margin-right: 10px;
	}

	@media (max-width: 575px) {
		justify-content: center;
	}
}

.title {
	margin-bottom: 32px;
	max-width: 461px;
	width: 100%;

	@media (max-width: 575px) {
		text-align: center;
	}
}

.subtitle {
	color: #e10d0d;
	font-weight: 700;
	font-size: 30px;
}

.descr {
	max-width: 605px;
	width: 100%;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	color: #8e9aa8;
	margin-bottom: 484px;

	@media (max-width: 575px) {
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}
}

.illustation {
	position: absolute;
	bottom: 0;
	right: 0;

	@media (max-width: 1600px) {
		display: none;
	}

	@media (max-width: 575px) {
		width: 100%;
		position: static;
		display: block;
		margin-bottom: -32px;
	}
}
