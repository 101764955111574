@import '../../../../../styles/vendor/variables.scss';

.schedule {
	background: #ffffff;
	padding: 10px;
	border-radius: 8px;
	margin-bottom: 68px;
	
	width: calc(100%);

	@media (max-width: 1280px) {
	    margin-left: -40px;
	    margin-right: -40px;
	    width: calc(100% + 80px);
	}

	@media (max-width: 770px) {
		margin-bottom: 50px;
		margin: 0;
		width: 100%;
	}
}

.days {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 8px;
	width: 100%;

	color: #8e9aa8;
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 8px;

	@media (max-width: 1700px) {
		font-size: 14px;
	}

	@media (max-width: 1460px) {
		font-size: 12px;
	}

	@media (max-width: 1080px) {
		display: none;
	}
}

.day {
	min-height: 35px;
	line-height: 45px;
	padding: 0 0 0 5px;

	@media (max-width: 1700px) {
		line-height: 40px;
	}

	@media (max-width: 1460px) {
		line-height: 35px;
	}
}

.schedule_container {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 8px;
	width: 100%;

	@media (max-width: 1080px) {
		grid-template-columns: repeat(5, 1fr);
	}

	@media (max-width: 860px) {
		grid-template-columns: repeat(3, 1fr);
		justify-items: center;
	}

	@media (max-width: 665px) {
		grid-template-columns: repeat(2, 1fr);
		
	}

	@media (max-width: 320px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.footer {
	display: grid;
	grid-template-columns: repeat(4, max-content);
	grid-template-rows: 20px;
	padding: 14px;
	margin-top: 20px;
	gap: 10px 40px;

	@media (max-width: 1300px) {
		grid-template-columns: repeat(3, max-content);
	     grid-template-rows: 16px;
	}

	@media (max-width: 1080px) {
		grid-template-columns: repeat(2, max-content);
	     grid-template-rows: 14px;
	}

	@media (max-width: 860px) {
		grid-template-columns: repeat(1, max-content);
	     grid-template-rows: 14px;
	}

	> span {
		position: relative;
		font-family: 'Inter', sans-serif;
		font-weight: 400;
		font-size: 14px;
		line-height: 140%;
		color: #71869d;
		padding-left: 26px;

		@media (max-width: 1700px) {
			font-size: 12px;
		}

		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			left: 0;
			top: 50%;
			border-radius: 4px;
			width: 18px;
			height: 18px;
			background: #009953;
			transform: translateY(-50%);
		}

		@media (max-width: 575px) {
			margin-bottom: 15px;
		}
	}

	.green {
		&::before {
			background: #009953;
		}
	}

	.darkGray {
		&::before {
			background: #0f1324;
		}
	}

	.yellow {
		&::before {
			background: #f89500;
		}
	}

	.lightGray {
		&::before {
			background: rgba(173, 191, 223, 0.4);
		}
	}

	.red {
		&::before {
			background: #ff0041;
		}
	}

	.purple {
		&::before {
			background: #8800ff;
		}
	}

	@media (max-width: 575px) {
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: none;
	}
}
