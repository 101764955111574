.done {
	padding: 40px 40px -20px 40px;

	@media (max-width: 900px) {
		padding: 24px 0px;
		
	}
}

.wrapper_title {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	color: #8e9aa8;
	font-weight: 700;
	font-size: 30px;

	@media (max-width: 900px) {
		
		margin-bottom: 8px;
	}
}

.subtitle {
	margin-left: 10px;

	@media (max-width: 575px) {
		margin-left: 6px;
	}
}

.title {
	margin-bottom: 32px;

	@media (max-width: 575px) {
		margin-bottom: 20px;
		min-height: 28px;
	}
}

.descr {
	max-width: 432px;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	font-size: 20px;
	color: #8e9aa8;
	margin-bottom: 596px;

	@media (max-width: 900px) {
		margin-bottom: 30px !important;
	}
}

.descr_approval {
	max-width: 432px;
	min-height: 76px;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	font-size: 20px;
	color: #8e9aa8;
	margin-bottom: 64px;
	@media (max-width: 1200px) {
		margin-bottom: 12px;
		margin-right: 0;
	}

	@media (max-width: 1050px) {
		font-size: 18px;
	}
}

.illustration__rejected {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 600px;

	@media (max-width: 1800px) {
      width: 35.8vw;
	  height: 40vw;
	  right: -40px;
	}

	@media (max-width: 1740px) {
		display: none;
	}

	@media (max-width: 770px) {
		display: block;
		position: static;
		width: 100%;
		height: auto;
		margin-bottom: -48px;
	}

}

.illustation__approval {
	
	height: 400px;
	position: absolute;
	bottom: 0;
	right: 0;

	@media (max-width: 1860px) {
		display: none;
	}

	@media (max-width: 770px) {
		position: static;
		display: block;
		width: 120%;
		height: auto;
		margin-left: -25px;
	}
}

.subtitle__approval {
	color: #28a879;
}

.subtitle__notApproval {
	color: #e10d0d;
}


.window {
	max-width: 35vw;
	height: 531px;
	overflow-y: scroll;

	@media (max-width: 1910px) {
		max-width: 685px;
	  }
}

.window::-webkit-scrollbar {
	width: 6px;
}
  
.window::-webkit-scrollbar:disabled {
    width: 0;
}
  
.window::-webkit-scrollbar-track {
	background-color: #C4CEFF;
    border-radius: 10px;
}
  
.window::-webkit-scrollbar-thumb {
	background-color: #002CFB;
    border-radius: 10px;
}

.incorrect {
	margin-bottom: 40px;
	h4 {
		font-weight: 600;
		font-size: 20px;
		line-height: 26px;
		color: #121922;
		margin:24px 0 16px;		
	}
}

.answer {
	font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #E55656;
	margin: 0 0 5px;

	&_correct {
		color:#28a879bd;
		margin-top: 10px;
	}
}

.text {
	display: flex;
	font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #121922;
	align-items: center;

	&_correct {
		color:#28a879;
	}

}

.point {
	display: flex;
	flex-shrink: 0;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 1px solid #ADBFDF;
	margin-right: 14px;
}