@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Raleway:wght@400;500;600;700;800&display=swap');

@import '../../styles/vendor/variables.scss';

.lk {
	display: flex;
	font-family: 'Raleway', sans-serif;
}

.main {
	position: relative;
	color: #121922;
	background: #ffffff;
	border-radius: 8px;
	margin: 48px 120px 48px /* 360px */ calc($width-lkSidebar + 120px);
	width: 100%;



	@media (max-width: 1800px) {
		margin: 50px 50px 48px /* 360px */ calc($width-lkSidebar + 50px);
	}

	@media (max-width: 1280px) {
		margin: 48px 60px 48px /* 360px */ calc($width-lkSidebar + 60px);
	}

	@media (max-width: 770px) {
		margin: 0 0 0 0;
		min-height: 100vh;
		padding: 70px 16px 100px;
		line-height: 120%;
	}
}


.main_contract {
	margin: 48px 120px 48px /* 360px */ calc($width-lkSidebar + 120px);

	@media (max-width: 770px) {
		margin: 0 0 0 0;
	}
}

.main_questionary {
	padding-bottom: 0;
	margin-bottom: 48px;
}

.main_exam {
	padding-bottom: 0;
	margin-bottom: 48px;
}

.main_contractVip,
.main_calendar {
	margin-top: 120px;

	@media (max-width: 770px) {
		margin: 0 0 0 0;
	}
}

.main_calendar {
	background-color: transparent;

	@media (max-width: 770px) {
		padding: 70px 16px 8px;
	}
}

.main_access,
.main_zoom,
.main_material,
.main_payment,
.contract {
	@media (max-width: 770px) {
		padding: 70px 16px 8px;
	}
}

.main_book {
	margin: 48px 50px 40px calc($width-lkSidebar + 50px);
	@media (max-width: 770px) {
		padding: 70px 16px 150px;
		margin: 0;
	}
}

.main_noBG {
	background-color: transparent;

	@media (max-width: 770px) {
		padding: 0;
	}
}

.main_overlay {
	background: rgba(42, 53, 63, 0.6);
}

.main_forBaner {
	margin: 144px 120px 360px calc($width-lkSidebar + 120px);

	@media (max-width: 1500px) {
		margin: 144px 40px 360px calc($width-lkSidebar + 40px);
	}

	@media (max-width: 1280px) {
		margin: 144px 60px 360px calc($width-lkSidebar + 60px);
	}
}

.top_mobile {
	width: 100%;
	min-height: 48px;
	padding-top: 17px;
	background: #091336;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	> div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px 16px 8px 20px;
	}
}

.burger {
	width: 35px;
	height: 35px;

	> img {
		width: 100%;
		height: 100%;
	}
}

.banner {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	position: absolute;
	bottom: -283px;
	left: 0;
	background: #fff;
	border-radius: 8px;
	z-index: 10;
	cursor: pointer;

	@media (max-width: 1600px) {
		flex-direction: column;
		padding: 16px 8px 12px;
		bottom: -210px;
	}

	@media (max-width: 1092px) {
		bottom: -236px;
	}

	@media (max-width: 770px) {
		align-items: center;
		padding: 0;
		position: static;
		text-align: center;
		background: rgba(212, 223, 243, 0.17);
		margin-bottom: 100px;
		margin-top: 32px;
		bottom: -280px;
	}
}

.bannerHide {
	display: none;
	visibility: hidden;
}

.bannerNoMargin {
	@media (max-width: 770px) {
		margin-bottom: 0;
	}
}

.banner_right {
	display: flex;
	align-items: flex-start;

	> img {
		margin-right: 15px;

		@media (max-width: 1600px) {
			width: 150px;
		}
	}

	@media (max-width: 1600px) {
		align-items: center;
	}

	@media (max-width: 770px) {
		flex-direction: column-reverse;
	}
}

.banner_content {
	padding-top: 44px;

	> h2 {
		margin-bottom: 15px;
		color: rgba(31, 51, 69, 0.9);
		font-weight: 800;
		font-size: 27px;

		@media (max-width: 1600px) {
			font-size: 18px;
		}

		@media (max-width: 915px) {
		  font-size: 16px;
		}

		@media (max-width: 770px) {
			font-size: 18px;
		  }
	}

	> p {
		margin-bottom: 25px;
		color: #8e9aa8;
		font-weight: 700;
		font-size: 20px;
		line-height: 26px;

		@media (max-width: 1600px) {
			font-size: 16px;
			margin-bottom: 7px;
		}

		@media (max-width: 915px) {
			font-size: 14px;
		  }

		  @media (max-width: 770px) {
			font-size: 16px;
		  }
	}

	@media (max-width: 1600px) {
		padding-top: 0;
	}

	@media (max-width: 770px) {
		padding-top: 24px;
		padding-bottom: 14px;
	}
}

.banner_litres {
	padding-top: 50px;
	padding-right: 50px;

	@media (max-width: 1600px) {
		padding-top: 15px;
	}
}

.banner_btn {
	position: relative;
	z-index: 10;
	display: flex;
	align-items: center;
	font-family: inherit;
	font-weight: 500;
	font-size: 18px;
	line-height: 48px;
	padding: 0 40px;
	color: #ffffff;
	border-radius: 4px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	background: #3357ff;

	> img {
		margin-left: 12px;
	}

	@media (max-width: 1600px) {
		margin-top: 10px;
		font-size: 16px;
		line-height: 44px;
	}

	@media (max-width: 915px) {
		
		font-size: 14px;
		line-height: 38px;
	}

	@media (max-width: 770px) {
		padding: 0;
		position: static;
		justify-content: center;
		max-width: 350px;
		width: 100%;
		margin: 0 auto;
		z-index: 30;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.banner_img {
	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 770px) {
			object-fit: cover;
		}
	}

	@media (max-width: 770px) {
		position: static;
		width: inherit;
	}
}
