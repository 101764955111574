.header {
	position: absolute;
	top: -96px;
	left: 0;
	height: 80px;
	max-width: 1440px;
	width: 100%;
    border-radius: 8px;
	overflow: hidden;
	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 1800px) {
			object-fit: contain;
		}
	}

	@media (max-width: 575px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.header_btn {
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);

	@media (max-width: 1600px) {
		right: 440px;
	}
}

.training {
	@media (max-width: 575px) {
		padding: 0 0 8px;
		background: #fff;
	}
}

.main_content {
	position: relative;

	padding: 40px;
	background: #ffffff;
	border-radius: 8px;
	@media (max-width: 575px) {
		position: static;
		padding: 24px 8px 0;
	}
}

.main_content_waiting {
	@media (max-width: 575px) {
		padding: 24px 0 0;
	}
}

.item {
	max-width: 600px;
	min-height: 76px;
	width: 100%;
	padding: 10px 16px 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	border: 1px solid transparent;
	border-radius: 8px;
	background: rgba(212, 223, 243, 0.17);

	margin-bottom: 12px;

	@media (max-width: 575px) {
		flex-direction: column;
		align-items: initial;
	}
}

.title {
	max-width: 600px;
	width: 100%;
	margin-bottom: 32px;
	line-height: 120%;
	color: rgba(31, 51, 69, 0.9);

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 20px;
	}
}

.subtitle,
.subtitle_waiting {
	color: #8e9aa8;
	font-weight: 800;
	font-size: 30px;
	margin-bottom: 10px;

	@media (max-width: 575px) {
		text-align: center;
		font-size: 30px;
		margin-bottom: 8px;
	}
}

.subtitle_waiting {
	margin-bottom: 0;
}

.descr {
	font-size: 20px;
	font-family: 'Inter', sans-serif;
	margin-bottom: 48px;
	color: #8e9aa8;

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 32px;
	}
}
.info_waiting {
	max-width: 630px;
	width: 100%;
	background: #f1f4f9;
	border-radius: 8px;
	padding: 16px 24px;
	color: #121922;
	font-weight: 600;
	font-size: 20px;
	line-height: 26px;

	@media (max-width: 575px) {
		margin-top: 32px;
	}
}

.wrapper_title {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	> img {
		margin-right: 10px;
	}

	@media (max-width: 575px) {
		justify-content: center;
		margin-bottom: 8px;
	}
}

.form {
	width: 100%;

	@media (max-width: 575px) {
		display: flex;
		flex-direction: column;
		gap: 12px 0;
	}
}

.place_picker {
	display: flex;
	align-items: center;
	margin-top: 82px;

	@media (max-width: 575px) {
		margin-top: 32px;
		flex-direction: column;
	}
}

.item_place {
	position: relative;

	@media (max-width: 575px) {
		width: 100%;
	}
}

.place_title {
	margin-right: 24px;
	color: #71869d;
	font-weight: 400;
	font-size: 18px;

	@media (max-width: 575px) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 12px;
	}
}

.arrow {
	width: 30px;
	height: 30px;
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

.consent_right {
	@media (max-width: 575px) {
		width: 100%;
	}
}

.input_place {
	font-weight: 500;
	font-size: 18px;
	background: #f1f4f9;
	color: #71869d;

	border: 1px solid #ecf2fc;
	border-radius: 4px;
	font-family: inherit;
	min-height: 48px;
	min-width: 240px;
	padding: 0 12px;
	transition: all 0.2s ease-in-out;

	&::placeholder {
		color: #71869d;
	}

	@media (max-width: 575px) {
		min-width: 100%;
		font-weight: 400;
		color: #121922;

		&::placeholder {
			color: #121922;
		}
	}
}

.place_active {
	color: #002cfb;
}

.radio {
	position: relative;
	z-index: 2;
	transition: all 0.2s ease-in;
	padding-left: 56px;
	cursor: pointer;

	> label {
		display: inline-block;
		max-width: 600px;
		font-weight: 600;
		font-size: 20px;
		color: #121922;
		transition: all 0.2s ease-in-out;
		cursor: pointer;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 16px;
			z-index: 1;
			transform: translateY(-50%);
			transition: all 0.2s linear;
			width: 20px;
			height: 20px;
			border: 2px solid #adbfdf;
			background-color: transparent;
			border-radius: 50%;
		}

		&:after {
			content: '';
			opacity: 0;
			position: absolute;
			display: block;
			background: #002cfb;
			width: 10px;
			height: 10px;
			top: 50%;
			left: 21px;
			z-index: 2;
			border-radius: 50%;
			transform: translateY(-50%);
		}
	}

	> input {
		appearance: none;
		position: absolute;

		&:checked + label::before {
			border: 2px solid #002cfb;
		}

		&:checked + label::after {
			opacity: 1;
		}

		&:hover + label {
			color: #2e3c4e;
		}
	}

	&:hover {
		color: #121922;

		label::before {
			border: 2px solid #002cfb;
		}
	}
}

.checkbox {
	position: relative;

	> label {
		vertical-align: sub;
		font-family: 'Inter', sans-serif;
		max-width: 670px;
		display: inline-block;
		font-weight: 400;
		font-size: 18px;
		color: #121922;
		padding-left: 34px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;

		> a {
			color: #121922;
			text-decoration-line: underline;
		}

		> span {
			max-width: 607px;
			display: inline-block;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 0;

			transform: translateY(-50%);
			transition: all 0.2s linear;
			width: 24px;
			height: 24px;
			border: 2px solid rgba(173, 191, 223, 0.4);
			border-radius: 6px;
			background-color: rgba(255, 255, 255, 0.56);

			@media (max-width: 575px) {
				top: 0;
				transform: initial;
			}
		}

		&:after {
			content: url('../../../../public/static/lk/checkboxDone.svg');
			opacity: 0;
			position: absolute;
			display: block;
			z-index: 2;
			top: 50%;
			left: 2px;
			color: #fff;
			transform: translateY(-50%);

			@media (max-width: 575px) {
				top: 4px;
				transform: initial;
			}
		}

		@media (max-width: 575px) {
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			margin-bottom: 32px;
		}
	}

	> input {
		appearance: none;
		position: absolute;

		&:checked + label::before {
			border: 2px solid #002cfb;
			background: #002cfb;
		}

		&:checked + label::after {
			opacity: 1;
		}

		&:not(input:checked):hover + label::before {
			background: rgba(0, 44, 251, 0.05);
			border: 2px solid #526ed3;
		}

		&:hover + label {
			color: #2e3c4e;
		}
	}

	@media (max-width: 575px) {
		text-align: initial;
	}
}

.details {
	max-width: 600px;
	width: 100%;
	margin-bottom: 48px;

	> h5 {
		font-family: 'Inter', sans-serif;
		color: #2e3c4e;
		font-weight: 400;
		font-size: 20px;
	}

	> ul {
		margin-top: 16px;
		padding: 0;
		list-style-type: none;
		color: #8e9aa8;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		> li {
			padding: 0;
		}
	}
}

.date {
	margin-top: 4px;
	color: #8e9aa8 !important;
	line-height: 26px;

	@media (max-width: 575px) {
		font-weight: 400;
	}
}

.place {
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	font-size: 16px;
	color: #71869d;

	@media (max-width: 575px) {
		max-width: 128px;
		line-height: 32px;
		padding: 0 14px;
		width: 100%;
		text-align: initial;
		margin-left: 56px;
		margin-top: 12px;
		background: #f1f4f9;
		border-radius: 50px;
	}
}

.menu {
	position: absolute;
	z-index: 10;
	width: 100%;
	max-width: 240px;
	max-height: 200px;
	margin: 0 auto;
	padding: 9px 0;

	font-size: 18px;
	font-weight: 400;
	text-align: left;
	transition: background-color 0.3s ease;
	list-style-type: none;
	background: #fff;
	border: 1px solid #ecf2fc;
	color: #121922;
	box-shadow: 0px 0px 10px rgba(0, 32, 51, 0.1);
	border-radius: 4px;
	overflow-y: auto;
	cursor: pointer;

	@media (max-width: 575px) {
		max-width: 100%;
		padding: 12px 12px 0;
	}
}

.input_error {
	border: 1px solid red;
	transition: all 0.2s ease-in-out;
}

.reservation {
	padding: 24px 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 16px;
	background: #ffffff;
	border-radius: 8px;

	@media (max-width: 575px) {
		padding: 0 8px;
		margin-top: 32px;
		flex-direction: column;
	}
}

.inputNumber {
	font-weight: 500;
	font-size: 18px;
	background: #f1f4f9;
	color: #71869d;
	border: 1px solid #ecf2fc;
	border-radius: 4px;
	font-family: inherit;
	min-height: 48px;
	min-width: 271px;
	padding: 0 12px;
	transition: all 0.2s ease-in-out;

	&::placeholder {
		color: #71869d;
	}

	@media (max-width: 575px) {
		min-width: 100%;
		color: #121922;
	}
}

.btn {
	width: 100%;
	margin-top: 16px;
	background: #002cfb;
	border-radius: 4px;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	min-height: 48px;
	padding: 0 40px;
	transition: all 0.2s ease-in;
	cursor: pointer;

	&:hover {
		background: #0024cb;
	}

	@media (max-width: 575px) {
		font-size: 15px;
		padding: 0;
		margin-top: 16px;
	}
}

.illustration {
	position: absolute;
	right: 0;
	bottom: 0;

	@media (max-width: 1600px) {
		height: 600px;
	}

	@media (max-width: 1360px) {
		height: 550px;
	}

	@media (max-width: 970px) {
		display: none;
	}


	@media (max-width: 575px) {
		display: block;
		position: static;
		margin-top: 40px;
		margin-bottom: -40px;
		width: 100%;
		height: auto;
	}
}
.error {
	color: #e10d0d;
	&:before {
		border: 2px solid #e10d0d !important;
	}
}

.cancel_btn {
	min-height: 48px;
	min-width: 228px;
	color: #002cfb;
	font-weight: 500;
	font-size: 18px;
	line-height: 48px;
	border: 1px solid #002cfb;
	border-radius: 4px;
	margin-top: 467px;
	cursor: pointer;

	transition: all 0.2s ease-in;

	&:hover {
		color: #0024cb;
		border: 1px solid #0024cb;
	}

	@media (max-width: 970px) {
       margin-top: 200px;
	}

	@media (max-width: 575px) {
		min-width: 350px;
		margin-top: 0;
		position: fixed;
		background: #fff;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
	}

	@media (max-width: 320px) {
		min-width: 304px;
	}
}

.cancel_btn_hide {
	opacity: 0;
	visibility: hidden;
}

.disabled {
	margin-top: 155px;

	> p {
		color: #71869d;
		font-family: 'Inter', sans-serif;
		font-weight: 400;
		font-size: 18px;
		margin-bottom: 21px;

		@media (max-width: 575px) {
			text-align: center;
		}
	}

	> button {
		font-family: inherit;
		font-weight: 500;
		font-size: 18px;
		min-height: 48px;
		padding: 0 95.5px;
		background: #f1f4f9;
		border-radius: 4px;

		@media (max-width: 575px) {
			padding: 0;
			max-width: 350px;
			width: 100%;
			z-index: 30;
		}

		@media (max-width: 320px) {
			max-width: 304px;
		}
	}

	@media (max-width: 575px) {
		margin-top: 100px;
	}
}
