@import './vendor/icons.scss';
@import './vendor/mixins.scss';
@import './vendor/fonts.scss';
@import './vendor/variables.scss';


html,
body {
	margin: 0;
	padding: 0;
	font-family: 'rawline', sans-serif;
	background-color: #000729;
	font-feature-settings: 'pnum' on, 'lnum' on;
	@media (max-width: 768px) {
		/* line-height: 0; */
	}
}

body {
	&.overlay {
		overflow: hidden;
	}
}

#root {
	overflow: hidden;
	-webkit-overflow: hidden;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;

	@media (max-width: 768px) {
		line-height: 120%;
	}
}

p {
	margin: 0;
	padding: 0;
}

ul {
	padding: 0 0 0 23px;
	margin: 0;

	@media (max-width: 768px) {
		line-height: 130%;
	}
}

ol {
	padding: 0 0 0 23px;
}

li {
	padding: 0 0 12px 12px;

	@media (max-width: 768px) {
		padding: 0 0 12px 0;
	}
}

li {
	&::marker {
		font-family: 'Bitter';
		font-size: 18px;
		font-weight: 700;
		color: #00cc6e;
		line-height: 130%;
	}
}

a {
	text-decoration: none;
}

button {
	background: transparent;
	border: none;
	padding: 0;
}

.img-lent {
	width: 100%;
	position: absolute;
	z-index: 2;

	@media (max-width: 768px) {
		width: auto;
		height: 50px;
	}
}
input:focus,
button:focus {
	outline: 0;
}

.title-header-h1 {
	font-size: 40px;
	font-weight: 800;
	line-height: 48px;
	margin: 0 0 16px !important;
	max-width: 806px;
	width: 100%;
	color: #fff;

	@media (max-width: 768px) {
		font-size: 36px;
		max-width: 343px;
	}
}

.title-header-h2 {
	font-size: 20px;
	font-weight: 400;
	margin: 0 0 36px !important;
	color: #fff;

	@media (max-width: 768px) {
		font-size: 18px;
	}
}

.title {
	font-size: 46px;
	font-weight: 800;
	color: #fff;
	margin-bottom: 16px;
	@media (max-width: 1600px) {
		line-height: 120%;
	}

	@media (max-width: 768px) {
		font-weight: 800;
		font-size: 26px;
		line-height: 120%;
	}
}

.title-span {
	display: inline !important;
	color: #00ff88;
}

.title-main {
	font-size: 46px;
	font-weight: 800;
	color: #fff;
	margin-bottom: 32px;
	line-height: 59px;
	span {
		color: #00ff88;
	}

	@media (max-width: 768px) {
		font-weight: 800;
		font-size: 26px;
		line-height: 120%;
		margin-bottom: 16px;
	}
}

.description-main {
	font-weight: 400;
	font-size: 22px;
	color: #e3e4e8;
	line-height: 130%;

	@media (max-width: 768px) {
		font-weight: 400;
		font-size: 16px;
		line-height: 130%;
	}
}

.title-green {
	color: #00ff88;
}

.description {
	max-width: 689px;
	font-size: 22px;
	color: #e3e4e8;
	line-height: 130%;

	@media (max-width: 768px) {
		font-size: 16px;
	}
}

.worker-span {
	color: #b866ff !important;
}


.container {
	margin: 0 auto;
	width: 1400px;
}

@media (min-width: 2000px) {
	.container {
		width: 1900px;
	}
}

@media (max-width: 2000px) {
	.container {
		width: 1400px;
	}
}

@media (max-width: 1600px) {
	.container {
		width: 1200px;
	}
}

@media (max-width: 1300px) {
	.container {
		width: 1000px;
	}
}

@media (max-width: 1100px) {
	.container {
		width: 900px;
	}
}

@media (max-width: 960px) {
	.container {
		flex-wrap: wrap;
		width: 550px;
	}
}


.container_smail {
	margin: 0 auto;
	width: 1900px;

	@media (max-width: 2000px) {
		width: 1400px;
	}


	@media (max-width: 1600px) {
		max-width: 100%;
	}
}

.lds-dual-ring {
	display: inline-block;
	width: 32px;
	height: 32px;
}

.lds-dual-ring.white::after {
	border: 3px solid #fff;
	border-color: #fff transparent #fff transparent;
}

.lds-dual-ring::after {
	content: ' ';
	display: block;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: 3px solid #3357ff;
	border-color: #3357ff transparent #3357ff transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

.maps {
	background-color: #000729;
}

.tabs__not-found {
	color: #fff;
	font-size: 18px;
}

.modal {
	position: fixed;
	z-index: 11;
	justify-content: center;
	align-items: center;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 7, 41, 0.4);

	&__img {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 50%;
			height: 100%;
			object-fit: contain;
			max-height: 680px;
			overflow: hidden;
			background-color: #000729;
		}
	}
}

.carousel__button {
	&.is-next,
	&.is-prev {
		background-color: #000729;
		border-radius: 100px;
		transition: all 0.2s linear;

		&:hover {
			background-color: #00cc6e;
		}
	}
}

.container {
	
	@media (max-width: 768px) {
		max-width: 343px;
		width: 100%;
		margin: 0 auto;
	}
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);

		@media (max-width: 768px) {
		}
	}
}

.list {
	margin-top: 48px;
	@media (max-width: 768px) {
		margin-top: 32px;
	}
}

.item-list {
	position: relative;
	padding: 0 0 0 48px;
	margin-bottom: 24px;
	font-weight: 400;
	font-size: 22px;
	line-height: 130%;
	p {
		@media (max-width: 1600px) {
			font-size: 16px;
		}
	}
	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		width: 32px;
		height: 32px;
		background: url('../../public/static/business/training/icons/check-mark.svg');

		@media (max-width: 1600px) {
			background: url('../../public/static/icons//check-mobile-lg.svg')
				no-repeat;
			width: 22px;
			height: 22px;
		}

		@media (max-width: 768px) {
			background: url('../../public/static/icons//check-mobile.svg') no-repeat;
		}
	}

	@media (max-width: 1600px) {
		padding: 0 0 0 29px;
	}

	@media (max-width: 768px) {
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		padding: 0 0 0 29px;
	}
}

.lk-title {
	color: rgba(31, 51, 69, 0.9);
	font-weight: 800;
	font-size: 38px;

	@media (max-width: 575px) {
		font-weight: 800;
		font-size: 24px;
		line-height: 28px;
	}
}

.bg-lk {
	background: #f1f4f9;
	min-height: 100vh;
}


