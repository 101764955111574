.buy {
	max-width: 685px;
	width: 100%;
	margin-left: 121px;

	@media (max-width: 575px) {
		margin-left: 0;
	}
}

.info {
	margin-bottom: 32px;

	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 24px;
	}
}

.title {
	margin-bottom: 8px;
	font-weight: 800;
	font-size: 38px;
	color: #00ff88;

	@media (max-width: 575px) {
		font-weight: 800;
		font-size: 24px;
		line-height: 120%;
		margin-bottom: 8px;
	}
}

.title_customer {
	color: #3357ff;
}

.title_business {
	color: #00ff88;
}

.title_worker {
	color: #b866ff;
}

.subtitle {
	font-weight: 500;
	font-size: 25px;
	line-height: 120%;
	margin-bottom: 16px;
	color: #ffffff;

	@media (max-width: 575px) {
		text-align: start;
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		margin-bottom: 12px;
	}
}

.name {
	font-weight: 500;
	font-size: 22px;
	line-height: 120%;
	color: #00ff88;

	@media (max-width: 575px) {
		font-weight: 400;
		font-size: 14px;
		text-align: start;
	}
}

.name_customer {
	color: #3357ff;
}

.name_business {
	color: #00ff88;
}

.name_worker {
	color: #b866ff;
}

.btns {
	max-width: 520px;
	width: 100%;
	margin-bottom: 30px;

	@media (max-width: 575px) {
		padding: 0;
		position: fixed;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 350px;
		width: 100%;
		margin-bottom: 0;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;

	@media (max-width: 575px) {
		flex-direction: column;
		justify-content: center;
		margin-bottom: 0;

		> button {
			margin-bottom: 16px;
		}
	}
}

.descr {
	margin-bottom: 32px;
	> p {
		color: rgba(255, 255, 255, 0.8);
		font-weight: 500;
		font-size: 20px;
		line-height: 120%;
		margin-bottom: 15px;

		&:last-of-type {
			margin-bottom: 0;
		}

		@media (max-width: 575px) {
			font-weight: 400;
			font-size: 18px;
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 24px;
	}
}

.logos {
	@media (max-width: 575px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.readAll,
.readSnippet {
	display: inline-block;
	position: relative;
	z-index: 10;
	font-family: inherit;
	font-weight: 500;
	font-size: 18px;
	line-height: 48px;
	padding: 0 40px;
	color: #ffffff;
	border-radius: 4px;
	background: #002cfb;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		background: #0024cb;
	}

	@media (max-width: 575px) {
		width: 100%;
		text-align: center;
	}
}

.readSnippet {
	min-width: 234px;

	@media (max-width: 575px) {
		margin-bottom: 16px;
	}
}
