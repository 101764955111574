.overlay {
	visibility: hidden;
	opacity: 0;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100vh;
	z-index: 100;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 0.2s ease-in-out;
	background: rgba(0, 0, 0, 0.4);

	@media (max-width: 575px) {
		overflow-y: auto;
		height: calc(var(--vh, 1vh) * 100);
	}
}

.overlay_active {
	visibility: visible;
	opacity: 1;
	z-index: 99;
}

.modal {
	position: relative;
	max-width: 1210px;
	width: 100%;
	padding: 22px 0 0 60px;
	background: radial-gradient(
			58.61% 182.04% at 93.24% 105.88%,
			rgba(255, 255, 255, 0.7) 0%,
			rgba(255, 255, 255, 0) 100%
		),
		#303550;

	border-radius: 8px;

	@media (max-width: 575px) {
		position: absolute;
		bottom: 0;
		max-width: 100%;
		max-height: 550px;
		flex-direction: column;
		padding: 0 16px 0;
		border-radius: 8px 8px 1px 0px;

		background: radial-gradient(
				98.27% 75.95% at 109.07% 63.47%,
				rgba(255, 255, 255, 0.5) 0%,
				rgba(255, 255, 255, 0) 100%
			),
			#303550;
		border-radius: 12px 12px 0px 0px;
	}
}

.modal_success {
	background: radial-gradient(
			46.9% 84.3% at 7.01% 108.17%,
			rgba(0, 255, 136, 0.5) 0%,
			rgba(255, 255, 255, 0) 100%
		),
		#006636;

	@media (max-width: 575px) {
		background: radial-gradient(
				84.67% 73.33% at 108.93% 92.96%,
				rgba(0, 255, 136, 0.5) 0%,
				rgba(255, 255, 255, 0) 100%
			),
			#006636;
		border-radius: 12px 12px 0px 0px;
	}
}

.modal_success_customer {
	background: radial-gradient(
				67.81% 115.19% at 2.41% 118.08%,
				#002cfb 0%,
				rgba(255, 255, 255, 0) 100%
			)
			/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
		#001a97;
	border-radius: 8px;

	@media (max-width: 575px) {
		background: radial-gradient(
					78.57% 78.57% at 93.66% 89.69%,
					#002cfb 0%,
					rgba(255, 255, 255, 0) 100%
				)
				/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
			#001a97;
		border-radius: 12px 12px 0px 0px;
	}
}

.modal_success_worker {
	background: radial-gradient(
				61.19% 118.87% at 0% 101.74%,
				#e3c4e4 0%,
				rgba(227, 196, 228, 0) 100%
			)
			/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
		linear-gradient(277.58deg, #2f0058 0.37%, #8800ff 101.18%);
	border-radius: 8px;

	@media (max-width: 575px) {
		background: radial-gradient(
					81.54% 63.79% at 102.62% 59.86%,
					#e3c4e4 0%,
					rgba(227, 196, 228, 0) 100%
				)
				/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
			linear-gradient(202.99deg, #2f0058 9.85%, #8800ff 97.66%);
		border-radius: 12px 12px 0px 0px;
	}
}

.modal_failure {
	background: radial-gradient(
			68.95% 82.61% at 97.8% 103.56%,
			rgba(255, 96, 96, 0.5) 0%,
			rgba(255, 255, 255, 0) 100%
		),
		#66001a;

	@media (max-width: 575px) {
		background: radial-gradient(
				87.73% 75.19% at 100% 96.16%,
				rgba(255, 96, 96, 0.47) 0%,
				rgba(255, 255, 255, 0) 100%
			),
			#66001a;
		border-radius: 12px 12px 0px 0px;
	}
}

.inner {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@media (max-width: 575px) {
		overflow-y: auto;
		overflow-x: hidden;
		flex-direction: column;
		align-items: center;
	}
}

.wrapper_title {
	display: flex;
	align-items: center;
	margin-bottom: 24px;

	> img {
		margin-right: 15px;

		@media (max-width: 575px) {
			width: 28px;
			height: 28px;
		}
	}

	@media (max-width: 575px) {
		align-items: center;
		padding-top: 24px;
		margin-bottom: 16px;
	}
}

.subtitle {
	color: #00ff88;
	font-weight: 700;
	font-size: 38px;
	line-height: 120%;

	@media (max-width: 575px) {
		font-size: 24px;
		text-align: start;
		margin-bottom: 8px;
	}
}

.subtitle_customer {
	color: #647fff;
}

.subtitle_worker {
	color: #b866ff;
}

.title {
	font-weight: 800;
	font-size: 38px;
	margin-bottom: 32px;
	@media (max-width: 575px) {
		font-size: 24px;
		margin-bottom: 26px;
	}
}

.title_success {
	color: #fff;
	font-weight: 700;
	font-size: 38px;

	@media (max-width: 575px) {
		font-size: 24px;
		font-weight: 800;
		text-align: start;
	}
}

.title_waiting {
	color: #121922;
	margin-bottom: 40px;
	font-weight: 700;
	font-size: 38px;

	@media (max-width: 575px) {
		font-size: 24px;
		margin-bottom: 26px;
		margin-top: 35px;
	}
}

.title_failure {
	color: #ffffff;
	font-weight: 700;
	font-size: 38px;
	@media (max-width: 575px) {
		font-weight: 700;
		font-size: 26px;
	}
}

.descr {
	color: #e3e4e8;
	font-size: 20px;
	margin-bottom: 40px;

	@media (max-width: 575px) {
		font-size: 18px;
		text-align: start;
		line-height: 130%;
		margin-bottom: 32px;
	}
}

.content {
	max-width: 570px;
	width: 100%;
	padding-top: 38px;

	@media (max-width: 575px) {
		text-align: center;
		padding-top: 16px;
	}
}

.price {
	margin-bottom: 40px;

	@media (max-width: 575px) {
		margin-bottom: 160px;
	}
}

.price_new {
	color: #2e3c4e;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	font-size: 36px;
	margin-right: 8px;

	@media (max-width: 575px) {
		font-size: 24px;
	}
}

.price_old {
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	font-size: 18px;
	text-decoration-line: line-through;
	color: #8e9aa8;

	@media (max-width: 575px) {
		font-size: 16px;
	}
}

.formApi {
	> form {
		> button {
			background: #a2a9b4;
			cursor: pointer;
			position: relative;
			min-height: 48px;
			padding-right: 20px;
			z-index: 10;
			font-family: inherit;
			font-weight: 500;
			font-size: 18px;
			min-width: 278px;
			color: #ffffff;

			border-radius: 0px 4px 4px 0px;
			transition: all 0.2s ease-in-out;

			&::after {
				content: url('../../../../../public/static/lk/IconForward-small.svg');
				position: absolute;
				right: 42px;
				top: 50%;
				width: 16px;
				height: 16px;
				transform: translateY(-50%);

				@media (max-width: 575px) {
					right: 55px;
				}
			}

			@media (max-width: 575px) {
				padding: 0;

				justify-content: center;
				max-width: 350px;
				width: 100%;
				margin: 0 auto;
				z-index: 30;
			}

			@media (max-width: 320px) {
				max-width: 304px;
			}
		}
	}
}

.btn_success,
.btn_failure {
	display: inline-block;
	position: relative;
	min-height: 48px;
	line-height: 48px;
	z-index: 10;
	font-family: inherit;
	font-weight: 600;
	font-size: 18px;
	max-width: 313px;
	width: 100%;
	text-align: center;
	border-radius: 4px;
	color: #ffffff;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	@media (max-width: 575px) {
		padding: 0;
		position: fixed;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 350px;
		width: 100%;
		z-index: 30;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.btn_success {
	background: rgba(255, 255, 255, 0.05);
	border: 1px solid rgba(255, 255, 255, 0.4);
	&:hover {
		background: rgba(255, 255, 255, 0.35);
	}
}

.btn_failure {
	background: rgba(255, 255, 255, 0.05);
	border: 1px solid rgba(255, 255, 255, 0.4);
	border-radius: 4px;

	&:hover {
		background: rgba(255, 255, 255, 0.01);
	}
}

.input {
	> input {
		background: #f1f4f9;
		border-radius: 4px 0px 0px 4px;
		min-height: 48px;
		min-width: 251px;
		border: none;
		padding: 10.5px 13px;
		width: 100%;
		font-size: 18px;
		font-family: inherit;
		line-height: 145%;

		&::placeholder {
			color: #8e9aa8;
			font-weight: 400;
			font-size: 18px;
		}
	}
}

.input {
	@media (max-width: 575px) {
		margin-bottom: 12px;
	}
}

.wrapper {
	display: flex;

	@media (max-width: 575px) {
		flex-direction: column;
		padding: 0;
		position: fixed;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 350px;
		width: 100%;
		z-index: 30;
	}
	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.input_error {
	border: 1px solid red;
	transition: all 0.2s ease-in-out;
}

.close {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 20px;
	height: 20px;
	cursor: pointer;
}
