.item {
	padding: 0;
}

.link {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	font-weight: 500;
	font-size: 16px;
	border-radius: 4px;
	color: rgba(255, 255, 255, 0.56);

	margin-bottom: 6px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&.disabled {
		color: rgba(255, 255, 255, 0.1);
		pointer-events: none;
		cursor: default;
	}

	&.active {
		background: rgba(255, 255, 255, 0.1);
		color: #fff;
	}

	&.enabled {
		color: rgba(255, 255, 255, 0.56);
	}

	&.hidden {
		display: none;
		visibility: hidden;
		opacity: 0;
	}

	@media (max-width: 575px) {
		font-size: 22px;
		padding: 12.5px 8px;
	}
}

.count {
	display: inline-block;
	font-family: 'Raleway', sans-serif;
	width: 24px;
	text-align: center;
}

.name {
	font-family: 'Inter', sans-serif;
	margin-left: 6px;

	@media (max-width: 770px) {
		margin-left: 8px;
	}
}

.waiting {
	width: 24px;
	height: 24px;
}

.indicator {
	font-family: inherit;
	min-height: 48px;
	width: 100%;
	cursor: pointer;
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	margin-bottom: 8px;
	transition: all 0.2s ease-in-out;
	&:hover {
		background: rgba(255, 255, 255, 0.1);
	}
}
