.header {
	position: absolute;
	top: -96px;
	left: 0;
	height: 80px;
	width: 1440px;

	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 575px) {
			object-fit: cover;
		}
	}

	@media (max-width: 575px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.header_btn {
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);
}

.book {
	padding: 61px 79px 61px 70px;

	@media(max-width: 1600px) {
		padding: 40px;
	}

	@media (max-width: 575px) {
		padding: 24px 0 0;
	}
}

.top {
	display: flex;
	align-items: flex-start;
	margin-bottom: 73px;

	@media (max-width: 575px) {
		display: block;
	}
}
