.step1 {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@media (max-width: 575px) {
		flex-direction: column-reverse;
	}
}

.left {
	max-width: 700px;
	width: 100%;

	@media (max-width: 575px) {
		margin-top: 0;
		margin-bottom: 40px;
	}
}

.header {
	margin-bottom: 40px;
	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 32px;
	}
}

.subtitle {
	font-weight: 700;
	font-size: 30px;
	color: #8e9aa8;
	margin-bottom: 10px;

	@media (max-width: 575px) {
		margin-bottom: 8px;
	}
}

.title {
	margin-bottom: 32px;

	@media (max-width: 575px) {
		margin-bottom: 20px;
	}
}

.descr {
	font-family: 'Inter', sans-serif;
	max-width: 600px;
	width: 100%;
	margin-bottom: 40px;
	color: #8e9aa8;
	font-weight: 400;
	font-size: 20px;

	@media (max-width: 575px) {
		margin-bottom: 0;
		font-weight: 400;
		font-size: 16px;
		line-height: 18px;
	}
}

.form_item {
	margin-bottom: 32px;

	&:last-of-type {
		margin-bottom: 40px;
	}
}

.form_title {
	color: #121922;
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 16px;

	@media (max-width: 575px) {
		text-align: left;
	}
}

.birthPlace {
	display: flex;
	flex-direction: column;
}

.input {
	font-family: 'Inter', sans-serif;
	min-height: 48px;
	width: 202px;
	padding: 0 13px;
	background: #f1f4f9;
	font-weight: 400;
	font-size: 18px;
	border: 1px solid #ecf2fc;
	border-radius: 4px;
	color: #71869d;
	margin-right: 8px;
	margin-bottom: 8px;
	transition: all 0.2s ease-in-out;
	&:last-of-type {
		margin-right: 0;
	}

	&:hover {
		background: #f1f4f9;
		color: #2e3c4e;
		border: 1px solid #adbfdf;
	}

	&:focus {
		background: #f1f4f9;
		color: #121922;
		border: 1px solid #71869d;
	}

	@media (max-width: 575px) {
		width: 100%;
		margin-right: 0;
	}
}

.input_patronymic,
.input_date,
.input_code {
	width: 180px;

	@media (max-width: 575px) {
		width: 100%;
	}
}

.input_calendar {
	padding-left: 49px;
}

.input_who,
.input_birthPlace {
	width: 412px;

	@media (max-width: 575px) {
		width: 100%;
	}
}

.input_address {
	width: 600px;

	@media (max-width: 575px) {
		width: 100%;
	}
}

.input_birthPlace {
	margin-bottom: 4px;
}

.input_wrapper {
	position: relative;

	> img {
		position: absolute;
		top: 12px;
		left: 13px;
	}
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
	appearance: none;
	-moz-appearance: textfield;
}

.right {
	padding: 32px;
	margin-left: 40px;
	background: rgba(212, 223, 243, 0.17);
	border-radius: 12px;

	@media (max-width: 575px) {
		margin-left: 0;
		padding: 12px 8px 68px;
	}
}

.right_title {
	display: flex;
	align-items: flex-start;
	margin-bottom: 16px;

	> h3 {
		color: #000729;
		font-weight: 700;
		font-size: 28px;
		
		line-height: 120%;

		@media (max-width: 575px) {
			
			font-size: 18px;
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 10px;
		img {
			margin-right: 4px;
			margin-left: -3px;
		}
	}
}

.right_descr {
	> p {
		color: #000729;
		font-weight: 400;
		font-size: 18px;
		line-height: 150%;
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.error {
	border: 1px solid #e10d0d;
}

.btn_mobile {
	min-width: 304px;
	min-height: 48px;
	font-weight: 500;
	font-size: 18px;
	font-family: inherit;
	position: absolute;
	z-index: 2;
	bottom: 8px;
	left: 50%;
	background: #002cfb;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;

	transform: translateX(-50%);

	@media (max-width: 575px) {
		padding: 0;
		position: fixed;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 350px;
		width: 100%;
		z-index: 30;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.wrapper_mobile {
	display: flex;
	justify-content: space-between;
}

.back {
	position: relative;
	z-index: 3;
	width: 48px;
	height: 48px;
	border: 1px solid #647fff;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:hover {
		border: 1px solid #0024cb;
	}
}

.right_container {
	display: flex;
	gap: 12px;
}

.list {
	padding: 0;
	margin: 0;
	width: 300px;
	li {
		display: flex;
		align-items: center;
		padding: 0;
		margin: 0 0 12px;

		p {
			color: rgba(31, 51, 69, 0.90);
			font-variant-numeric: lining-nums proportional-nums;
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 140%;
			margin-left: 6px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
