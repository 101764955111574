.header {
	position: absolute;
	top: -96px;
	left: 0;
	height: 80px;
	max-width: 1440px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 1800px) {
			object-fit: contain;
		}
	}

	@media (max-width: 575px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.header_btn {
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);

	@media (max-width: 1600px) {
		right: 440px;
	}
}

.plan {
	padding: 40px;

	@media (max-width: 575px) {
		padding: 24px 8px 8px;
	}
}

.wrapper_step1 {
	max-width: 725px;
	width: 100%;
	margin-bottom: 32px;

	@media (max-width: 575px) {
		margin-bottom: 0;
	}
}

.descr_mobile {
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	color: #8e9aa8;
	margin-top: 20px;
	margin-bottom: 32px;
}

.wrapper_step2 {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 40px;

	@media (max-width: 1512px) {
		justify-content: flex-start;
	}

	@media (max-width: 1200px) {
		flex-direction: column;
		gap: 40px;
		justify-content: flex-start;
	}

	@media (max-width: 575px) {
		flex-direction: column;
		margin-bottom: 0;
	}
}

.wrapper_title {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	> img {
		margin-right: 10px;
	}

	@media (max-width: 575px) {
		justify-content: center;
		margin-bottom: 8px;
	}
}

.title {
	max-width: 730px;
	width: 100%;

	@media (max-width: 575px) {
		text-align: center;
	}
}

.title_step2 {
	max-width: 650px;
	width: 100%;

	@media (max-width: 575px) {
		text-align: center;
	}
}

.subtitle_step1 {
	color: #8e9aa8;
	font-weight: 700;
	font-size: 30px;
}

.subtitle_step2 {
	color: #28a879;
	font-weight: 700;
	font-size: 30px;
}

.descr {
	font-family: 'Inter', sans-serif;
	margin-top: 32px;
	color: #8e9aa8;

	@media (max-width: 575px) {
		text-align: center;
		margin-top: 20px;
		margin-bottom: 32px;
	}
}

.wrapper_btn {
	position: relative;
	display: flex;
	justify-content: center;
	
	a {
		display: flex;
        justify-content: center;
		align-items: center;
	}

	 svg {
		margin-left: 15px;
		fill: #fff;
		cursor: pointer;


		@media (max-width: 575px) {
			right: 100px;
		}
	}

	@media (max-width: 575px) {
		width: 100%;
		position: fixed;
		bottom: 8px;
		left: 0;
		z-index: 10;
		text-align: center;
	}
}

.download {
	padding: 0 24px;
	line-height: 48px;
	width: 205px;
	font-family: inherit;
	display: inline-block;
	font-weight: 500;
	font-size: 18px;
	background: #002cfb;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	svg {
	  margin-left: 10px;
	}

	&:hover {
		background: #0024cb;
	}

	@media (max-width: 575px) {
		max-width: 350px;
		width: 100%;
		z-index: 30;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.frame {
	display: inline-block;
	position: relative;
	margin-bottom: 40px;
	transition: all 0.2s ease-in-out;
	width: 100%;
	> img {
		width: 100%;
		object-fit: cover;
	}

	&:hover {
		.link {
			> a {
				color: #fff;
			}

			> svg {
				fill: #fff;
			}
		}
	}

	@media (max-width: 575px) {
		width: 100%;
		margin-bottom: 24px;

		> img {
			width: 100%;
			object-fit: cover;
		}
	}
}

.link {
	position: absolute;
	display: flex;
	align-items: center;
	top: 50%;
	left: 50%;
	z-index: 2;

	transform: translate(-50%, -50%);

	> svg {
		fill: rgba(#fff, 0.56);
		margin-right: 8px;
		transition: all 0.2s ease-in-out;
	}

	> a {
		color: rgba(255, 255, 255, 0.56);
		font-weight: 700;
		font-size: 20px;
		transition: all 0.2s ease-in-out;
	}

	&:hover {
		> a {
			color: #fff;
		}

		> svg {
			fill: #fff;
		}
	}
}

.list {
	padding: 0;
}

.done {
	width: 24px;
	height: 24px;
}

.btn {
	font-family: inherit;
	min-height: 48px;
	padding: 0 20px;
	font-weight: 500;
	font-size: 18px;
	background: #002cfb;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	transition: all 0.2s ease-in;
	margin-top: 452px;

	&:hover {
		background: #0024cb;
	}

	@media (max-width: 575px) {
		z-index: 2;
		width: 350px;
		margin-top: 0;
		position: fixed;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
	}

	@media (max-width: 320px) {
		width: 304px;
	}
}

.illustation {
	position: absolute;
	bottom: 0;
	right: 0;

	@media (max-width: 1600px) {
		height: 540px;
		width: auto;
		right: -90px;
	}

	@media (max-width: 575px) {
		display: block;
		position: static;
		width: 120%;
		margin-left: -24px;
		margin-top: 20px;
	}
}

.footer {
	> h4 {
		color: rgba(31, 51, 69, 0.9);
		font-weight: 600;
		font-size: 24px;
		margin-bottom: 24px;

		@media (max-width: 575px) {
			text-align: center;
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 0;
	}
}

.planLinks {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 0 68px;

	div {

		width: 340px;
		@media (max-width: 2124px) {
		   width: 480px;
		}
   
		@media (max-width: 1588px) {
		   width: 400px;
		}
	   }
}

.planLink {
	display: flex;
	align-items: center;
	transition: all 0.2s ease-in-out;
	margin-bottom: 14px;
	cursor: pointer;
	> svg {
		fill: #526ed3;
		transition: all 0.2s ease-in-out;
	}

	> a {
		font-family: 'Inter', sans-serif;
		font-weight: 500;
		font-size: 14px;
		color: #71869d;
		margin-left: 8px;
	}

	&:hover {
		color: #2e3c4e;
		text-decoration-line: underline;
		text-underline-offset: 3px;

		> svg {
			fill: #2e3c4e;
		}
	}

	@media (max-width: 575px) {
		align-items: initial;
		width: 100%;
		line-height: 20px;
	}
}

.responsible {
	margin-bottom: 61px;
	max-width: 350px;
	width: 100%;
	> h4 {
		margin-bottom: 12px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 24px;
		color: rgba(31, 51, 69, 0.9);

		@media (max-width: 575px) {
			text-align: center;
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 29px;
	}
}

.responsible_info {
	display: flex;
	align-items: center;
	> img {
		border-radius: 50%;
		width: 56px;
		height: 56px;
	}

	> span {
		margin-left: 16px;
		color: #8e9aa8;
		font-weight: 700;
		font-size: 20px;
	}

	@media (max-width: 575px) {
		justify-content: center;
	}
}
