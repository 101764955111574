
.recharts-surface {
    height: 388px;
}

.recharts-label-list text {
    position: absolute;
   font-weight: 600;
   font-size: 14px;
   @media (max-width: 1600px) {
    font-size: 14px;
   }

   @media (max-width: 1200px) {
    font-size: 12px;
   }

   @media (max-width: 1000px) {
    font-size: 10px;
   }
}
