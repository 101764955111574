.articlesForm {
  max-width: 807px;
  width: 100%;
  padding: 24px 0 24px 24px;
  display: flex;
  align-items: center;
  margin: 32px 0 40px;
  background-color: #f9f9f9;
  border-radius: 20px;

  &__content {
    max-width: 512px;
    width: 100%;

    &-video {
      max-width: 760px;
    }
  }
  &__img {
    margin-left: 62px;
    width: 214px;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__descr {
    font-size: 16px;
    color: #000729;
    margin-bottom: 0 !important;
  }

  &__input {
    margin-top: 24px;
    font-size: 16px;
    width: 334px;
    height: 39px;
    border: 1px solid #c7c9d1;
    padding: 0.59em 0.69em;
    border-radius: 4px 0 0 4px;
    &-error {
      margin-top: 24px;
      font-size: 16px;
      width: 334px;
      border: 1px solid #c7c9d1;
      padding: 0.59em 0.69em;
      border: 1px solid red;
      border-radius: 4px 0 0 4px;
    }

    &::placeholder {
      font-size: 16px;
      color: #676b7e;
      font-family: inherit;
    }

    &--footer {
      background: transparent;
      color: #fff;
      &::placeholder {
        color: #fff;
      }

      @media (max-width: 768px) {
        margin-bottom: 20px;
        padding: 13px;
      }
    }

    @media (max-width: 768px) {
      height: unset;
    }
  }

  &__btn {
    font-size: 16px;
    font-weight: 600;
    height: 39px;
    border: none;
    background-color: #00cc6e;
    color: #fff;
    padding: 0.59em 1.25em;
    cursor: pointer;
    transition: all 0.2s linear;
    border-radius: 0 4px 4px 0;

    &-wrapper {
      text-align: center;
      display: inline;

      @media (max-width: 768px) {
        text-align: unset;
      }
    }

    &--footer {
      background-color: #f9f9f9;
      color: #000729;
    }
    &:hover {
      background-color: #23b571;
    }

    @media (max-width: 768px) {
      max-width: 335px;
      padding: 14px;
      width: 100%;
      height: unset;
      color: #fff;
      font-weight: 600;
      font-size: 18px;
      border-radius: 4px;
      color: #000;
    }
  }
  &__form {
    &-result {
      font-size: 20px;
      font-weight: 700;
      margin-top: 10px;
      color: #000;
      white-space: nowrap;
      &--footer {
        text-align: center;
        color: #fff;
        @media (max-width: 768px) {
          text-align: start;
        }
      }
    }
  }
}

.articlesForm-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 72px 0 56px;
  max-width: 926px;
  width: 100%;
  color: #fff;
  background: radial-gradient(
      83.46% 1121.06% at 85.57% 38.35%,
      #317756 0%,
      #33483e 43.05%,
      #1d1f1e 100%
    ),
    conic-gradient(
      from 191.71deg at 66% 49.79%,
      #385945 0deg,
      #292b29 51.99deg,
      #445447 95.11deg,
      #3ff646 207.61deg,
      #08442b 230.11deg,
      #132113 346.89deg,
      #385945 360deg
    ),
    rgba(0, 66, 105, 0.05);
  border-radius: 20px;

  &__content {
    padding: 32px 0 24px 32px;
    max-width: 616px;
    width: 100%;

    &.active {
      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        margin: 0;
      }
    }

    @media (max-width: 768px) {
      padding: 32px 0;
      max-width: 375px;
      width: 100%;
      margin: 0 auto;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      font-size: 17px;
      max-width: 343px;
      width: 100%;
    }
  }

  &__descr {
    @media (max-width: 768px) {
      font-size: 14px;
      max-width: 343px;
      width: 100%;
    }
  }

  &__input {
    margin-top: 40px;
    font-size: 16px;
    width: 336px;
    height: 39px;
    border: 1px solid #c7c9d1;
    padding: 0.59em 0.69em;
    color: #fff;
    background: transparent;
    &::placeholder {
      font-size: 16px;
      color: #fff;
      font-family: inherit;
    }
  }

  &__img {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__btn {
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    height: 39px;
    border: none;
    background-color: #f9f9f9;
    color: #000729;
    padding: 0.59em 1.25em;
    cursor: pointer;
    transition: all 0.2s linear;
    &:hover {
      background-color: #c5c4c4;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  @media (max-width: 1600px) {
    max-width: 960px;
    width: 100%;
    margin: 72px auto 56px;
  }

  @media (max-width: 768px) {
    //max-width: 415px;
    padding: 16px;
    width: 100%;
    border-radius: 0;
    line-height: 130%;
    margin: initial;
  }
}

.header-form__labels {
  display: flex;
  grid-gap: 32px;
  margin-top: 18px;
}

.header-form__label {
  font-size: 16px;
  line-height: 120%;
  color: #c7c9d1;
  display: flex;
  align-items: center;
}

.header-form__checkbox {
  margin: 0 8px 0 0;
  width: 16px;
  height: 16px;

  &:checked::before {
    border: 1px solid #00ff88;
    background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='7' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='3' cy='3.5' r='3' fill='%2300FF88'/%3e%3c/svg%3e ")
      no-repeat center #303550;
    background-position: 4px 3.5px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #303550;
    border: 1px solid rgba(199, 201, 209, 0.3);
    border-radius: 50%;
  }
}
