.course {
    font-family: Inter;
    width: 100%;
    min-height: 600px;

    padding: 40px;
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    color: #1F3345E5;
    overflow: hidden;

    @media(max-width: 1440px) {
        padding: 28px;
    }

    @media(max-width: 768px) {
        padding: 0;
        min-width: none;
        justify-content: center;
    }
}

.container {
    background-color: #F8FAFD;
    border-radius: 8px;
    min-width: 594px;
    box-sizing: border-box;
    padding: 40px;
    width: 35%;
    display: flex;
    flex-direction: column;

    &_right {
        min-width: 770px;
        width: 65%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 88vh;

        h3 {
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.15px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
        }

        @media(max-width: 768px) {
            height: 100%;
            position: relative;
        }
    }

    &_hidden {
        opacity: 0;
        pointer-events: none;
        display: none;
    }

    @media(max-width: 1440px) {
        min-width: 424px;
        padding: 28px;
        overflow: auto;
    }

    @media(max-width: 768px) {
        padding: 20px 0 0;
        background: none;
        min-width: 330px;
        width: 100%;
        border-radius: 0;
    }
}

.header {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 28px;

    div {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
            color: #8E9AA8;
        }
    }

    p {
        font-size: 32px;
        font-weight: 800;
        line-height: 120%;
        color: #1F3345E5;
    }

    @media(max-width: 768px) {
        margin-bottom: 12px;
        position: sticky;
        top: 0;
        left: 0;

        div {
            span {
                font-size: 20px;
                line-height: 120%;
            }
        }

        p {
            font-size: 24px;
        }
    }
}

.done {
    color: #2EA96E !important;
}

.sub {
    margin-top: -8px;
    margin-bottom: 20px;
    color: #8E9AA8;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    @media(max-width: 768px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 16px;

    }
}

.arrow {
    width: 36px;
    height: 36px;
}

.menu {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.15px;
        margin-bottom: 10px;
    }

    @media(max-width: 768px) {

        h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0.15px;
            margin-bottom: 8px;
        }
    }

    &_mobile {
        width: 100%;

        background-color: #F8FAFD;
        border-radius: 4px;
        margin-top: 16px;
        height: 44px;
        position: relative;
    }

    &_list {
        position: absolute;
        padding: 0;
        top: 44px;
        left: 0;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        background-color: #F8FAFD;
        width: 100%;
        height: 0;
        transition: all 0.25s;
        overflow: hidden;
        z-index: 5;

        svg {
            width: 16px;
            height: 16px;
        }

        &_open {
            height: 358px;
        }

        li {
            height: 44px;
            padding: 12px 16px;
            flex-shrink: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &_header {
        width: 100%;
        padding: 12px 16px;
    }
}

.legal {
    h3 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
    }
}



.text {
    color: #71869D !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 140% !important;

    &_active {
        color: #121922 !important;
    }
}

.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    padding: 0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 16px;
    cursor: pointer;
    transition: all 0.15s;

    &:hover {
        background-color: rgba(0, 44, 251, 0.02);
    }

    span {
        color: #71869D;
        font-size: 12px;
        transition: all 0.15s;
        opacity: 0;
        text-wrap: nowrap;
        flex-shrink: 0;
    }

    &_disabled {
        pointer-events: none;

        span {
            opacity: 1;
        }
    }

    &_active {
        background-color: rgba(0, 44, 251, 0.05);
    }

    &_available {
        span {
            opacity: 1;
        }
    }

    @media(max-width: 768px) {
        padding: 12px;
    }
}

.point {
    display: flex;
    gap: 10px;
    font-size: 500;

    svg {
        transition: all 0.15s;
        opacity: 1;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }

    div {
        margin-top: 2px;
        transition: all 0.15s;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #bfcbe000;
        position: relative;
    }

    &_disabled {
        font-size: 400;
        color: #71869D;

        svg {
            opacity: 0;
        }

        div {
            border: 1px solid #BFCBE0;
        }
    }

    &_active {
        font-weight: 500;
        color: #121922;

    }

    &_available {
        font-weight: 500;
        color: #121922;

        svg {
            opacity: 0;
        }

        div {
            border: 1px solid #BFCBE0;
        }
    }

    &_start {
        color: #71869D;
    }

    @media(max-width: 768px) {
        gap: 8px;
    }
}


.video {
    width: 100%;
    height: auto;
    aspect-ratio: 720 / 405
}

.content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    transition: all 0.15s;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    a {
        text-decoration: underline;
        color: #1F3345E5;

        &:active {
            text-decoration: none;
            color: #1F3345E5;
        }

        &:hover {
            text-decoration: none;
            color: #1F3345E5;
        }
    }

    span {
        color: #8E9AA8;
        line-height: 22px;
        margin-top: -8px;

        @media(max-width: 768px) {
            margin-top: 0;
        }
    }

    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media(max-width: 768px) {
            h3 {
                display: none;
            }
        }
    }

    @media(max-width: 768px) {
        overflow: hidden;
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    @media(max-width: 768px) {
        width: calc(100% - 12px);
        margin: 0 6px;
        box-sizing: border-box;
        height: 48px;
        display: flex;
        justify-content: center;
        font-size: 18px;
        margin-top: 8px;
        position: fixed;
        bottom: 12px;
        left: 0;

    }
}


.button {
    margin-top: auto;
    width: fit-content;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #002CFB;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
    transition: all 0.15s;


    &:hover {
        background-color: #0028E5;
    }

    &:disabled {
        pointer-events: none;
        background-color: #E9EEF5;
        color: #BFCBE0;
    }

    &_succes {
        pointer-events: none;
        background-color: #2EA96E;
    }

    &_hidden {
        pointer-events: none;
        opacity: 0;
    }

    @media(max-width: 768px) {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
    }

    &_back {
        min-width: 118px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color: #002CFB;
        border: 1px solid #002CFB;
        font-size: 16px;
        font-weight: 500;
        background-color: #ffffff;
    }

    &_start {
        @media(max-width: 768px) {
            width: calc(100% - 12px);
            margin: 0 6px;
            box-sizing: border-box;
            height: 48px;
            display: flex;
            justify-content: center;
            font-size: 18px;
            margin-top: 8px;
            position: fixed;
            bottom: 8px;
            left: 0;

        }
    }
}

.exam {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    overflow: auto;
}

.question {
    h3 {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.15px;
    }

    &_header {
        display: flex;
        gap: 4px;
        margin-bottom: 12px;
    }
}

.num {
    min-width: 24px;
    text-align: start;
}

.answers {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.answer {
    padding: 0 28px;
    line-height: 22px;
    display: flex;
    gap: 8px;
    cursor: pointer;

    &_succes {
        color: #2EA96E;
    }

    &_fail {
        color: #E75A5A;
    }

    &_disabled {
        pointer-events: none;
    }
}

.radio {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1.5px solid #DAE2F1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    transition: all 0.15s;
    margin-top: 4px;

    div {
        width: 10px;
        height: 10px;
        background-color: #002CFB;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.15s;
    }

    &_active {
        border-color: #002CFB;

        div {
            opacity: 1;
        }
    }

    &_succes {
        border-color: #2EA96E;

        div {
            background-color: #2EA96E;
        }
    }

    &_fail {
        border-color: #E75A5A;

        div {
            background-color: #E75A5A;
        }
    }
}

.image {
    width: auto;
    height: 90%;
    position: absolute;
    bottom: 0;
    right: 40px;

    @media(max-width: 1440px) {
        height: 70%;
    }

    @media(max-width: 1340px) {
        display: none;
    }
}

.reserved {
    color: #8E9AA8;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 20px;

    @media (max-width: 575px) {
        margin-top: 8px;
    }
}

.arrow2 {
    margin-left: auto;
    gap: 0 !important;
    transition: all 0.15s;
    
    &_open {
        transform: rotate(180deg);
    }
}

.baner {
	position: absolute;
	top: -96px;
	height: 80px;
	max-width: 1440px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;


	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 1800px) {
			object-fit: contain;
		}
	}

	@media (max-width: 575px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.baner_btn {
	position: absolute;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);

	@media (max-width: 1600px) {
		right: 440px;
	}
}


.content::-webkit-scrollbar {
    transition: all 0.2;
    visibility: visible;
    width: 4px;

}

.content::-webkit-scrollbar-track {
    /* background: var(--color-bg-card); */
}

.content::-webkit-scrollbar-thumb {
    background-color: #E9EEF5;
    border-radius: 4px;

}


.exam::-webkit-scrollbar {
    transition: all 0.2;
    visibility: visible;
    width: 4px;

}

.exam::-webkit-scrollbar-track {
    /* background: var(--color-bg-card); */
}

.exam::-webkit-scrollbar-thumb {
    background-color: #E9EEF5;
    border-radius: 4px;

}