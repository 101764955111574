.documents {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
}

.subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #71869D;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.item {
    display: flex;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    img {
        margin-bottom: -3px;
    }
}


.load {
    width: 100%;
    min-height: 72px;
    height: fit-content;
    border-radius: 8px;
    border: 1.4px dashed #EAF0FA;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 2;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    transition: all 0.2s;
    position: relative;
    z-index: 2;
    padding: 16px 16px;
   
    p {
        transition: all 0.2s;
    }

    input {
        display: none;
    }


    label {
        color: #002CFB;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
            color: #0028E5;
        }
    }

    &_drag {
        border-color: #002CFB;

        p {
            opacity: 0;
            visibility: hidden;
        }
    }
}

.sub {
    font-size: 12px;
    line-height: 14px;
    /* margin-bottom: 18px; */
    color: #71869D;

}

.download {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #F8FAFD;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s;
    border-radius: 8px;

    svg path {
        fill: #ADBFDF;
    }

    &_drag {
        visibility: visible;
        opacity: 1;
    }

}

.files {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    margin-top: 16px;

    &_hiden {
        margin-top: 0;
    }
}

.file {

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    height: 0;
    box-sizing: border-box;
    background-color: #F8FAFD;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    overflow: hidden;
    text-wrap: nowrap;
    flex-wrap: nowrap;
    transition: all 0.25s;
    opacity: 0;
    &:last-child {
        margin-bottom: 16px;
    }


    svg {
        z-index: 1;
    }


    .block {
        display: flex;
        align-items: center;

        svg {
            margin-right: 12px;
        }
    }

    &_anim {
        height: 48px;
        padding: 12px 16px;
        opacity: 1;
        margin-bottom: 8px;
    }

   

}

.delete {
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        opacity: 0.7;
    }
}

.err {
    position: absolute;
    z-index: 5;
    bottom: -24px;
    left: 0;
    color: #E75A5A;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}