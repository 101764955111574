.current {
	margin-bottom: 48px;

	@media (max-width: 575px) {
		text-align: center;
	}
}

.current_info {
	> h5 {
		font-family: 'Inter', sans-serif;
		color: #8e9aa8;
		font-weight: 400;
		font-size: 20px;
		margin-bottom: 8px;

		@media (max-width: 575px) {
			margin-bottom: 20px;
		}
	}

	> div {
		font-family: 'Inter', sans-serif;
		color: #2e3c4e;
		font-weight: 600;
		font-size: 36px;
		margin-bottom: 48px;
	}
}

.checkboxs {
	display: flex;
	margin-bottom: 48px;
	cursor: default;

	@media (max-width: 575px) {
		flex-direction: column;
		gap: 12px 0;
	}
}

.checkboxs {
	display: flex;
	margin-bottom: 48px;
	cursor: default;
}

.radio {
	line-height: 58px;
	position: relative;
	cursor: pointer;
	z-index: 2;
	margin-right: 8px;
	background: rgba(212, 223, 243, 0.17);
	border: 1px solid transparent;
	border-radius: 8px;
	padding: 0 16px 0 50px;
	transition: all 0.2s ease-in;

	&_active {
		background: rgba(212, 223, 243, 0.429);	
	}

	&:hover {
		background: rgba(212, 223, 243, 0.429);	
	}


	> label {
		display: inline-block;
		max-width: 600px;
		min-height: 58px;
		vertical-align: sub;
		font-weight: 600;
		font-size: 20px;
		color: #121922;
		transition: all 0.2s ease-in-out;
		cursor: pointer;
		

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 16px;
			z-index: 1;
			transform: translateY(-50%);
			transition: all 0.2s linear;
			width: 20px;
			height: 20px;
			border: 2px solid #adbfdf;
			background-color: transparent;
			border-radius: 50%;
		}

		&:after {
			content: '';
			opacity: 0;
			position: absolute;
			display: block;
			background: #002cfb;
			width: 10px;
			height: 10px;
			top: 50%;
			left: 21px;
			z-index: 2;
			border-radius: 50%;
			transform: translateY(-50%);
		}
	}

	> input {
		appearance: none;
		position: absolute;

		&:checked + label::before {
			border: 2px solid #002cfb;
		}

		&:checked + label::after {
			opacity: 1;
		}

		&:hover + label {
			color: #2e3c4e;
		}
	}

	/* &:hover {
		color: #2e3c4e;
		border: 1px solid #adbfdf;

		label::before {
			border: 2px solid #002cfb;
		}
	} */

	&:first-of-type {
		padding: 0 57px 0 50px;
	}
}

.details {
	max-width: 600px;
	width: 100%;
	margin-bottom: 48px;

	> h5 {
		font-family: 'Inter', sans-serif;
		color: #2e3c4e;
		font-weight: 400;
		font-size: 20px;
	}

	> ul {
		margin-top: 16px;
		padding: 0;
		list-style-type: none;
		color: #8e9aa8;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		> li {
			padding: 0;
		}
	}
}

.form {
	> form {
		> button {
			position: relative;
			z-index: 2;
			font-weight: 500;
			font-size: 18px;
			font-family: inherit;
			background: #002cfb;
			border-radius: 4px;
			color: #fff;
			min-height: 48px;
			padding: 0 24px;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			margin-bottom: 152px;

			&:hover {
				background: #0024cb;
			}

			@media (max-width: 575px) {
				margin-bottom: 0;
				position: fixed;
				bottom: 8px;
				left: 50%;
				padding: 0;
				width: 90%;
				transform: translateX(-50%);
			}
		}
	}
}
