.card {
	max-width: 328px;
	min-height: 530px;
	width: 100%;
	background: rgba(212, 223, 243, 0.17);
	border-radius: 12px;
	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	> img {
		@media (max-width: 575px) {
			width: 100%;
		}
	}

	@media (max-width: 1100px) {
		width: 428px;
		max-width: 428px;
	}

	@media (max-width: 575px) {
		max-width: 100%;
		min-height: 430px;
	}
}

.content {
	padding: 24px;

	@media (max-width: 575px) {
		padding: 0 8px 8px;
	}
}

.container {
	max-width: 280px;
	width: 100%;
	margin: 0 auto;

	@media (max-width: 575px) {
		max-width: 100%;
	}
}

.wrapper {
	@media (max-width: 575px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 16px 0;
	}
}

.title {
	font-weight: 800;
	font-size: 32px;
	color: rgba(31, 51, 69, 0.9);
	margin-bottom: 8px;

	@media (max-width: 575px) {
		font-size: 20px;
		margin-bottom: 0;
	}
}

.subtitle {
	font-weight: 400;
	font-size: 16px;
	color: #8e9aa8;
	margin-bottom: 40px;

	@media (max-width: 575px) {
		margin-bottom: 0;
	}
}

.descr {
	font-weight: 400;
	font-size: 18px;
	color: rgba(31, 51, 69, 0.9);
	margin-bottom: 40px;
	line-height: 120%;

	@media (max-width: 575px) {
		margin-bottom: 16px;
		line-height: 24px;
	}
}

.btn {
	display: inline-block;
	text-align: center;
	font-family: inherit;
	padding: 12.5px 0;
	min-width: 280px;
	color: #002cfb;
	border: 1px solid #4b6bff;
	border-radius: 4px;
	font-weight: 500;
	font-size: 18px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:hover {
		border: 1px solid #0024cb;
		color: #0024cb;
	}

	&.btn__blue {
		background: #002cfb;
		color: #fff;

		&:hover {
			background: #0024cb;
		}
	}

	@media (max-width: 575px) {
		min-width: 100%;
	}
}
