@font-face {
  font-family: 'rawline';
  src: url('../../fonts/rawline-300.woff2') format('woff2'),
    url('../../fonts/rawline-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'rawline';
  src: url('../../fonts/rawline-400.woff2') format('woff2'),
    url('../../fonts/rawline-400.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'rawline';
  src: url('../../fonts/rawline-500.woff2') format('woff2'),
    url('../../fonts/rawline-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'rawline';
  src: url('../../fonts/rawline-600.woff2') format('woff2'),
    url('../../fonts/rawline-600.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'rawline';
  src: url('../../fonts/rawline-700.woff2') format('woff2'),
    url('../../fonts/rawline-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'rawline';
  src: url('../../fonts/rawline-800.woff2') format('woff2'),
    url('../../fonts/rawline-800.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'rawline';
  src: url('../../fonts/rawline-900.woff2') format('woff2'),
    url('../../fonts/rawline-900.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bitter';
  src: url('../../fonts/Bitter.woff2') format('woff2'),
    url('../../fonts/Bitter.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter.woff2') format('woff2'),
    url('../../fonts/Inter.woff') format('woff');
  font-style: normal;
  font-display: swap;
}
