.overlay {
	visibility: hidden;
	opacity: 0;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100vh;
	background: rgba(#2a353f, 60%);
	top: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 0.2s ease-in-out;

	@media(max-width: 575px) {
		height: calc(var(--vh, 1vh) * 100);
	}
}

.overlay_active {
	visibility: visible;
	opacity: 1;
	z-index: 99;
}

.form {
	position: relative;
	padding: 24px;
	background: #ffffff;
	text-align: center;
	border-radius: 8px;
	max-width: 520px;
	width: 100%;
	font-family: 'Inter', sans-serif;

	@media (max-width: 575px) {
		max-width: 100%;
		padding: 16px;
		position: fixed;
		bottom: 0;
		border-radius: 8px 8px 0px 0px;
	}
}

.close {
	position: absolute;
	top: 24px;
	right: 20px;
	cursor: pointer;

	@media (max-width: 320px) {
		top: 10px;
		right: 10px;
	}
}

.title {
	max-width: 260px;
	width: 100%;
	margin: 0 auto;
	font-weight: 600;
	font-size: 24px;
	line-height: 30px;
	color: #121922;
	margin-bottom: 50px;

	@media (max-width: 575px) {
		font-size: 18px;
		margin-bottom: 32px;
	}
}

.inputs {
	margin-bottom: 51px;
}

.input {
	font-family: inherit;
	color: #121922;
	font-weight: 400;
	font-size: 36px;
	width: 64px;
	height: 64px;
	background: #f1f4f9;
	border-radius: 4px;
	border: none;
	text-align: center;
	margin-right: 8px;
	padding: 0;
	transition: all 0.2s ease-in-out;

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		border: 1px solid #adbfdf;
	}

	&:focus {
		border: 1px solid #71869d;
	}

	@media (max-width: 575px) {
		padding-left: 35px;
		text-align: center;
		width: 100%;
		margin-right: 0;
		margin-bottom: 0;
		letter-spacing: 30px;

		&:hover {
			border: none;
		}
		&:focus {
			border: none;
		}
	}
}

.buttons {
	> button {
		width: 100%;
		font-family: 'Raleway', sans-serif;
		cursor: pointer;
	}
}

.noCode {
	display: flex;
	margin-bottom: 40px;

	> img {
		width: 46px;
		height: 46px;
		margin-right: 16px;
	}
}

.descr,
.repeat {
	font-weight: 400;
	font-size: 16px;
	color: #8e9aa8;
	margin-bottom: 25px;

	@media (max-width: 575px) {
		text-align: center;
	}
}

.repeat {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: underline;
	text-underline-offset: 4px;
	&:hover {
		color: #2e3c4e;
	}
}

.info {
	margin: 0 0 25px;
}

.error {
	border: 1px solid #e10d0d;
}

.wrapper {
	display: flex;
	flex-direction: column;
}
