.formLive {
  max-width: 760px;
  width: 100%;
  padding: 24px 32px 32px;
  background-color: #f9f9f9;
  margin: 40px auto;
  border-radius: 20px;
  &__title {
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    line-height: 130%;
    color: #abadba;

    @media (max-width: 768px) {
      font-size: 15px;
    }
  }
  &__subtitle {
    font-weight: 700;
    max-width: 696px;
    width: 100%;
    font-family: 'Bitter', sans-serif;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    margin: 16px 0 38px !important;

    @media (max-width: 768px) {
      font-size: 17px;
    }
  }

  @media (max-width: 1600px) {
    margin: 40px auto;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
}

.items-formLive {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px 24px;
  &__item {
    font-size: 16px;
    font-weight: 500;
    width: 336px;
    color: #818598;
    padding: 0.88em 0;
    background: transparent;
    border: 1px solid #e3e4e8;
    border-radius: 0.25em;
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      color: #009953;
      border: 1px solid #00cc6e;
    }

    @media (max-width: 768px) {
      width: unset;
    }
  }

  .active {
    color: #009953;
    border: 1px solid #00cc6e;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.answer {
  max-width: 632px;
  font-size: 30px;
  text-align: center;
  color: #009953;
  line-height: 130%;
  margin: 0 auto;
  padding: 0 32px;

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 0;
  }
}
