.header {
	position: absolute;
	top: -96px;
	left: 0;
	height: 80px;
	max-width: 1440px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;

	z-index: 10;
	cursor: pointer;

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;
		@media (max-width: 1800px) {
			object-fit: contain;
		}
	}

	@media (max-width: 575px) {
		position: static;
		width: 100%;
		height: initial;
	}
}

.header_btn {
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);

	@media (max-width: 1600px) {
		right: 440px;
	}
}


.payment {
	padding: 40px 40px 52px 40px;
	min-height: 780px;

	@media (max-width: 575px) {
		padding: 24px 0 0;
		margin-bottom: -8px;
	}
}

.btn {
	opacity: 0;
	visibility: hidden;
	margin-bottom: 368px;
}

.wrapper_title {
	max-width: 655px;
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	> img {
		margin-right: 10px;
	}

	@media (max-width: 575px) {
		justify-content: center;
	}
}

.title {
	margin-bottom: 32px;
	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 20px;
	}
}

.subtitle {
	color: #8e9aa8;
	font-weight: 700;
	font-size: 30px;
	margin-bottom: 10px;

	@media (max-width: 575px) {
		text-align: center;
	}
}

.subtitle_done {
	font-weight: 700;
	font-size: 30px;
	margin-bottom: 10px;
	color: #28a879;
}

.subtitle_return {
	font-weight: 700;
	font-size: 30px;
	margin-bottom: 10px;
	color: #e10d0d;
}

.descr,
.descr_return {
	max-width: 680px;
	width: 100%;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	color: #8e9aa8;
	margin-bottom: 48px;
	@media (max-width: 575px) {
		text-align: center;
		margin-bottom: 0;
	}
}

.descr {
	@media (max-width: 575px) {
		margin-bottom: 32px;
	}
}

.descr_return {
	max-width: 478px;

	@media (max-width: 575px) {
		margin-bottom: 40px;
	}
}

.info_return {
	max-width: 505px;
	width: 100%;
	background: rgba(212, 223, 243, 0.17);
	border-radius: 8px;
	padding: 12px 24px;
	margin-bottom: 240px;

	> h5 {
		font-family: 'Inter', sans-serif;
		color: #8e9aa8;
		font-weight: 400;
		font-size: 20px;
		margin-bottom: 8px;
	}
	> div {
		font-family: 'Inter', sans-serif;
		font-weight: 600;
		font-size: 36px;
		line-height: 42px;
		color: #2e3c4e;

		@media (max-width: 575px) {
			font-weight: 600;
			font-size: 36px;
			line-height: 42px;
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 0;
	}
}

.bottom {
	> div {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		color: #121922;
		font-weight: 600;
		font-size: 20px;

		> span {
			margin-left: 8px;
		}
	}

	> p {
		max-width: 632px;
		width: 100%;
		font-family: 'Inter', sans-serif;
		color: #8e9aa8;
		font-weight: 400;
		font-size: 20px;
		line-height: 26px;

		@media (max-width: 575px) {
			margin-bottom: 0;
		}
	}
}

.illustation {
	position: absolute;
	bottom: 0;
	right: 0;

	@media (max-width: 1600px) {
		display: none;
	}

	@media (max-width: 575px) {
		width: 100%;
		display: block;
		position: static;
		margin-left: 16px;
	}
}

.wrapper_title {
	max-width: 740px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	margin-bottom: 16px;

	> img {
		margin-right: 10px;
	}
}

.details {
	max-width: 600px;
	width: 100%;
	margin-bottom: 48px;

	> h5 {
		font-family: 'Inter', sans-serif;
		color: #2e3c4e;
		font-weight: 400;
		font-size: 20px;

		@media (max-width: 575px) {
			text-align: center;
			margin-bottom: 16px;
		}
	}

	> ul {
		margin-top: 16px;
		padding: 0;
		list-style-type: none;
		color: #8e9aa8;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		> li {
			padding: 0;
		}
	}

	@media (max-width: 575px) {
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 0;
	}
}

.done {


	@media (max-width: 575px) {
		padding: 0;
	}
}
