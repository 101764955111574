.btn-back {
	position: relative;
	z-index: 2;
	color: #002cfb;
	font-weight: 500;
	font-size: 18px;
	border: 1px solid #002cfb;
	border-radius: 4px;

	min-height: 48px;
	min-width: 101px;

	padding: 0 24px;

	transition: all 0.2s ease-in-out;

	&:hover {
		border: 1px solid #0024cb;
		color: #0024cb;
	}
}

.btn-send {
	position: relative;
	z-index: 2;
	background: #f1f4f9;
	color: #adbfdf;
	border-radius: 4px;
	font-weight: 500;
	font-size: 18px;
	min-width: 140px;
	min-height: 48px;
	transition: all 0.2s ease-in-out;

	&.active {
		background: #002cfb;
		color: #fff;

		&:hover {
			background: #0024cb;
		}
	}

	&.disabled {
		pointer-events: none;
		cursor: default;
	}
}

.btn-material {
	position: relative;
	z-index: 5;
	font-family: inherit;
	background: #002cfb;
	color: #fff;
	border-radius: 4px;
	font-weight: 500;
	font-size: 18px;
	margin: 40px 0 0 0;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	min-height: 48px;
	padding: 0 40px;

	&:hover {
		background: #0024cb;
	}

	&:disabled {
        pointer-events: none;
        background-color: #F1F4F9;
        color: #8E9AA8;
    }
}

.btn-noSms {
	position: relative;
	z-index: 2;
	font-family: inherit;
	font-weight: 500;
	font-size: 16px;
	color: #71869d;
	text-decoration: underline;
	text-underline-offset: 3px;
	margin-bottom: 49px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:hover {
		color: #2e3c4e;
	}

	@media (max-width: 575px) {
		width: 100%;
		text-align: center;
	}
}

.addNumberPhone {
	position: relative;
	z-index: 2;
	font-family: inherit;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: #002cfb;
	border-radius: 4px;
	cursor: pointer;

	min-height: 48px;
	padding: 0 24px;
	margin-bottom: 407px;
	transition: all 0.2s ease-in-out;

	&:hover {
		background: #0024cb;
	}
}
