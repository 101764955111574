.lastEvents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
	box-sizing: border-box;

	@media (max-width: 1350px) {
		align-items: center;
	}
}

.table {
	display: grid;
	grid-template-columns: repeat(4, 320px);
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 30px;
	margin-bottom: 30px;
	align-items: flex-start;

	@media (max-width: 1850px) {
		grid-template-columns: repeat(3, 320px);
	}

	@media (max-width: 1580px) {
		gap: 30px;
	}

	@media (max-width: 1460px) {
		grid-template-columns: repeat(2, 320px);
		gap: 40px;
	}

	@media (max-width: 1100px) {
		gap: 20px;
	}

	@media (max-width: 1060px) {
		grid-template-columns: repeat(1, 320px);
		justify-items: center;
	}
}

.button {
	width: 333px;
	height: 48px;
	font-family: 'Raleway';
	font-weight: 600;
    font-size: 18px;
    line-height: 130%;
	color: #002CFB;
	border: solid 1px #002CFB;
	border-radius: 4px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s;
	cursor: pointer;

	@media (max-width: 1350px) {
		width: 320px;
		font-size: 16px;
	}

	> span {
		margin-left: 20px;
    }
}

.button:hover {
	border-color: #0024CB;
	color: #0024CB;
}

.button:hover span svg path {
	fill: #0024CB;
}
