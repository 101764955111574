
.header {
	position: absolute;
	
	width: calc(100%);
	top: -96px;
	left: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 1700px) {
		top: -86px;
	}

	@media (max-width: 1460px) {
		top: -76px;
	}

	@media (max-width: 1280px) {
	    margin-left: -40px;
	    margin-right: -40px;
	    width: calc(100% + 80px);
	}

	@media (max-width: 1200px) {
		flex-direction: column;
		top: -100px;
	}

	@media (max-width: 820px) {
	  top: -120px;
	}

	@media (max-width: 770px) {
		position: static;
		width: 100%;
		height: initial;
		padding-top: 24px;
		margin: 0;
	}

	> h2 {
		font-weight: 800;
		font-size: 24px;
		line-height: 40px;
		color: rgba(31, 51, 69, 0.9);

		@media (max-width: 1700px) {
			font-size: 20px;
		}
	
		@media (max-width: 1460px) {
			font-size: 18px;
		}

		@media (max-width: 770px) {
			line-height: 28px;
			margin-bottom: 30px;
			max-width: 325px;
			text-align: center;
		}
	}

	> img {
		height: 100%;
		width: 100%;
		object-fit: none;

		@media (max-width: 575px) {
			object-fit: cover;
		}
	}

	
}

.header_btn {
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 323px;
	min-height: 48px;
	line-height: 48px;
	font-family: inherit;
	padding: 0 24px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.56);
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: translateY(-50%);
}

.subtitle {
	color: rgba(31, 51, 69, 0.9);
	font-weight: 800;
	font-size: 24px;
	margin-bottom: 35px;
}

.wrapper {
	max-width: 600px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	@media (max-width: 1180px) {
		max-width: 500px;
	}

	@media (max-width: 820px) {
		flex-direction: column;
		max-width: 400px;
	}

	@media (max-width: 770px) {
		margin-bottom: 40px;
	}
}


