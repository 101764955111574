.nextEventItem {
	background: #fff;
	max-width: 708px;
	width: 100%;

	> img {
		width: 100%;
	}

	@media (max-width: 1800px) {
		max-width: 600px;
	}

	@media (max-width: 575px) {
		max-width: 343px;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.content {
	padding: 30px 20px 20px;

	> h4 {
		color: rgba(31, 51, 69, 0.9);
		font-weight: 600;
		font-size: 24px;
		margin-bottom: 6px;
	}

	> p {
		margin-bottom: 20px;
		color: #8e9aa8;
		font-family: 'Inter', sans-serif;
		font-weight: 500;
		font-size: 16px;
	}
}
