.overlay {
	visibility: hidden;
	opacity: 0;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100vh;
	z-index: 100;
	background: rgba(#2a353f, 60%);
	top: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 0.2s ease-in-out;

	@media (max-width: 575px) {
		overflow-y: auto;
		height: calc(var(--vh, 1vh) * 100);
	}
}

.overlay_active {
	visibility: visible;
	opacity: 1;
	z-index: 99;
}

.modal {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	max-width: 1162px;
	width: 100%;
	padding: 22px 60px 0 60px;
	background: #fff;
	background: radial-gradient(
		68.95% 82.61% at 97.8% 103.56%,
		rgba(167, 213, 196, 1) 0%,
		rgba(255, 255, 255, 1) 100%
	);

	border-radius: 8px;

	@media (max-width: 575px) {
		position: absolute;
		min-height: 394px;
		bottom: 0;
		max-width: 100%;
		max-height: 550px;
		flex-direction: column;
		align-items: inherit;
		justify-content: flex-start;
		overflow-y: auto;
		padding: 0 8px 0;
		border-radius: 8px 8px 1px 0px;
	}
}

.wrapper_title {
	display: flex;
	align-items: flex-start;

	> img {
		margin-right: 15px;

		@media (max-width: 575px) {
			width: 28px;
			height: 28px;
		}
	}

	@media (max-width: 575px) {
		align-items: center;
	}
}

.subtitle {
	color: #8e9aa8;
	font-weight: 700;
	font-size: 30px;
	margin-bottom: 10px;

	@media (max-width: 575px) {
		font-size: 24px;
		text-align: start;
		margin-bottom: 8px;
	}
}

.title {
	font-weight: 800;
	font-size: 38px;
	margin-bottom: 32px;
	@media (max-width: 575px) {
		font-size: 24px;
		margin-bottom: 26px;
	}
}

.title_success {
	color: #28a879;
	margin-bottom: 40px;
	font-weight: 700;
	font-size: 38px;

	@media (max-width: 575px) {
		font-size: 24px;
		margin-bottom: 26px;
		margin-top: 35px;
	}
}

.title_waiting {
	color: #121922;
	margin-bottom: 40px;
	font-weight: 700;
	font-size: 38px;

	@media (max-width: 575px) {
		font-size: 24px;
		margin-bottom: 26px;
		margin-top: 35px;
	}
}

.title_failure {
	color: #e10d0d;
	margin-bottom: 40px;
	font-weight: 700;
	font-size: 38px;
	@media (max-width: 575px) {
		font-size: 24px;
		margin-bottom: 26px;
		margin-top: 35px;
	}
}

.descr {
	margin-bottom: 40px;
	color: #8e9aa8;
	font-size: 20px;

	@media (max-width: 575px) {
		font-size: 18px;
	}
}

.content {
	max-width: 570px;
	width: 100%;
	padding-top: 38px;

	@media (max-width: 575px) {
		text-align: center;
		padding-top: 16px;
	}
}

.price {
	margin-bottom: 40px;

	@media (max-width: 575px) {
		margin-bottom: 160px;
	}
}

.price_new {
	color: #2e3c4e;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	font-size: 36px;
	margin-right: 8px;

	@media (max-width: 575px) {
		font-size: 24px;
	}
}

.price_old {
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	font-size: 18px;
	text-decoration-line: line-through;
	color: #8e9aa8;

	@media (max-width: 575px) {
		font-size: 16px;
	}
}

.formApi {
	> form {
		> button {
			background: #a2a9b4;
			cursor: pointer;
			position: relative;
			min-height: 48px;
			padding-right: 20px;
			z-index: 10;
			font-family: inherit;
			font-weight: 500;
			font-size: 18px;
			min-width: 278px;
			color: #ffffff;

			border-radius: 0px 4px 4px 0px;
			transition: all 0.2s ease-in-out;

			&::after {
				content: url('../../../../../../public/static/lk/IconForward-small.svg');
				position: absolute;
				right: 42px;
				top: 50%;
				width: 16px;
				height: 16px;
				transform: translateY(-50%);

				@media (max-width: 575px) {
					right: 55px;
				}
			}

			@media (max-width: 575px) {
				padding: 0;

				justify-content: center;
				max-width: 350px;
				width: 100%;
				margin: 0 auto;
				z-index: 30;
			}

			@media (max-width: 320px) {
				max-width: 304px;
			}
		}
	}
}

.btn_success,
.btn_failure {
	display: inline-block;
	position: relative;
	min-height: 48px;
	line-height: 48px;
	z-index: 10;
	font-family: inherit;
	font-weight: 500;
	font-size: 18px;
	max-width: 233px;
	width: 100%;
	text-align: center;
	color: #ffffff;
	background: #3357ff;
	border-radius: 0px 4px 4px 0px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		background: #0024cb;
	}

	@media (max-width: 575px) {
		padding: 0;
		position: fixed;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 350px;
		width: 100%;
		z-index: 30;
	}

	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.input {
	> input {
		background: #f1f4f9;
		border-radius: 4px 0px 0px 4px;
		min-height: 48px;
		min-width: 251px;
		border: none;
		padding: 10.5px 13px;
		width: 100%;
		font-size: 18px;
		font-family: inherit;
		line-height: 145%;

		&::placeholder {
			color: #8e9aa8;
			font-weight: 400;
			font-size: 18px;
		}
	}
}

.input {
	@media (max-width: 575px) {
		margin-bottom: 12px;
	}
}

.wrapper {
	display: flex;

	@media (max-width: 575px) {
		flex-direction: column;
		padding: 0;
		position: fixed;
		bottom: 8px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 350px;
		width: 100%;
		z-index: 30;
	}
	@media (max-width: 320px) {
		max-width: 304px;
	}
}

.input_error {
	border: 1px solid red;
	transition: all 0.2s ease-in-out;
}

.close {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.sale {
	margin-left: 16px;
	font-size: 22px;
	font-weight: 600;
	color: #121922;

	@media(max-width: 575px) {
		font-size: 17px;
		font-weight: 500;
	}

	@media(max-width: 320px) {
		font-size: 14px;
	}
}