.header-articles {
  &__intro {
    position: absolute;
    bottom: 80px;

    &.partners {
      @media (max-width: 768px) {
        bottom: -185px;
      }
    }

    &.clients {
      @media (max-width: 768px) {
        bottom: 0;
      }
    }

    @media (max-width: 1600px) {
      bottom: 25px;
    }

    @media (max-width: 768px) {
      max-width: initial;
      bottom: 0;
    }
  }

  @media (max-width: 768px) {
    min-height: 570px;
  }
}

.articles {
  background: #fff;
  color: #000;
  overflow: hidden;

  h1,
  h2,
  h3,
  p,
  ul,
  ol {
    max-width: 807px;
    margin: 0 auto;
    @media (max-width: 1600px) {
      max-width: 760px;
    }
    @media (max-width: 768px) {
      max-width: 343px;
    }
  }

  img {
    max-width: 1920px;
    //margin: 0 auto 12px;
    //object-fit: cover;
  }

  h2 {
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  h2,
  h3 {
    margin: 16px auto;
  }

  h2,
  h3 {
    max-width: 807px;

    @media (max-width: 1600px) {
      max-width: 760px;
    }

    @media (max-width: 768px) {
      margin: 16px auto;
      width: 100%;
      //max-width: 343px;
    }
  }

  h3 {
    @media (max-width: 768px) {
      margin: 16px auto;
    }
  }

  li {
    max-width: 687px;
    width: 100%;
    line-height: 120%;

    @media (max-width: 768px) {
      max-width: 300px;
      margin: 0 auto;
      width: 100%;
    }
  }

  &__header {
    position: relative;
    height: 720px;
    max-width: initial;
    width: 100%;

    &-service {
      position: relative;
      background: radial-gradient(125.92% 1199.42% at 96.82% 88.05%, #393f70 0%, #000729 100%);
      margin: auto;
      height: 640px;
      width: 100%;

      img {
        max-width: 834px;
        width: 100%;
        max-height: 500px;
        position: absolute;
        right: 0;
        bottom: 0;

        @media (max-width: 768px) {
          bottom: 285px;
        }
      }
    }

    &-background {
      margin: auto;
      height: 100%;
      //width: 100%;
      max-width: 2300px !important;
      object-fit: cover;
      background-size: cover;

      @media (max-width: 768px) {
        min-height: 100vh;
        width: initial;
        object-fit: cover;
        position: relative;
      }
    }

    @media (max-width: 1600px) {
      height: 100%;
    }

    @media (max-width: 768px) {
      height: 600px;
    }
  }

  &__content {
    position: relative;

    p {
      margin: 10px auto;
      line-height: 130%;
      width: 100%;
      max-width: 807px;
      font-size: 18px;
      font-family: 'Bitter', sans-serif;

      @media (max-width: 1600px) {
        max-width: 760px;
      }
      @media (max-width: 768px) {
        max-width: 343px;
      }
    }

    img {
      display: flex;
      max-width: 820px;
      max-height: 900px;
      object-fit: cover;
      margin: 24px auto 12px;

      height: 100%;

      @media (max-width: 1600px) {
        max-height: 700px;
      }

      @media (max-width: 768px) {
        margin: 24px auto;
        max-width: 450px;
        width: 100%;
        max-height: 400px;
      }
    }

    &-video {
      max-width: 760px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__static {
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 1600px) {
      max-width: 807px;
      align-items: center;
      margin: 0 auto;
    }

    @media (max-width: 768px) {
      align-items: initial;
    }
  }

  iframe {
    width: 807px;
    height: 452px;
    display: flex;
    margin: 0 auto;

    @media (max-width: 1600px) {
      width: 760px;
    }

    @media (max-width: 768px) {
      width: 343px;
      height: 250px;
    }
  }
}

.info-partner {
  position: absolute;
  top: 125px;
  right: 20px;
  max-width: 332px;
  width: 100%;

  &__name {
    margin-bottom: 8px;
  }

  &__age {
    font-size: 14px;
    margin-bottom: 16px;
    color: #abadba;
  }

  @media (max-width: 1600px) {
    right: 0;
  }
  @media (max-width: 768px) {
    top: 320px;
    right: 0;
  }
}

.company-partner {
  font-size: 14px;
  color: #0f1324;
  p {
    margin-bottom: 13px;
  }
}

.list-articles {
  margin-bottom: 40px;
  padding: 0;
  &__title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__descr {
    font-size: 18px;
    font-family: 'Bitter', sans-serif;
    margin-bottom: 24px !important;
    line-height: 23.4px;
  }
}

.item-articles {
  list-style-type: none;
  display: flex;
  margin-bottom: 16px;

  &__icon {
    width: 56px !important;
    height: 58px !important;
    margin: 0 0 16px !important;
    fill: #00cc6e;
  }

  &__descr {
    max-width: 616px !important;
    margin: 0 0 0 16px !important;
    span {
      font-weight: 700;
    }

    a {
      color: #009953;
    }
  }
}

.article-next {
  position: relative;
  margin-bottom: 56px;

  &__title {
    margin: 16px 0 !important;
  }

  &__wrapper {
    width: 819px;

    position: relative;
    background: radial-gradient(125.92% 1199.42% at 96.82% 88.05%, #393f70 0%, #000729 100%);
    border-radius: 8px;

    @media (max-width: 768px) {
      border-radius: 0;
      width: 100%;
    }
  }

  &__banner {
    padding-left: 369px;
    min-height: 320px;
    width: 100%;
    cursor: pointer;
    filter: brightness(0.5);

    &.partners,
    &.clients {
      padding-left: 0;
      object-fit: cover;
      margin: initial;

      @media (max-width: 768px) {
        height: 570px;
      }
    }

    @media (max-width: 768px) {
      padding-left: 0;
    }
  }

  &__name {
    font-size: 17px;
    position: absolute;
    top: 32px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    color: #e3e4e8;

    @media (max-width: 768px) {
      font-size: 24px;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
      text-align: start;
      line-height: 130%;
    }
  }

  &__descr {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    font-size: 28px;
    line-height: 120%;
    font-weight: 700;
    color: #fff;
    text-align: center;

    @media (max-width: 768px) {
      max-width: 300px;
      font-size: 18px;
      text-align: start;
    }
  }

  &__wrapper-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 34px;
    width: 98px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;

    background: #fff;
    backdrop-filter: blur(4px);
    border-radius: 0.29em;
    padding: 0.5em 0.86em 0.5em 1.14em;
    transition: all 0.2s linear;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
      margin: initial;
    }

    span {
      color: #000729;
    }

    &:hover {
      background-color: #00ff88;
      border-color: #00ff88;
      color: #000729;
    }

    &--customer {
      &:hover {
        background-color: #3357ff;
        span {
          color: #fff;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    &--client {
      &:hover {
        background-color: #8800ff;
        span {
          color: #fff;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    @media (max-width: 768px) {
      width: 200px;
      font-size: 18px;
      left: 40%;
      transform: translateX(-40%);
      padding: 16px;
    }
  }

  @media (max-width: 1600px) {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    max-width: 450px;
    width: 100%;
    margin: initial;
  }
}

.articles-other {
  max-width: 450px;
  padding-bottom: 160px;

  &__title {
    width: 100vw;
    margin: 0 0 24px;
  }

  @media (max-width: 1600px) {
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding-bottom: 80px;
    margin: initial;
  }
}

.article-video {
  &__video {
    margin-bottom: 20px;
  }
}

.recharts-layer .recharts-cartesian-axis-tick {
  font-size: 10px;
  font-weight: 500;
}

.article-chart {
  margin-bottom: 24px;
}

.chart__info {
  font-size: 14px;
  color: #4d5268;
  font-style: italic;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &-wrapper {
    height: 30px;
    display: flex;
  }
}

.chart__link {
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: max-content;
  margin-right: 12px;
  gap: 10px;
  font-size: 14px;
  line-height: 120%;
  color: #abadba;
  text-decoration: none;
  transition: color 0.3s ease;

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.recharts-layer .recharts-cartesian-axis-tick {
  font-size: 12px;
  font-weight: 500;
  color: #818598;
}

.p_large {
  max-width: 807px !important;
  color: #0f1324 !important;
  line-height: 130% !important;
  font-weight: 400 !important;
  font-family: 'Bitter', sans-serif;
  font-size: 40px !important;
  margin: 32px auto !important;

  @media (max-width: 1600px) {
    max-width: 760px !important;
  }

  @media (max-width: 768px) {
    max-width: 343px !important;
    font-size: 28px !important;
  }
}

.p_img {
  font-style: italic !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 130% !important;
  color: #4d5268 !important;
  margin: 12px auto 24px !important;
}
